export default {
    'common': {
        'copy': 'Copy',
        'copied': 'copied',
        'Overview': 'Overview',
        'NetAPY': 'Net APY',
        'CurrentFILPrice': 'Current FIL Price',
        'Dashboard': 'Analytics',
        'Referrals': 'Referrals',
        'More': 'More',
        'Stake': 'Stake',
        'Farm': 'Lending',
        'more': 'more',
        'return': 'Return',
        'Warning': 'Warning',
        'Tips': 'Tips',
        'Errors': 'Errors',
        'close': 'Close',
        'Node': 'Node',
        'Balance':'Balance',
        'interest': 'Total Income',
        'Net Mode': 'Net Mode',
        'Disconnect Wallet': 'Disconnect Wallet',
        'Wallet': 'Wallet',
        'Connect Wallet': 'Connect Wallet',
        'Connect a wallet': 'Connect a wallet',
        'networkTitle':'Oops, your wallet is not on the right network.',
        'networkTip':'It seems your wallet is running on a different network from stfil.app. Please manually change the network in your wallet, or you can click the button below.',
        'Switch network': 'Switch network',
        'The app is running in testnet mode': 'The app is running in testnet mode.',
        'Disable testnet': 'Disable Testnet',
        'Language': 'Language',
        'Wait for transaction confirmation': 'Transaction Pending',
        'wait for approve': 'Awaiting Contract Approval',
        'Awaiting stFIL Approval': 'Awaiting stFIL Approval',
        'Waiting for winding': 'Blockchain Pending',
        'Waiting for msg': 'Waiting For Data Sync',
        'Transaction ID': 'Transaction ID',
        'Copy and share poster successfully ready to jump': 'Copy and share poster successfully, ready to jump',
        'Copy and share poster successfully ready to jump2': 'Copy and share poster link successfully, please open in mobile browser.',
        'Contract': 'Contract',
        'flaskErrTips': 'Please disable the installed version of MetaMask. Keep only the Flask version and refresh the page. Launching multiple Metamasks in the same browser can break dapp interactions.',
        'Connection timed out': 'Connection timed out, please check your network or try again.',
        "Failed to fetch": "Please check if your wallet app is the latest version and also check your network",
        "Governance": 'Governance',
        "AVG": "AVG",
        'AUDITED SECURED BY': 'AUDITED & SECURED BY:',
        'Convert to APR': 'Convert to ',
        'APR': 'APR',
        'clear': 'Clear',
        'Wallet not detected': 'Wallet not detected. Connect or install wallet and retry',
        'Model': {
            'Interest Rate Model': 'Interest Rate Model',
            'Variable Interest Rate': 'Variable Interest Rate',
            'Variable Interest Rate Desc': 'Provide a more fair and transparent market pricing mechanism, allowing market participants to manage their funds more flexibly and gain greater profits from market fluctuations.',
            'Stable Interest Rate': 'Stable Interest Rate',
            'Stable Interest Rate Desc': '<p>Stays unchanged within a fixed lending period, unaffected by market supply and demand. Stable rate models are typically applicable to storage providers seeking predictable returns over a specific time frame.</p><p>Only 20% of the available liquidity can be borrowed from the pool at a time.</p>',
            'Optimal Utilization Rate': 'Optimal Utilization Rate',
            'Optimal Utilization Rate Desc': 'Define an interval [80%, 90%] as the target utilization rate for asset reserves. Beyond this range, interest rates increase significantly to ensure that the pool always maintains liquidity.',
            'Interest Rate Protection': 'Interest Rate Protection',
            'Interest Rate Protection Desc': 'By setting upper and lower limits on interest rates, we aim to maintain rates from becoming too high or too low in extreme circumstances, thereby preventing panic among depositors and borrowers.',
            'diffBrowserIn': 'Amount to be repaid',
            'diffBrowserOut': 'Amount to borrow',
            'liquidityReserveRatio Desc': 'The protocol reserves 5% of liquidity as a unstake reserve. The lending pool cannot lend out funds exceeding a 95% utilization rate, while interest rate growth slows down.',
        },
        'Browser Wallet': 'Browser Wallet',
        'NoData': 'No Data',
        'Lending Paused': 'Lending Paused',
        'edit': 'Edit',
        'Invalid Signature': 'Signature submission error, please check the signature content and ensure the initiator has sufficient balance to pay for GAS.',
        'Sign the node source Owner offline': 'Use node Original Owner for offline signing?',
        'Loan pool administrator signature': 'Loan pool admin signature?',
        'herald': 'xxxxx xxxxxxxxx xxxxxx xxxx xx xxxxxx xxxxx xxxxx xx xxxxxxxx',
        'add address': "Add Address",
        'add derivation path': "Add Derived Paths",
        'Please enter a derivation path': 'Please enter a derivation path',
        'Allowance': 'Allowance',
        'what is wstfil': 'wstFIL (wrapped stFIL) is a non-rebasing version of stFIL, where the price of wstFIL is denominated in the changing value of stFIL. The balance of wstFIL can only be altered through transfers, minting, and burning. At any given time, anyone holding wstFIL can exchange any amount of it for stFIL at a fixed rate, and vice versa. Typically, the exchange rate is updated when stFIL undergoes a rebase.'
    },
    'change': {
        'ChangeWorker': 'Change Worker Address',
        'ChangeWorkerDesc': 'Change the node\'s worker address via STFIL.',
        'ChangeControl': 'Change Control Addresses',
        'ChangeControlDesc': 'Change the node\'s contol addresses via STFIL.',
        'settingWorker': 'Setting Now',
        'settingControl': 'Setting Now',
        'current': 'Current',
        'change': 'Change',
        'new': 'New',
        'workerInputTip': 'New Worker ID address (f0 address)',
        'controlInputTip': 'New Control ID address (f0 address)',
        'newTime': 'Effective time',
        'cancel': 'Cancel',
        'workerOk': 'You have successfully modified the Worker ID',
        'controlOk': 'You have successfully modified the Control ID',
        'workerOkWait': 'The change of Worker address takes effect after a period of time',
        'workerInputErr': 'Please enter the correct Worker ID',
        'controlInputErr': 'Please enter the correct Control ID',
        'Please enter Worker ID': 'Please enter Worker ID',
        'Please enter Control ID': 'Please enter Control ID',
    },
    "transfer": {
        "transfer": "Transfer",
        "transferForwardTip": "Not all exchanges properly accept transactions made using FILForward. Contact your exchange for confirmation or send a small test trade first.",
        "transfer0XTip": "Some exchanges currently do not support FIL. It is advisable to initiate a small transaction as a test before proceeding with larger amounts.",
        "from": "From",
        "to" : "To",
        "amount": "Amount",
        "balance": "Balance",
        "send": "Send",
        "receive": "Receive",
        "clear": "Clear",
        "noEthAddressTip": "This is not a valid 0x address",
        "transferBalanceTip": "The transfer amount exceeds your balance",
        "transfeStfilTip": "This address does not have f0,f4 address, cannot transfer stFIL",
        "Transfer successful": "Transfer successful",
        "contractTipTile": "Note",
        "contractTipTileErr": "Error",
        "contractTipContent": "To transfer funds to a smart contract, its code cannot be invoked.",
        "To FilForwarder": "Forwarded By FilForwarder",
        'Scan the address to receive payment': 'Scan the address to receive payment',
    },
    "nft": {
        "title": 'StableJumper NFTs',
        "decs": "Be a Part of the 5,000 Brave Leap and Join the Brave and Steady Journey with StableJumper NFTs!",
        "Total Risk Reserve Value": "Total Risk Reserve Value",
        "Earn real profit as a Community Ambassador": "Earn real profit as a Community Ambassador. ",
        "Earn Airdrop from DAO with your NFT":  "Earn Airdrop from DAO with your NFT",
        "You dont have any StableJumper NFTs":  "You don't have any StableJumper NFTs",
        "Get Your StableJumper NFTs":  "Get Your StableJumper NFTs",
        "How to get reward with StableJumper NFTs": "How to get reward with StableJumper NFTs",
        "By referring a staker to join you can get it for free": "By referring a staker to join, you can get it for free.",
        "Referral now": "Referral Now",
        "Stake your NFT to earn rewards from STFIL DAO Tokens": "Stake your NFT to earn rewards from STFIL DAO Tokens",
        "The longer you stake the higher rewards you earn from STFIL DAO": "The longer you stake, the higher rewards you earn from STFIL DAO.",
        "All income from minting is deposited into the risk reserve fund": "All income from minting is deposited into the risk reserve fund.",
        "Current epoch": "Current Epoch",
        "Staked NFT": "Staked NFT",
        "Total distributed": "Total Distributed",
        "Next epoch": "Next Epoch",
        "Max APR": "Max APR",
        "Next epoch reward": "Next Epoch Reward",
        "Your Nft": "Your NFT",
        "Your staked NFT": "Your Staked NFT",
        "Whitelist complete": "Whitelist Complete",
        "Whitelist Mint NFT": "Whitelist Mint NFT",
        "Off Whitelist": "Off Whitelist",
        "Get NFT": "Get NFT",
        "Stake NFT": "Stake NFT",
        "Earn reward": "Earn Reward",
        'Coming soon': 'Coming Soon...',
        'Pending public sale': 'Pending Public Sale',
        'Public sale ended': 'Public Sale Ended',
        'public sale start': 'Sale Time',
        'public sale end': 'Sale Closing Time',
        'Round': '',
        'Round End': 'Public Sale End Time for Round $$$$',
        'Mint through Rare WhiteList': 'Mint through Rare White-list',
        'From': 'From',
        'stake': 'Stake',
        'unStake': 'UnStake',
        'First round of presale remaining': 'First round of presale. 50 remaining.'
    },
    'Referrals': {
        'Referrals': 'Referrals',
        'Introduction': 'Through the STFIL referral program, become a community ambassador, and gain governance and dividend rights.For more information, <a href="https://docs.stfil.io/referral-program/introduction-to-referral" target="_blank">please read the referral program details.</a>',
        'Referral Code': 'Referral Code',
        'ReferralTip': 'Looks like you don\'t have a referral code to share. Create one now and start earning rebates!',
        'GenerateCode': 'Generate Code',
        'copyGenerateCode': 'Copy Share Link',
        'copyGenerateCodeOk': 'Successful replication',
        'GenerateCodeNew': 'Generate New Code',
        'GenerateCodeInput': 'Enter a number code',
        'GenerateCodeInputBtn': 'Enter a code',
        'GenerateCodeInputErr': 'Recommended codes must be between $$$$1 and $$$$2',
        'Target': 'Target',
        'TargetTip': 'You can become a community ambassador as long as the total interest generated from your referrals reaches the target value.',
        'TotalRebates': 'Number',
        'Referral Codes': 'Referral Codes',
        'Stakes Referred': 'Number',
        'Rewards Distribution History': 'Rewards Distribution History',
        'Referral Details': 'Referral Details',
        'WithdrawRecord':'Withdrawal History',
        'WithdrawTime':'Time',
        'WithdrawAmount':'Amount',
        'Fee': 'Fee',
        'WithdrawStatus':'Status',
        'WithdrawStatus_1':'Withdrawn',
        'WithdrawStatus_0':'Wait Processing',
        'NFT has been obtained': 'Ambassador',
        'Go whitelist MINT': 'Whitelist MINT now',
        'Waiting to add whitelist': 'NFT Pending',
        'Waiting to add whitelist NFT': 'WhiteList Pending',
        'Address': 'Address',
        'Reward': 'Become An Ambassador',
        'No referral codes': 'No referral codes.',
        'No Rewards Distribution History yet': 'No Rewards Distribution History yet.',
        'No Withdraw Distribution History yet': 'There is no record of withdrawals.',
        'Create': 'Create',
        'Checking code': 'Checking Code...',
        'Code already token': 'Code Already Token',
        "Your Referral Code": "Your Referral Code",
        "Your Stakes Referred": "Your Stakes Referred",
        "commission": "Total Rebates",
        "commissionTip": "Total rebates: (Total interest - Target) * Your rebates rate",
        "commissionRate": "Your Rebates Rate",
        "commissionRateTip": "Join our referral program to become a community ambassador and gain governance and dividend rights.",
        "commissioned": "Total Amount Withdrawn",
        "Withdraw commission": "Withdraw",
        'NFT pick up address': 'NFT Claim Address',
        'Please MINT NFT through the NFT claim address': 'Please MINT NFT through the NFT claim address',
        'Progress': 'Progress',
        'NFT whitelist address': 'NFT Whitelist Address',
        'Submit': 'Submit',
        'Enter the address to get the NFT': 'Enter the address to get the NFT',
        'Please enter the correct wallet address' :'Please enter the correct wallet address',
        'Please enter the wallet address': 'Please enter the wallet address',
        'Setting': 'Setting',
        'rule': {
            't': 'Program Profile',
            'title': 'Create your exclusive referral code and share it with other users to receive StableJumper NFTs for free.',
            'rule1': 'The rebates for the referral program are paid from a treasury and do not deduct any rewards from the referred person.',
            'rule2': 'You can create different referral codes for different channels, and the rewards will accumulate.',
            'rule3': 'You will receive interest rebates when other users participate in staking using your referral code.',
            'rule4': 'When other users participate in staking through a URL with a referral code, the interest generated will be counted towards your total interest.',
            'rule5': 'Once your total interest reaches the specified target, you will be eligible to receive the NFT whitelist for free.',
            'rule6': 'Any malicious activities, such as artificially inflating referrals or engaging in cheating, are strictly prohibited and will result in disqualification from receiving rewards.',
            'rule7': 'STFIL DAO reserves the right to modify, adjust, or terminate the rules of the program at any time.',
            'tip': 'If you have any questions or suggestions, please feel free to contact us. Thank you!',
            'learMore': 'Learn more',
        },
        'Customized': 'Customized',
        'refresh': 'Refresh',
        'Wait': 'Wait',
        'poster': 'Poster',
        'saveImg': 'Save Picture',
        'NoCansaveImg': 'Use the PC to save the picture',
        'Stake': 'My',
        'Affiliates': 'Affiliates',
        'Your Active Referral Code': 'Your Active Referral Code',
        'Your Active Referral Code Tip': 'Enter a referral code to receive up to a 2% rebates . The rebates will be airdropped to your wallet address.',
        'Total Interest': 'Total Interest',
        'Share link': 'Share link',
        'Total Rebates': 'Rebates',
        'Can withdraw': 'Available',
        'Settlement Period': 'Settlement Period',
        'Current Interest': 'Current Interest',
        'Discount Rate': 'Rebates Rate',
        'Rebate Amount': 'Rebate Amount',
        'No settlement record': 'No Settlement Record',
        'Your Rebates Rate': 'Your Rebates Rate',
        'Enter Referral Code': 'Enter Referral Code',
        'Your Identity': 'Your Identity',
        'Next Level': 'Next Level',
        'Upgrade condition': 'Upgrade Condition',
        'Staking one NFT': 'Staking One NFT',
        'stFIL balance greater than': 'stFIL balance greater than ',
        'Complete cycle goals obtain rare NFT and pledge':'Complete cycle goals obtain rare NFT and Stake',
        'Waiting to airdrop rare NFT': 'Waiting to airdrop rare NFT',
        'super_rule2': 'Acquire rare NFTs through <span class="link-text-2">minting</span> and whitelist, and <span class="link-text-2">stake</span> your NFTs.',
        'Total Referral Interest': 'Total Referral Interest',
        'Total Referral Stake': 'Total Referral Staking',
        'Total Referral Rebates': 'Rebates',
        'Total Amount Withdrawn': 'Available',
        'level': {
            'Referrer': 'Referrer',
            'Ambassador': 'Ambassador',
            'Super Ambassador': 'Super Ambassador',
        },
        'The referral code does not exist': 'The referral code does not exist.。',
        'Successfully added to queue': 'Successfully added to queue.',
        'The minimum withdrawal amount for commissions': 'The minimum withdrawal amount for commissions is 1 FIL.'
    },
    'ledgerSp': {
        'Connect with Ledger Device': 'Connect with Ledger Device',
        'Something went wrong':'Something went wrong',
        'Please unlock your Ledger device and open the Filecoin App': 'Please unlock your Ledger device and open the Filecoin App',
        'Awaiting confirmation': 'Awaiting confirmation...',
        'To complete the transaction please review the recipient and amount on your': 'To complete the transaction, please review the recipient and amount on your',
        'Ledger Device': 'Ledger Device',
        'If the details match what you see in select': '. If the details match what you see in stfil.io, select ',
        'Approve': 'Approve',
        'Remember': 'Remember',
        'Once approved the message is submitted transactions are final': 'Once approved, the message is submitted, transactions are final.',
        'Sign Message': 'Sign Message',
        'Please do not close this page Waiting for message to link up': 'Please do not close this page. Waiting for message to link up...',
        'Sign the message with your Ledger by clicking the below': 'Sign the message with your Ledger by clicking the "SignMessage" below。',
        'Search Account': 'Search Account...',
        'Connect Ledger': 'Connect Ledger',
        'Wait Approve': 'Wait Approve...',
        'SignMessage': 'SignMessage',
        'Waiting up chain': 'Waiting up chain...',
        'Please approve the request on your Ledger device': 'Please approve the request on your Ledger device.',
        'The account address is not found': 'The account address is not found, you can check if your wallet exists From this address. Or try again!',
        'Is your Ledger device unlocked': 'Is your Ledger device unlocked?',
        'Is your Ledger device locked or busy': 'Is your Ledger device locked or busy?',
        'Were sorry but we cant communicate with your': 'We\'re sorry, but we can\'t communicate with your Ledger Device because your browser is incompatible with our Ledger communication mechanism.',
        'Is your Ledger device plugged in':'Is your Ledger device plugged in?',
        'Please quit the Filecoin appand unplugreplug your Ledger deviceand try again':'Please quit the Filecoin app, And re-enter the Filecoin application, and try again.',
        'Please quit any other App using your Ledger device':'Please quit any other App using your Ledger device.',
        'Please update your Filecoin':'Please update your Filecoin Ledger app to v0.23.0 after, and try again.',
        'Transaction rejected':'Transaction rejected',
        'signFair':'Ledger Signature failure',
        'openExpertMode':'Ledger Failed to open expert mode',
        'LedgerTransactionRejectedErrors':'Signature rejected',
        'LedgerOpenExpertModeModeErrors':'Open the Expert mode of the Ledger Filecoin app',
        'LedgerAddAddressExistErrors': 'This address already exists',
        'LedgerAddAddressFormatErrors': 'Please enter the correctly formatted derivation path. e.g (m/44\'/461\'/6\'/0/0)',
        'Please quit the Fi':'Please quit the Filecoin app, and unplug/replug your Ledger device, and try again.',
        'accounts': 'Accounts',
        'filecoin app not open on device': 'Filecoin app not open on device'
    },
    'footer': {
        'tips': 'STFIL is a Liquid Staking Protocol for Filecoin network. It allows users to obtain block rewards without locking assets and maintaining Storage Provider infrastructure by allowing Storage Providers to obtain more FIL in the protocol pool for pledge.',
        'Social Links': 'Social Links',
        'Learn': 'Learn',
        'Introduction': 'Introduction',
        'Guides': 'Guides',
        'Developers': 'Developers',
        'Quick Links': 'Quick Links',
        'TermsConditions': 'Terms & Conditions',
        'PrivacyPolicy': 'Privacy Policy',
        'document': 'Docs',
        'audit': 'Audits',
        'BountyProgram': 'Bug Bounty',
        'Stay up to date with': 'Stay up to date with stfil.io',
        'Subscribe to the newsletter for Oasis updates': 'Subscribe to the newsletter for stfil updates',
        'Subscribe OK': 'Thank you for subscribing the newsletter!',
        'Subscribe Btn': 'Subscribe',
        'Subscribe Placeholder': 'Email Address',
    },
    'dashboard': {
        'maxBorrowableLiquidityAmount': 'Max Borrowable Liquidity Amount',
        'liquidityReserveRatio': 'Liquidity Reserve Ratio',
        'Dashboard': 'Analytics',
        'Introduction': 'Transparent data analysis, track your reward changes in real-time!',
        'TVL': 'TVL',
        'TotalValueLocked': 'Total Value Locked',
        'Farm Balance': 'Farm Balance',
        'DailyEarnings': 'Daily Earnings',
        'AvailableLiquidity': 'Available Liquidity',
        'TotalBorrowed': 'Total Borrowed',
        'dashTotalBorrowed': 'Total Borrowed',
        'ReserveFactor': 'Reserve Factor',
        'ReserveFactorTip': 'Reserve factor (10%) is a percentage of interest which goes to a collector contract that is controlled by STFIL governance to promote ecosystem growth.',
        'UtilizationRate': 'Utilization Rate',
        'OptimalUtilizationRate': 'Optimal Utilization Rate',
        'FarmBalance': 'Farm Balance',
        'StakingPoolInfo': 'Staking Pool Info',
        'TotalSupply': 'Total Supply',
        'Supply': 'Supply',
        'adTitle': 'Become a high-yielding Storage Provider',
        'adContent': 'We provide you with sufficient pledge assets to help you earn more storage rewards, Powering the infrastructure of Web3!',
        'adBtn': 'Onboarding Now',
        'BorrowInfo': 'Borrow Info',
        'Variable': 'Variable',
        'Stable': 'Stable',
        'InterestRateMode': 'Interest Rate Info',
        'APYVariable': 'APY, Variable',
        'variableRateAvg': '30d Avg APY, Variable',
        'APYStable': 'APY, Stable',
        'BorrowStable': 'Borrow,stable',
        'BorrowVariable': 'Borrow,variable',
        'BorrowAPRstable': 'Borrow APR,stable',
        'BorrowAPRvariable': 'Borrow APR,variable',
        'BorrowAPYstable': 'Borrow APY,stable',
        'BorrowAPYvariable': 'Borrow APY,variable',
        'supply': 'Supply',
        'StakersNodes': 'Stakers/Nodes',
        'Stakers': 'Stakers',
        'nodes': 'Nodes',
        'total power': 'Total Power',
        'Collateral': 'Collateral',
        'Total Collateral Automated': 'Total Collateral Automated',
        'Total Adj Power': 'Total Adj. Power',
    },
    'stake': {
        'title': 'Staking',
        'Staked APY': 'Staked APY',
        'Introduction': 'No lock-up period staking, let your FIL earn interest in real-time, automatically compound.',
        'TotalSupply': 'Total Supply',
        'UtilizationRate': 'Utilization Rate',
        'SupplyAPY': 'Staked APY',
        'NetAPY': 'Staked APY',
        'NetAPYTip': 'Earnings are provided by storage provider nodes, and the earnings are obtained by increasing the balance of stFIL. ',
        'WhatDoYouGainWhenHoldStFIL': 'What do you gain when hold stFIL?',
        'WhatDoYouGainWhenHoldStFILTip': 'The rules of the sector pledge cycle in the Filecoin network are transparent to stakers. stFIL allows holders to have more possibilities when participating in Filecoin.',
        'LearnMore': 'Learn More',
        'WhichActionWouldYouLikeToChoose': 'Which action would you like to choose?',
        'YouWillReceive': 'You will receive',
        'AvailableLiquidity': 'Available Liquidity',
        'ExchangeRate': 'Exchange Rate',
        'Stake': 'STAKE',
        'unStake': 'UNSTAKE',
        'AddSTFILToMetaMask':'Add Token to wallet',
        'Expected': 'Exp. 30D Earnings',
        'stakeInputErr limit max': 'Insufficient FIL balance',
        'stakeInputErr gas max': 'Reserve a balance of at least 0.5FIL for GAS',
        'Gas may be insufficient Reserve a balance of at least': 'Gas may be insufficient. Reserve a balance of at least 0.5FIL!',
        'You have successfully staked FIL': 'You have successfully staked xxxx FIL',
        'You have successfully unstaked FIL': 'You have successfully unstaked xxxx FIL',
        'You have successfully wrap stFIL': 'You have successfully wrap xxxx stFIL',
        'You have successfully unwrap stFIL': 'You have successfully unwrap xxxx stFIL',
        'Zap stFIL to wstFIL': 'Zap stFIL to wstFIL',
        'unStakeInputErr': 'Insufficient liquidity available',
    },
    'farm': {
        'Next Update': 'Next Update',
        'title': 'Lending',
        'Introduction': 'Add flexible leverage to your Storage providers\' nodes and boost annual returns!',
        'TotalBorrowed': 'Total Borrowed',
        'APYVariable': 'APY, Variable',
        'APYStable': 'APY, Stable',
        'StorageProviders': 'Storage Providers',
        'Search': 'Search',
        'Active': 'Active',
        'Closed': 'Closed',
        'OnboardingNow': 'Onboarding Now',
        'Node': 'Node',
        'Position': 'Position',
        'Debt': 'Debt',
        'Equity': 'Equity',
        'DebtRatio': 'Debt Ratio',
        'lever': 'Leverage',
        'MaxDebtRatio': 'Max Debt Ratio',
        'LiqThreshold': 'Liq. Threshold',
        'View': 'View',
        'LessThan': 'Less Than',
        'The current node is a collateralized node': 'The current node is a collateralized node',
        'Please select a collateralized node': 'Please select a collateralized node',
        'Cannot find a node with the same owner': 'Cannot find a node with the same owner',
        'The debt of the node has exceeded the maximum debt': 'The debt of the node has exceeded the maximum debt',
        'You have successfully collateralized the node': 'You have successfully collateralized the node',
        'Unsecured node': 'Unsecured node',
        'You have successfully released the collateralized node': 'You have successfully released the collateralized node',
        'There are outstanding debts The guarantee cannot be released': 'There are outstanding debts. The guarantee cannot be released.',
        'The current node is not collateralized with any oth+er nodes': 'The current node is not collateralized with any other nodes',
        'power': 'Adj. Power',
        'collateralNode': {
            'rule1': 'Validator nodes must have the same owner.',
            'rule2': 'Collateral nodes need to have the same staking role.',
            'rule3': 'The debt of the collateral node and this node combined must not exceed the maximum leverage.',
            'rule4': 'The two nodes after collateral must use the minimum leverage allowed.',
            'rule5': 'Releasing collateral requires both nodes to be debt-free.',
        },
        'minerOverview' : {
            'Address Balance': 'Node Balance',
            'Available Balance': 'Available Balance',
            'Initial Pledge': 'Initial Pledge',
            'Locked Rewards': 'Locked Rewards',
            'Average pledge': 'Avg. Initial Pledge FIL/TiB',
            'Sector Liquidation Percentage': 'Sector Termination Slashing Ratio',
            'Adjusted Power': 'Adjusted Power',
            'Rate': 'Rate',
            'Raw Byte Power': 'Raw Byte Power',
            'Sector Size': 'Sector Size',
            'Sector Status': 'Sector Status',
            'total': 'total',
            'active': 'active',
            'faults': 'faults',
            'recoveries': 'recoveries',
            'Account Overview': ' Access Control',
            'Contract Operator': 'Contract Operator',
        },
        'console': {
            'Overview': 'Overview',
            'History': 'History',
            'Miner Overview': 'Profile',
            'Settings': 'Settings',
            'PositionValue': 'Position Value',
            'PositionValueTip': 'The position value refers to the total value of assets held by a storage provider, which includes Available Balance, Initial Pledged and Rewards Locked.',
            'DebtValue': 'Debt Value',
            'DebtValueTip': 'The debt value refers to the total amount of debt that a storage provider has in the agreement, which includes the total amount of loans that need to be repaid by the storage provider, and it is the sum of variable debt and stable debt.',
            'EquityValue': 'Equity Value',
            'InterestValue': 'Accumulated Interest',
            'EquityValueTip': 'The equity value refers to the net asset value of a storage provider. This value is usually obtained by subtracting the Debt value from the position value of the storage provider.',
            'CurrentAPR': 'Current APR',
            'EstimatedDailyEarnings': 'Estimated Daily Earnings',
            'DebtRatio': 'Debt Ratio',
            'DebtRatioTip': 'The debt ratio measures a storage provider\'s total debt relative to its position value. It helps to evaluate the level of debt risk, and ascertain its financial stability and repayment capability.',
            'DebtRatioTip2': 'The current debt ratio includes the debt of the collateral nodes.',
            'SafetyBuffer': 'Safety Buffer',
            'SafetyBufferTip': 'The safety buffer refers to a protective mechanism set by this protocol, which is used to ensure that the funds borrowed by the storage provider are used for collateral storage. It is generated when the storage provider borrows money, and only withdrawals can be made when the total assets exceed the safety buffer portion.',
            'MaximumDebtRatio': 'Maximum Debt Ratio',
            'LiqThreshold': 'Liq. Threshold',
            'LiqThresholdTip': 'The liquidation threshold refers to the debt-to-equity ratio threshold agreed upon in this protocol for a storage provider. When the debt-to-equity ratio of the storage provider exceeds this threshold, it will be liquidated to ensure the stability of the platform.',
            'MaximumLeverage': 'Maximum Leverage',
            'Delegated': 'Delegated',
            'Delegated1': 'Delegated',
            'AvailableBalance': 'Available Balance',
            'InitialPledge': 'Initial Pledge',
            'LockedRewards': 'Locked Rewards',
            'BorrowingRepayment':'Borrowing & Repayment',
            'BorrowingBtn':'Seal Loan',
            'RepaymentBtn':'Repayment',
            'Withdrawal income btn':'Withdraw Profits',
            'Loan withdrawal btn':'Withdraw Loan',
            'WithdrawFromNode':'Withdraw From Node',
            'WarningTip': 'Don\'t  use "<b>lotus-miner actor withdraw ...</b>" to bypass the protocol for withdrawals. Funds will be sent to a black hole, rendering them irrecoverable.',
            'learnMore': 'Learn more',
            'Choosethewayyoulike': 'Choose the way you like',
            'ByLeverage': 'By Leverage',
            'ByAmount': 'By Amount',
            'Whatinterestratedoyoupreferforborrowing': 'What interest rate do you prefer for borrowing?',
            'Whatyourpreferredrepaymentinterestrate': 'What\'s your preferred repayment interest rate?',
            'Choose your repayment method': 'Choose your repayment method',
            'repay1': 'Available Balance',
            'repay2': 'Wallet Balance',
            'Priority repayment Seal Loan': 'Priority repayment "Seal Loan"',
            'Priority repayment Withdraw Loan': 'Priority repayment "Withdraw Loan"',
            'Pleasemakechangestotheleverage': 'Please make changes to the leverage',
            'OutstandingVariableDebt': 'Outstanding Variable Debt',
            'OutstandingVariableDebtTip': 'The variable interest rate will fluctuate based on the market conditions. A more fair and transparent market pricing mechanism allows storage providers to manage their funds more flexibly and obtain greater profits from market fluctuations.',
            'MaximumBorrowingLimit': 'Maximum Borrowing Limit',
            'TargetLeverage': 'Target Leverage',
            'Youareborrowing': 'You are borrowing',
            'CurrentVariableDebt': 'Current Variable Debt',
            'RemainingBorrowingCapacity': 'Remaining Borrowing Capacity',
            'SingleRemainingBorrowingCapacity': 'Remaining Borrowing Capacity',
            'The loan amount cannot exceed the maximum leverage': 'The loan amount cannot exceed the maximum leverage',
            "The loan amount cannot exceed the available liquid funds": 'The loan amount cannot exceed the available liquid funds',
            "The maximum borrowing amount for stable debt cannot exceed of the available liquid funds": 'The maximum borrowing amount for stable debt cannot exceed $$$% of the available liquid funds%',
            'VariableInterestRate': 'Variable Interest Rate',
            'Isthiswhatyouexpected': 'Is this what you expected?',
            'CurrentFarmAPR': 'Current Farm APR',
            'CurrentBorrowingInterestAPR': 'Current Borrowing Interest APR',
            'TotalAPR': 'Total APR',
            'DailyAPR': 'Daily APR',
            'WhichActionWouldYouLikeToChoose': 'Which action would you like to choose?',
            'Borrowing': 'Borrowing',
            'Repayment': 'Repayment',
            'Youarerepaying': 'You are repaying',
            'CurrentLeverage': 'Current Leverage',
            'OutstandingStableDebt': 'Outstanding Stable Debt',
            'OutstandingStableDebtTip': 'The stable interest rate will stay the same for the duration of your loan.Recommended for storage providers who want predictable returns over a specific time period.',
            'CurrentStableDebt': 'Current Stable Debt',
            'StableInterestRate': 'Average Stable Interest Rate',
            'avgRate': 'Average Interest Rate',
            'CurrentStableInterestRate': 'Current Stable Interest Rate',
            'Howmachmorewouldyouliketowithdrow': 'How mach more would you like to withdrow?',
            'Maximumwithdrawal': 'Maximum Withdrawal',
            'Payee': 'Payee',
            'PayeeTip': 'The owner of the node is the only receiving address.',
            'Amount': 'Amount',
            'Withdraw': 'WITHDRAW',
            'Calculatedbasedonyourleveragedposition': 'Calculated based on your',
            'Calculatedbasedonyourleveragedposition2': 'leveraged position',
            'VariableDebt': 'Variable Debt',
            'StableDebt': 'Stable Debt',
            'MaxInterestRate': 'Max Interest Rate',
            'OptimalInterestRate': 'Optimal Interest Rate',
            'OptimalUtilizationRate': 'Optimal Utilization Rate',
            'UtilizationRate': 'Utilization Rate',
            'APRstable': 'APR,stable',
            'APRvariable': 'APR,variable',
            'Close': 'Close',
            'Variable': 'Variable',
            'Stable': 'Stable',
            'ConnectOperatorWallet': 'Connect Operator Wallet',
            'ConnectAdminWallet': 'Connect Admin Wallet',
            'REPAY': 'REPAY',
            'REPAY-CASH': 'Repay With Cash',
            'BORROW': 'BORROW',
            'DISABLED': 'DISABLED',
            'Yourcurrentdebtratiorateexceedsthemaximumdebtratio':'Your current debt ratio rate exceeds the maximum debt ratio!',
            'Gotorepay': 'Go to repay',
            'WITHDRAW': 'WITHDRAW',
            'Optimal':'Optimal Range',
            'Borrows':'Borrows',
            'Repayments':'Repayments',
            'Withdraws':'Withdraws',
            'Liquidations':'Liquidations',
            'Action':'Action',
            'TxnHash': 'Txn Hash',
            'From': 'From',
            'To': 'To',
            'Age': 'Age',
            'Mode': 'Mode',
            'Rate': 'Rate',
            'Liquidator': 'Liquidator',
            'Award': 'Award',
            'ApproveOperator': 'Approve Operator',
            'ApproveOperatorJIANJIE': 'Welcome to the Approve An Operator page. Here, you can grant secure access to trusted personnel for financial transactions such as borrowing, repayment and withdrawal. STFIL protocol separates the ownership and operation of storage providers, ensuring a more secure authorization of nodes.',
            'ApproveOperatorTip': 'The STFIL protocol ensures secure node authorization by separating the ownership and operation of storage providers, allowing only authorized operators to perform actions such as borrowing, repayment, and withdrawal.',
            'ApproveOperatorBtn': 'Approve Now',
            'Unrestricted': 'Unrestricted resignation, completely decentralized',
            'UnrestrictedTip': 'The STFIL protocol allows for completely unrestricted onboarding and offboarding processes. You are free to leave or rejoin at any time. Please ensure that all debts have been repaid before leaving.',
            'UnrestrictedBtn': 'Offboarding Now',
            'Oopsunauthorized operator': 'The node has no operator!',
            'Oopsunauthorized operator2': 'The STFIL protocol allows only authorized operators to perform actions such as borrowing, repayment, and withdrawal.You can click the button below to approve the operator.',
            'actorId': 'Actor',
            'role': 'Role',
            'action': 'Action',
            'NodeDelegate': 'Delegate',
            'NodeUndelegate': 'Undelegate',
            'TerminateGuarantee': 'Terminate Guarantee',
            'TerminateGuaranteeTip': 'The STFIL protocol allows two nodes to form a mutual guarantee relationship, share positions and share risks.',
            'TerminateGuaranteeNow': 'Release now',
            'ProvideGuarantee': 'Provider Guarantee For Node',
            'ProvideGuaranteeTip': 'The STFIL protocol allows two nodes to form a mutual guarantee relationship, share positions and share risks.',
            'ProvideGuaranteeNow': 'Guarantee Now',
            'Contains the Position': 'Shared with $$$$',
            'current' : 'Current',
            'This is the debt of the collateral node': 'This is the debt of the collateral node',
            "Make sure that all debts have been repaid": 'The current node has outstanding debts',
            'Please enter the amount withdraw': 'Please enter the amount withdraw.',
            'Successfully upgraded to Owner delegate': 'Successfully upgraded to Owner delegate.',
            'The current node delegate role is not beneficiary': 'The current node delegate role is not beneficiary.',
            'The actual repayment TIP': 'The actual repayment amount is subject to the final executed amount of the contract.',
        }
    },
    'Onboarding': {
        'title':'Node Onboarding',
        'tips':'Welcome to the STFIL storage provider node onboarding. As a part of the Filecoin ecosystem, you will get loan leverage, and you can do a better job of providing safe, reliable and efficient data storage services for global users, and get more benefits from it. We look forward to having you on board and providing you with the necessary support and resources to help you become a premium storage provider.',
        'Search': 'Search',
        'SearchTip': 'Search for Address',
        'Position Value': 'Position Value',
        'Position Value tip': 'The position value refers to the total value of assets held by a storage provider, which includes Available Balance, Initial Pledged and Rewards Locked.',
        'Available Balance': 'Available Balance',
        'Locked Rewards': 'Locked Rewards',
        'Initial Pledge': 'Initial Pledge',
        'Adjusted Power': 'Adjusted Power',
        'Raw Byte Power': 'Raw Byte Power',
        'Sector Size': 'Sector Size',
        'Sector Count': 'Sector Count',
        'Please select the role to delegate': 'Please select the role to delegate',
        'Owner': 'Owner',
        'Beneficiary': 'Beneficiary',
        'Original Owner': 'Original Owner',
        'Address': 'Address',
        'AddressTips1': 'The follow-up steps require this owner to sign the message in order to successfully delegate.',
        'Available Borrowing': 'Available Borrowing',
        'Maximum Debt Ratio': 'Maximum Debt Ratio',
        'LiqThreshold': 'Liq. Threshold',
        'LiqThreshold Tip': 'The liquidation threshold refers to the debt-to-equity ratio threshold agreed upon in this protocol for a storage provider. When the debt-to-equity ratio of the storage provider exceeds this threshold, it will be liquidated to ensure the stability of the platform.',
        'Maximum Leverage': 'Maximum Leverage',
        'Pre': 'Pre',
        'Next': 'Next',
        'Submit a' : 'Submit a ',
        'proposal' : ' proposal.',
        'Actor': 'Actor',
        'Approval Threshold': 'Approval Threshold',
        'NoofSinger': 'No. of Singer',
        'Please select an address to initiate this proposal': 'Please select an address to initiate this proposal',
        'Approved': 'Approved',
        'Confirm': 'Confirm',
        'Approval proposal by STFIL staking pool': 'Approval proposal by STFIL staking pool.',
        'Message Content': 'Message Content',
        'Method': 'Method',
        'Fee': 'Fee',
        'params': 'Params',
        'Please choose your signature method': 'Please choose your signature method.',
        'Sign Message': 'Sign Message',
        'Sign message via related offline signature account': 'Sign message via related offline signature account. Use Lotus CLI program below to make the signature',
        'venus Sign message via related offline signature account': 'Sign message via related offline signature account. Use Venus Wallet program below to make the signature',
        'Sign message via related offline signature account2': 'Sign message via related offline signature account. Open theoffline app and scan the QR code below to make the signature.',
        'STFILWallet': 'FiveToken Pro',
        'Please enter the result': 'Please enter the result',
        'Rest': 'Reset',
        'Push': 'Push',
        'Not installed?': 'Not installed?',
        'Text Import': 'Text Import ?',
        'Scan QR code here': 'Scan QR code here',
        'Message ID': 'Message ID',
        'Multisig': 'Multisig',
        'You have successfully joined': 'Successfully Onboarded!',
        'This node has delegated the owner role': 'This node has delegated the owner role!',
        'Please take good care the original owner': 'Please take good care the original owner, it is still the owner of this node.',
        'You have successfully completed your entry application': 'You have successfully completed your entry application!',
        'View Node': 'View Node',
        'Approval proposal by original owner':'Approval proposal by original owner',
        'The original owner will take over the ownership':'The original owner will take over the ownership of the node, please keep it safe.',
        'Once the first step is complete':'Once the first step is complete, it is equivalent to offboarded. If the process is interrupted, please come back here to try again.',
        'roposal by staking pool':'roposal by staking pool.',
        'Thank you for your support for': 'Thank you for your support for the STFIL protocol all the way.',
        'Node Offboarding': 'Node Offboarding',
        'Try Again': 'Try Again',
        'You have successfully offboarded':'You have successfully offboarded.',
        'Thank you for your unwavering':'Thank you for your unwavering support for the STFIL protocol. I look forward to the opportunity to collaborate again in the future.',
        'Safety Buffer':'Safety Buffer',
        'Safety Buffer Tip': 'The safety buffer refers to a protective mechanism set by this protocol, which is used to ensure that the funds borrowed by the storage provider are used for collateral storage. It is generated when the storage provider borrows money, and only withdrawals can be made when the total assets exceed the safety buffer portion.',
        'Equity Value':'Equity Value',
        'Equity Value tip': 'The equity value refers to the net asset value of a storage provider. This value is usually obtained by subtracting the Debt value from the position value of the storage provider.',
        'Debt Value':'Debt Value',
        'Debt Value Tips':'The debt value refers to the total amount of debt that a storage provider has in the agreement, which includes the total amount of loans that need to be repaid by the storage provider, and it is the sum of variable debt and stable debt.',
        'Debt Ratio':'Debt Ratio',
        'Debt Ratio Tips':'The debt ratio measures a storage provider\'s total debt relative to its position value. It helps to evaluate the level of debt risk, and ascertain its financial stability and repayment capability.',
        'LiqThreshold Tips':'The liquidation threshold refers to the debt-to-equity ratio threshold agreed upon in this protocol for a storage provider. When the debt-to-equity ratio of the storage provider exceeds this threshold, it will be liquidated to ensure the stability of the platform.',
        'This node has been delegated!':'This node has been delegated!',
        'There can be no other beneficiaries': 'There can be no other beneficiaries!',
        'Scan the QR code after offline': 'Scan the QR code after offline signature',
        'Click here to scan the QR code': 'Click here to scan the QR code',
        'Qr Scan': 'Qr Scan?',
        'F4 address does not support entry': 'f4 address does not support entry!',
    },
    'Offboarding': {
        'title' : 'Node Offboarding',
        'tips' : 'Thank you for your support for the STFIL protocol all the way.',
        'Node ' : 'Node ',
        'Submit a' : 'Submit a ',
        'proposal by staking pool' : ' proposal by staking pool.',
        'proposal by lengin pool' : ' proposal.',
        'The original owner will take over the authority of the node beneficiary':'The original owner will take over the authority of the node beneficiary, please keep it safe.',
        'Once the first step is complete':'Once the first step is complete, it is equivalent to offboarded. If the process is interrupted, please come back here to try again.',
        'There are outstanding debts': 'There are outstanding debts.',
        'There are haveGuaranteeNodeId debts': 'Please release the guaranteed node before leaving the company.',
        'Address': 'Address',
        'not skip this step': 'The previous step is irreversible, please do not skip this step',
        'not skip this step2': ', otherwise the "ChangeOwnerAddress" message needs to be sent offline.',
        'isFineOffBoardTip': 'Node $$$nodeId$$$ has left the service.Ready to jump to history.',
    },
    'Operator': {
        'New Operator': 'New Operator',
        'Current Wallet': 'Current Wallet',
        'Current Wallet Tip': 'Please enter the operator\'s address',
        'You have successfully approved the operator': 'You have successfully approved the operator.',
        'If the operator is changed lost': 'If the operator is changed, lost, or leaked, you can return here to reapprove.',
        'View Node': 'View Node',
        'Reapprove': 'Reapprove',
        'Actor Not Found': 'Actor Not Found.',
        'Current Operator':'Current Operator',
        'You should recharge this address with some FIL': 'You should recharge this address with some FIL',
    },
    'FAQs': {
        'FAQs': 'FAQs',
        'Why node onboarding': 'Why node onboarding?',
        'What is required for node onboarding': 'What is required for node onboarding?',
        'How much loan can a node get': 'How much loan can a node get?',
        'The difference between delegation methods': 'The difference between delegation methods?',
        'How to redeem a node': 'How to redeem a node?',
        'Additional Resources': 'Additional Resources',
        'STFILlending strategy': 'STFIL\'s lending strategy',
        'Lending Rate Model': 'Lending Rate Model',
        'Debt interest calculation method': 'Debt interest calculation method',
        'Debt liquidation': 'Debt liquidation',
        'Storage provider KYC': 'Storage provider KYC',
        'NodeOperator operation manual': 'NodeOperator operation manual',
    },
    'computer': {
        'ROI Calculator':'ROI Calculator',
        'FIL STATKED': 'FIL STATKED',
        'MyBalance': 'My BALANCE',
        'STAKED FOR': 'STAKED FOR',
        '1D': '1D',
        '7D': '7D',
        '15D': '15D',
        '30D': '30D',
        '1Y': '1Y',
        '3Y': '3Y',
        'ROI AT CURRENT RATES': 'ROI AT CURRENT APY',
        'STAKEFil': 'Stake FIL',
    },
    'subpool': {
        'The total amount borrowed by the platform cannot be greater than the liquidity pool': 'The total amount borrowed by the platform cannot be greater than $$$ of the liquidity pool',
        'Confirm the proposal with the new Owner': 'Confirm the proposal with the new Owner',
        'Initiate a proposal using the source Owner': 'Initiate a proposal using the Original Owner',
        'new owner tip': 'Please enter the new Owner address, Support f0,f1,f3',
        'new owner err f4 tip': 'The f4 address is not supported',
        'new owner err f2 tip': 'The f2 address is not supported',
        'new owner': 'New Owner',
        'changeOwner': 'Change Original Owner',
        'Please enter the node you want to join': 'Please enter the node you want to join',
        'none': 'None',
        'Entry requirement': 'Onboarding Reqs',
        'The node balance is less than': 'Node Balance < 1FIL',
        'The node balance is less than 2': 'The node balance must be less than 1FIL',
        'The node balance is less than 3': 'Must be less than 1FIL',
        'Yourcurrentdebtratiorateexceedsthemaximumdebtratio': 'The current debt ratio($$$) of your lending pool exceeds the maximum debt ratio($2$$)!',
        'Borrows': 'Seal Loan',
        'Withdraws': 'Withdraw Profits',
        'Show all': 'Show All',
        'SafetyBufferTip': 'The safety buffer is used to ensure that the funds borrowed for sector sealing can only be used for sector sealing. It is generated when “Seal Loan”, and withdrawals can only be made when the total assets exceed the safety buffer portion.',
        'ViewPool': 'View Pool',
        'created': {
            'poolType': 'Please select one lending pool type',
            'Storage Provider': 'Storage Provider',
            'Storage Provider Tip': `The Storage Provider Lending Pool is a platform designed to offer sufficient collateral assets to Filecoin Storage Providers (SPs), enabling them to earn more storage rewards and empower Web3 infrastructure! </br> It allows SPs to access a significant amount of funds for sector sealing and also permits SPs to borrow against their already staked assets for capital turnover.</br>Each storage provider lending pool is a privatized pool, and all nodes in the pool form a mutual guarantee relationship, share positions, and share risks.`,
            'lendingType': 'Agree the old node to onboarding?',
            'lendingType Tip1': 'Due to the protocol\'s stringent risk control, great emphasis is placed on the maximum termination penalty ratio for SPs nodes. </br>In the Filecoin network, the penalties for terminating sectors vary depending on when the sectors were sealed. Therefore, higher borrowing leverage is disabled for older nodes.',
            'lendingType Tip2': 'Leverage specifically provided for SP\'s sector sealing,</br>One position\'s value can be used to obtain a loan worth twice its value.',
            'lendingType Tip2_2': 'Leverage specifically provided for SP\'s sector sealing,</br>One position\'s value can be used to obtain a loan worth three times its value.',
            'lendingType Tip3': 'Leverage borrowed by SPs for capital turnover，the protocol allows SPs to withdraw the value of their pledged positions.',
            'lendingType Tip4': 'The STFIL protocol ensures secure node authorization by separating ownership and operation of storage providers. Pool administrators only possess operational rights delegated to the pool\'s nodes, while ownership of the nodes is still retained by their original owners.',
            'lendingType Tip5': 'The more nodes delegate to the lending pool, the more gas will be consumed for each operation. Please plan your nodes appropriately.',
            'isLocalAdmin': 'Please set up an pool administrator',
            'crateBtn': 'Submit',
            'self': 'Personal',
            'other': 'Custom',
            'address': 'Admin Address',
            'coming soon': 'Coming Soon...',
        },
        'LendingPool': 'Lending Pool',
        'createPoolBtn': 'Borrow Now',
        'Create Lending Pool': 'Create Lending Pool',
        'subpool list title': 'Lending Pools',
        'admin address':'Admin Address',
        'createPoolOkTip': 'The contract pool is created successfully.',
        'You are not the creator of the loan pool': 'You are not the creator of the loan pool.',
        'yes': 'Yes',
        'no': 'No',
        'poolAddress': 'Pool Address',
        'adminAddress': 'Admin Address',
        'nodeCount': 'Node Count',
        'poolOnlyAcceptsNewNodes':'Accepts New Nodes',
        'poolOnlyAcceptsOldNodes':'Old Nodes Onboarding',
        'poolOnlyAcceptsOldNodes2':'Select Seal Loan leverage',
        'poolPosition': 'Pool Position Value',
        'poolDebt': 'Pool Debt Value',
        'Available Seal Load': 'Available Seal Load',
        'Available Withdrawal Loan': 'Available Withdrawal Loan',
        'poolWithdrawLoadTotalAmount': 'Due Withdrawal Loan',
        'poolSealLoadTotalAmount': 'Outstanding Seal Loan',
        'poolEquity': 'Pool Equity Value',
        'poolMaxSealedLoadAmount': 'Max Sealed Load',
        'poolAvailableBalance': 'Pool Available Balance',
        'MaxSealLoadLeverage': 'Seal Load Leverage',
        'maxLeverage': 'Max Leverage',
        'poolMaxWithdrawLoadLeverage': 'Withdraw Load Leverage',
        'LiqThreshold': 'Liq Threshold',
        'DebtRatio': 'Debt Ratio',
        'MaximumDebtRatio': 'Maximum Debt Ratio',
        'Join the pool': 'Join The Pool',
        'Transfer pool': 'Transfer Pool',
        'MaxNodeCount': 'Maximun Node Count',
        'Contract address': 'Contract Address',
        'poolType': 'Pool Type',
        'Lending Pool': 'Lending',
        'Storage Provider': 'Storage Provider',
        'Search Contract Pool': 'Search',
        'Regular Lending Pool': 'Regular Lending Pool',
        'Regular Lending Pool Tip': 'The Storage Provider Lending Pool is a platform designed to offer sufficient collateral assets to Filecoin Storage Providers (SPs), enabling them to earn more storage rewards and empower Web3 infrastructure! \n It allows SPs to access a significant amount of funds for sector sealing and also permits SPs to borrow against their already staked assets for capital turnover.',
        'Accepting only new nodes': 'Accepting only new nodes',
        'Accepting only new nodes tip': 'Due to the protocol\'s stringent risk control, great emphasis is placed on the maximum termination penalty ratio for SPs nodes. \n' +
            'In the Filecoin network, the penalties for terminating sectors vary depending on when the sectors were sealed. Therefore, higher borrowing leverage is disabled for older nodes.',
        'Create using the current wallet': 'Create using the current wallet',
        'Create with a specified wallet': 'Create with a specified wallet',
        'Enter the contract pool administrator': 'Enter the contract pool administrator',
        'withdrawLoadAmount': 'Withdraw Load Amount',
        'currentWithdrawLoadAmount': 'Cut. W Load Amount',
        "This is the pools collateral section": "This is the pool's collateral section",
        'The debt of the pools nodes': 'The debt of the pool\'s nodes',
        'The current loan withdrawal section': 'The current loan withdrawal section',
        'The maximum loan withdrawal leverage for the contract pool': 'Max Withdraw Load Leverage',
        'The caller must be the pool admin': 'The caller must be the pool admin',
        'Pool Position': 'Pool Position',
        'pool admin': 'Pool Admin',
        'pooladdress': 'Pool Address',
        'Approval proposal by STFIL staking pool': 'Approval Proposal',
        'Approval proposal by STFIL lednding pool': 'Approval Proposal',
        'Migrate to the contract pool': 'Migrate to the contract pool',
        'Migration now': 'Migration now',
        'Beneficiary upgrading owner delegation': 'Beneficiary upgrading owner',
        'Change node delegation role and increase leverage': 'Change node delegation role and increase leverage',
        'Convert now': 'Convert now',
        'Please select the contract pool to join': 'Please select the contract pool to join',
        'You have successfully migrated to the lending pool': 'You have successfully migrated to the lending pool',
        'The node has not joined the StakingPool or has already been upgraded': 'The node has not joined the StakingPool or has already been upgraded',
        'poolInfoTitle': 'Storage Provider Lending Pool',
        'select address': 'Select Lending Pool',
        'select': 'Select',
        'node position': 'Node Balance',
        'node max lending': 'Node maximum borrowed',
        'induction': {
            'admin': 'Amdin',
            'maxLoan': 'Max Sealed Load Amount',
            'maxWith': 'Max Withdraw Loan Amount',
            'nodeFull': 'The capacity of the lending pool has reached the upper limit!',
            'connect admin Wallet' : 'Connect Admin Wallet',
            'Pool Will Receive' : 'The lending pool will receive'
        },
        'Leverage Used': 'Leverage Used',
        'poolInfoTip': {
            'poolAdmin': 'Pool administrators only possess operational rights delegated to the pool\'s nodes, while ownership of the nodes is still retained by their original owners.',
            'position': 'The total position value refers to the total value of assets held by all nodes in the lending pool, including available balance, initial staking, and locked rewards.',
            'equity': 'The total equity value refers to the net asset value of all nodes in the lending pool.',
            'debt': 'The total debt value refers to the total debt amount within the lending pool, encompassing the total loans that storage providers are required to repay in the protocol. It is the sum of floating and stable debt, which includes the outstanding withdrawal loans.',
            'borrowLoan': 'The outstanding withdrawal loans refer to the debt amount that nodes have borrowed for capital turnover and have not yet repaid.',
            'withLoan': 'Leverage specifically provided for SP\'s sector sealing，</br>' +
                'one position\'s value can be used to obtain a loan worth twice its value.',
            'withLoan2': 'Leverage specifically provided for SP\'s sector sealing，</br>' +
                'One position\'s value can be used to obtain a loan worth three times its value.',
            'borrowLoanLeverage': 'Leverage borrowed by SPs for capital turnover，the protocol allows SPs to withdraw the value of their pledged positions.',
            'debtRate': 'The debt ratio is a measure of the total debt of a lending pool compared to the value of its positions. It helps assess a debt\'s risk level and determine its financial stability and ability to repay.',
            'litRate': 'The liquidation threshold refers to the threshold for the debt-to-equity ratio of the lending pool. Liquidation is only triggered when the value of staked positions experiences significant reductions, causing the debt ratio to exceed this threshold, and this is done to ensure the stability of the protocol, typically after node failures.',
            'only': 'Whether the lending pool allows old nodes to onboard. Old nodes, as defined by the STFIL protocol, are nodes with staked positions valued at more than 1 FIL.',
            'poolAddress': 'Contract Address',
        },
        'not find node migrator': 'No matching node was found',
        'Migrator': 'Migrator',
        'node must be less than': 'The position value of the new node must be less than 1FIL',
        'transferOwnerTile': 'Transfer loan pool admin',
        'poolAddressErr': 'Please enter the correct contract address',
        'newPoolAdmin': 'New Pool Admin',
        'newPoolAdminTip': 'Please enter the new admin address',
        'newPoolAdminErrTip': 'Please enter the correct address',
        'transferOwnerOk': 'You have successfully transferred the loan pool。',
    },
    'home title': 'Staking',
    'home tips': 'Stake FIL and receive stFIL while staking.',
    'home': 'Home',
    'faq': 'Faq',
    'max': 'MAX',
    'you-will-receive': 'You will receive',
    'exchange-rate': 'Exchange Rate',
    'transaction-cost': 'Transaction cost',
    'reward-fee': 'Reward Fee',
    'reward-fee-tip': 'Liquidity stake protocol, built on top of Filecoin, allows users to earn block rewards without locking up assets or maintaining Storage Provider infrastructure.',
    'unstake-tip-befor': 'Default stFIL unstaking period takes around 3-4 days (80 epochs) to process. After that you can ',
    'unstake-tip-after': 'your rewards in Claim tab',
    'claim-tip-befor': 'You will be able to claim your rewards after the withdraw request has been processed. To unstake your amount go to',
    'claim-tip-after': 'tab',
    'connect-wallet': 'Connect Wallet',
    'statistics': 'statistics',
    'annual-percentage-rate': 'Annual percentage rate',
    'total-staked-with': 'Total staked with STFIL',
    'stakers': 'Stakers',
    'market-cap': 'stFIL market cap',
    'total-claimable-rewards': 'Total claimable rewards',
    'pending-amount': 'Pending amount',
    'faq_1': "What's the minimum deposit?",
    'faq_1_content': "The STFIL agreement allows anyone to earn rewards with deposits as low as 0.1 FIL. Allows holders (assets) to deposit their FIL assets into smart contracts with liquid pledge to mint a 1-for-1 pledge token stFIL, and without being locked in with us, stFIL accumulates pledge rewards wherever it is earned.",
    'faq_2': "What's the maximum deposit?",
    'faq_2_content': "Whatever you want, no limit.",
    'faq_3': "What is Liquid Staking?",
    'faq_3_content': "Liquidity pledge agreements allow users to receive pledge incentives without locking up assets or maintaining the pledge infrastructure. Users of these agreements can deposit pledged tokens and receive tradable liquid tokens in return. The DAO-controlled smart contract then pledges the token with a storage provider (SP) of the DAO's choosing. Funds deposited by the user are controlled by the DAO, and the storage provider never has direct access to the user's assets.",
    'faq_4': "How do I release the pledge?",
    'faq_4_content': "The STFIL protocol always maintains a one-to-one relationship between stFIL and FIL, and the vault will set up deposit reserves that you can trade back into the protocol for FIL as long as there is available liquidity in the protocol to cover the exchange.",
    'faq_5': "How is the pledged APR calculated?",
    'faq_5_content': "APR is denominated in stFIL, not US dollars, and is not a guaranteed or promised return or profit. The STFIL protocol provides the funds pledged by the user to a carefully selected storage provider for token pledge, and the proceeds are recast stFIL and distributed to the user, who can receive the pledge reward within 24 hours after the deposit, without waiting for the validator to activate. The user is presented with the average annualized revenue for the last 7 days.",
    'console-tip': 'Current balance may differ from last balance in the table due to rounding.',
    'lianjieqianbao': 'Connected wallet',
    'launch': {
        'home': 'Home',
        'title': 'STFIL Token Launch',
        'title2': 'Initial Liquidity Offering',
        'stage': 'STAGE',
        'the-offer': 'The offer',
        'total': 'TOTAL',
        'slots': 'SLOTS',
        'price-per-slot': 'PRICE PER SLOT',
        'start-time': 'START TIME',
        'end-time': 'END TIME',
        'More-about-the-token-mics': 'More about the token mics',
        'the-return': 'The return',
        'fdo-token': 'FDO TOKEN',
        'founder-nft': 'FOUNDER NFT',
        'claim-for-free-when-ready': 'Claim for free when ready',
        'more-about-this': 'More about this',
        'sale-stage': 'SALE STAGE',
        'whitelisted': 'WHITELISTED',
        'slots-left': 'SLOTS LEFT',
        'tip': 'After 16 hours since the start, the token sale will enter the Public Sale stage, if not sold out. One address can purchase up to 1 ILO slot.',
        'faq': 'FAQ',
        'WHITELISTED': 'Whitelisted',
        'address-filter': 'ADDRESS FILTER',
        'your-address': 'Your address',
        'tip2': 'Enter your address to check if you are included in the whitelists.',
        "can-find-the-address": "Can't find the address"
    },
    "vote": {
        'lianjieqianbao': 'Connected wallet',
        'connecting': 'connecting',
        'toupiao': 'Vote',
        'title': 'Comment on the future of STFIL',
        'proposal': 'Proposal',
        'hexin': 'Core',
        'shequ': 'Community',
        'suoyou': 'All',
        'lijietoupiao': 'Immediate vote',
        'jijiangkaishi': 'About to start',
        'yiguanbi': 'Closed',
        'chakanxiangqing': 'View details',
        'fanhui': 'Return',
        'xinxi': 'information',
        'zhuangtai': 'state',
        'biaoshifu': 'identifier',
        'gaodu': 'height',
        'toupiaozhidu': 'Voting system',
        'chaungjianshijian': 'Creation time',
        'kaishishijian': 'Start time',
        'jieshushijian': 'End time',
        'dangqianjieguo': 'Current result',
        'zhangtoupiao': 'vote',
        'toupiaoshu': 'Number of votes',
        'zangwutoupiaoshuju': 'No voting data is available',
        'touchunindepiao': 'Cast your vote',
        'pingzheng': 'certificate',
        'quxiao': 'cancel',
        'zaiyanzheng': 'Signator.io',
    }
}
