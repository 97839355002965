import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-32ffd799"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "radio-btn-group"
};
const _hoisted_2 = ["onClick"];
const _hoisted_3 = ["onClick"];
const _hoisted_4 = ["src"];
const _hoisted_5 = {
  style: {
    "font-size": "18px"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$props.tabs.length > 0 ? (_openBlock(true), _createElementBlock(_Fragment, {
    key: 0
  }, _renderList($props.tabs, (tab, index) => {
    return _openBlock(), _createElementBlock("div", {
      class: _normalizeClass(["radio-btn", $options.innerClass(tab)]),
      onClick: $event => $options.checked(tab),
      key: index
    }, _toDisplayString(tab), 11, _hoisted_2);
  }), 128)) : _createCommentVNode("", true), $props.tabArr.length > 0 ? (_openBlock(true), _createElementBlock(_Fragment, {
    key: 1
  }, _renderList($props.tabArr, (tab, index) => {
    return _openBlock(), _createElementBlock("div", {
      class: _normalizeClass(["radio-btn", $options.innerClass(tab.value)]),
      onClick: $event => $options.checked(tab.value),
      key: index
    }, [_createElementVNode("img", {
      src: tab.img,
      style: {
        "width": "20px",
        "margin-right": "6px",
        "transform": "translateY(-1px)"
      },
      alt: ""
    }, null, 8, _hoisted_4), _createElementVNode("span", _hoisted_5, _toDisplayString(tab.label), 1)], 10, _hoisted_3);
  }), 128)) : _createCommentVNode("", true)]);
}