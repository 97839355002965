import "core-js/modules/es.array.push.js";
import ChartBlock from '@/components/ChartBlock.vue';
import { externalFormatFilPrice, formatPrice, formatSubEthPrice, formatWalletFilPrice, toBigInt } from "@/utils/NumU";
import { mapState } from 'vuex';
import { formatUnits } from "ethers/lib/utils";
import PercentageMath from "@/utils/contracts/PercentageMath";
import AfterValue from './indicator/AfterValue';
import { E23, RAY, WadRayMath } from "@/utils/contracts/WadRayMath";
import Tooltip from '@/components/tip/Tooltip';
import { isEmpty } from "@/utils/model";
import ScreenWithAutMixin from "@/utils/mixin/ScreenWithAutMixin";
export default {
  name: "LeftBox",
  components: {
    ChartBlock,
    AfterValue,
    Tooltip
  },
  props: {
    nodeId: {
      type: String,
      default: ''
    }
  },
  mixins: [ScreenWithAutMixin],
  data() {
    return {
      optionInterestRateMode: {
        title: {
          show: false
        },
        legend: {
          selectedMode: false,
          orient: 'vertical',
          top: 0,
          left: '10px',
          data: ['Borrow APR,stable', 'Borrow APR,variable']
        },
        xAxis: {
          data: ['-', '-', '-', '-', '-', '-', '-', '-', '-'],
          axisTick: {
            show: false
          },
          axisLabel: {
            show: false,
            interval: 199,
            formatter: function (value) {
              return Math.floor(value) + '%';
            }
          }
        },
        grid: {
          left: '25px',
          right: '50px',
          top: '85px;'
        },
        yAxis: {
          type: 'value',
          minInterval: 40,
          max: 100,
          splitLine: {
            show: false
          },
          axisLine: {
            show: true
          },
          axisLabel: {
            show: false,
            formatter: function () {
              return '';
            }
          }
        },
        tooltip: {
          trigger: 'axis',
          showContent: true,
          formatter: params => {
            return `<div style="width: 250px;">
                        <div style="display: flex;justify-content: space-between; align-items: center;">
                            <div>${this.$t('farm.console.UtilizationRate')}</div>  <div>${params[0].axisValue}%</div>
                        </div>
                            <div style="display: flex;justify-content: space-between; align-items: center;">
                            <div>${this.$t('dashboard.BorrowAPYstable')}</div>  <div>${params[0].value}%</div>
                        </div>
                            <div style="display: flex;justify-content: space-between; align-items: center;">
                            <div>${this.$t('dashboard.BorrowAPYvariable')}</div>  <div>${params[1].value}%</div>
                        </div>
                      </div>`;
          }
        },
        series: [{
          z: 2,
          name: 'Borrow APR,stable',
          type: 'line',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0],
          showSymbol: false,
          smooth: true,
          //设置折线图颜色
          itemStyle: {
            color: '#e86016'
          },
          markLine: {
            lineStyle: {
              color: '#1dc757',
              width: 2
            },
            symbol: ['none', 'none'],
            label: {
              show: true,
              position: 'end',
              formatter: params => {
                return `Current: ${params.value / 2}%`;
              },
              distance: 8
            },
            data: [{
              name: 'Y 轴值为 100 的水平线',
              xAxis: 0
            }, [{
              coord: [0, 0],
              lineStyle: {
                color: '#e88f5f',
                width: 2,
                cap: 'round',
                join: 'round'
              },
              label: {
                distance: 5,
                position: 'start',
                show: true,
                formatter: params => {
                  return `${params.data.coord[1]}%`;
                }
              }
            }, {
              coord: [0, 0]
            }]]
          }
        }, {
          name: 'Borrow APR,variable',
          type: 'line',
          z: 2,
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0],
          showSymbol: false,
          smooth: false,
          //设置折线图颜色
          itemStyle: {
            color: '#1619d7'
          },
          markLine: {
            z: 0,
            lineStyle: {
              color: '#c9c9c9',
              width: 1,
              opacity: 0.9
            },
            symbol: ['none', 'none'],
            label: {
              show: true,
              position: 'start',
              formatter: () => {
                // if (params.dataIndex === 1) {
                //   return this.$t('farm.console.Optimal')
                // }
                // if (params.dataIndex === 0) {
                //   return 'OIR\n' + parseFloat(params.data.coord[1]).toFixed(2) + '%'
                // }
                // if (params.dataIndex === 2) {
                //   return 'MIR\n' + parseFloat(params.value).toFixed(2) + '%'
                // }
                return ``;
              },
              distance: 7
            },
            data: [[{
              coord: [0, 0]
            }, {
              coord: [0, 0]
            }], [{
              coord: [0, 0]
            }, {
              coord: [0, 0]
            }], {
              yAxis: 0
            }, [{
              coord: [0, 0]
            }, {
              coord: [0, 0]
            }], [{
              coord: [0, 0],
              lineStyle: {
                color: '#494bd5',
                width: 2
              },
              label: {
                show: true,
                formatter: params => {
                  return `${params.data.coord[1]}%`;
                }
              }
            }, {
              coord: [0, 0]
            }], [{
              coord: [0, 0]
            }, {
              coord: [0, 0]
            }], {
              yAxis: 0
            }, [{
              coord: [0, 0]
            }, {
              coord: [0, 0]
            }], [{
              coord: [0, 0]
            }, {
              coord: [0, 0]
            }]]
          },
          markArea: {
            emphasis: {
              disabled: true
            },
            silent: false,
            label: {
              show: true,
              formatter: () => {
                return this.$t('farm.console.Optimal');
              },
              position: 'bottom'
            },
            itemStyle: {
              color: 'rgba(103,82,240,0.1)'
            },
            data: [[{
              xAxis: 0,
              yAxis: 0
            }, {
              xAxis: 0,
              yAxis: 0
            }]]
          }
        }]
      },
      nodeInterestData: 0n,
      nodeInterestLoading: true
    };
  },
  watch: {
    optimalUtilizationRateIntervalEnd() {
      this.calcBorrowChartData();
    },
    utilizationRate() {
      this.utilizationRateFun();
    },
    borrowRateType() {
      this.initChartColor();
      this.initCurrenStableRate();
    },
    currentUtilizationRate() {
      this.initCurrenStableRate();
    },
    '$i18n.locale'() {
      this.initOptionBorrowInfo();
    }
  },
  created() {
    this.initOptionBorrowInfo();
    this.utilizationRateFun();
    this.calcBorrowChartData();
    this.getNodeInterest();
  },
  methods: {
    initChartColor() {
      if (this.borrowRateType === 'Variable') {
        this.optionInterestRateMode.series[0].itemStyle.color = '#e5e5e5';
        this.optionInterestRateMode.series[1].itemStyle.color = '#1619d7';
        this.optionInterestRateMode.series[1].z = 2;
        this.optionInterestRateMode.series[0].z = 1;
        this.clearBorrowTypeLine();
        this.setBorrowTypeLine();
      } else {
        this.clearBorrowTypeLine();
        this.setBorrowTypeLine();
        this.optionInterestRateMode.series[0].itemStyle.color = '#e86016';
        this.optionInterestRateMode.series[1].itemStyle.color = '#e5e5e5';
        this.optionInterestRateMode.series[0].z = 2;
        this.optionInterestRateMode.series[1].z = 1;
      }
    },
    // 绘制稳定利率初始图
    initCurrenStableRate() {
      if (this.borrowRateType === 'Stable') {
        let data = (parseInt(this.currentUtilizationRate.toString()) / 50).toFixed(2);
        // current
        let res = parseFloat(data);
        if (res >= 200.0) {
          res = 200.0;
        }
        let y = this.stableBorrowRate / E23;
        y = (parseFloat(y.toString()) / 100).toFixed(2);
        if (y > 0) {
          this.optionInterestRateMode.series[0].markLine.data[1][0].coord = [200, y];
          this.optionInterestRateMode.series[0].markLine.data[1][1].coord = [res, y];
          this.optionInterestRateMode.series[0].markLine.data[1][0].label.show = true;
        } else {
          this.optionInterestRateMode.series[0].markLine.data[1][0].coord = [0, 0];
          this.optionInterestRateMode.series[0].markLine.data[1][1].coord = [0, 0];
          this.optionInterestRateMode.series[0].markLine.data[1][0].label.show = false;
        }
      }
    },
    initOptionBorrowInfo() {
      this.optionInterestRateMode.legend.data = [this.$t('dashboard.BorrowAPRstable'), this.$t('dashboard.BorrowAPRvariable')];
      this.optionInterestRateMode.series[0].name = this.$t('dashboard.BorrowAPRstable');
      this.optionInterestRateMode.series[1].name = this.$t('dashboard.BorrowAPRvariable');
    },
    externalFormatFilPrice,
    formatWalletFilPrice,
    formatSubEthPrice,
    formatUnits,
    formatPrice,
    debtRatioColor(debtRatio, max_debt_ratio) {
      if (debtRatio < max_debt_ratio) {
        return '';
      } else {
        return 'debtRatio-color-yellow';
      }
    },
    utilizationRateFun() {
      let data = (parseInt(this.utilizationRate.toString()) / 50).toFixed(2);
      // current
      let res = parseFloat(data);
      if (res >= 200.0) {
        res = 200.0;
      }
      this.setBorrowTypeLine(res);
      this.optionInterestRateMode.series[0].markLine.data[0].xAxis = res;
    },
    setBorrowTypeLine(res) {
      let _res = res;
      if (!res) {
        let data = (parseInt(this.utilizationRate.toString()) / 50).toFixed(2);
        // current
        _res = parseFloat(data);
        if (res >= 200.0) {
          _res = 200.0;
        }
      }
      if (this.borrowRateType === 'Variable') {
        let y = this.calcVariableData(toBigInt(Math.floor(_res * 100 / 2).toString()));
        y = (parseFloat(y.toString()) / 100).toFixed(2);
        this.optionInterestRateMode.series[1].markLine.data[4][0].coord = [200, y];
        this.optionInterestRateMode.series[1].markLine.data[4][1].coord = [_res, y];
        this.optionInterestRateMode.series[1].markLine.data[4][0].label.show = true;
      } else {
        // let {x, y} = this.calcStableRate(toBigInt((Math.floor(_res * 50)).toString()))
        // if (res) {
        //   this.$store.commit('SET_AFTER_STABLE_RATE', {afterStableRate: y})
        // }
        // y = (parseFloat(y.toString()) / 100).toFixed(2)
        // if (y > 0) {
        //   this.optionInterestRateMode.series[0].markLine.data[1][0].coord = [200, y]
        //   this.optionInterestRateMode.series[0].markLine.data[1][1].coord = [x, y]
        //   this.optionInterestRateMode.series[0].markLine.data[1][0].label.show = true
        // } else {
        //   this.optionInterestRateMode.series[0].markLine.data[1][0].coord = [0, 0]
        //   this.optionInterestRateMode.series[0].markLine.data[1][1].coord = [0, 0]
        //   this.optionInterestRateMode.series[0].markLine.data[1][0].label.show = false
        // }
      }
    },
    clearBorrowTypeLine() {
      if (this.borrowRateType !== 'Variable') {
        this.optionInterestRateMode.series[1].markLine.data[4][0].coord = [0, 0];
        this.optionInterestRateMode.series[1].markLine.data[4][1].coord = [0, 0];
        this.optionInterestRateMode.series[1].markLine.data[4][0].label.show = false;
      } else {
        this.optionInterestRateMode.series[0].markLine.data[1][0].coord = [0, 0];
        this.optionInterestRateMode.series[0].markLine.data[1][1].coord = [0, 0];
        this.optionInterestRateMode.series[0].markLine.data[1][0].label.show = false;
      }
    },
    calcBorrowChartData() {
      if (this.optimalBorrowRate) {
        this.initChartColor();
        this.calcStableChartData();
        this.calcVariableChartData();
        let _currentAPR = (parseFloat((this.currentAPR / E23).toString()) / 100).toFixed(2);
        let _maxBorrowRate = (parseFloat((this.maxBorrowRate / E23).toString()) / 100).toFixed(2);
        let _optimalBorrowRate = (parseFloat((this.optimalBorrowRate / E23).toString()) / 100).toFixed(2);
        let _optimalUtilizationRateIntervalEnd = (parseFloat((this.optimalUtilizationRateIntervalEnd / E23).toString()) / 100).toFixed(1);
        let _optimalUtilizationRateIntervalStart = (parseFloat((this.optimalUtilizationRateIntervalStart / E23).toString()) / 100).toFixed(1);
        let _maxSeadLoadRateInterval = (parseFloat((this.maxSeadLoadRateInterval / E23).toString()) / 100).toFixed(1);
        let _reserveRatioBorrowVariableRate = (parseFloat((this.reserveRatioBorrowVariableRate / E23).toString()) / 100).toFixed(2);
        this.optionInterestRateMode.yAxis.max = _currentAPR * 1.05;
        this.optionInterestRateMode.series[1].markLine.data[0][0].coord = [0, _optimalBorrowRate];
        this.optionInterestRateMode.series[1].markLine.data[0][1].coord = [_optimalUtilizationRateIntervalEnd, _optimalBorrowRate];
        this.optionInterestRateMode.series[1].markLine.data[1][0].coord = [_optimalUtilizationRateIntervalEnd, 0];
        this.optionInterestRateMode.series[1].markLine.data[1][1].coord = [_optimalUtilizationRateIntervalEnd, _optimalBorrowRate];
        this.optionInterestRateMode.series[1].markLine.data[2].yAxis = _maxBorrowRate;
        let data = parseFloat(100 .toString()).toFixed(1);
        this.optionInterestRateMode.series[1].markLine.data[3][0].coord = [data, _currentAPR];
        this.optionInterestRateMode.series[1].markLine.data[3][1].coord = [data, 0];
        this.optionInterestRateMode.series[1].markLine.data[6].yAxis = _currentAPR;
        this.optionInterestRateMode.series[1].markLine.data[7][0].coord = [0, _reserveRatioBorrowVariableRate];
        this.optionInterestRateMode.series[1].markLine.data[7][1].coord = [_maxSeadLoadRateInterval, _reserveRatioBorrowVariableRate];
        this.optionInterestRateMode.series[1].markLine.data[8][0].coord = [_maxSeadLoadRateInterval, 0];
        this.optionInterestRateMode.series[1].markLine.data[8][1].coord = [_maxSeadLoadRateInterval, _reserveRatioBorrowVariableRate];
        this.optionInterestRateMode.series[1].markLine.data[5][0].coord = [_optimalUtilizationRateIntervalStart, 0];
        this.optionInterestRateMode.series[1].markLine.data[5][1].coord = [_optimalUtilizationRateIntervalStart, _optimalBorrowRate];
        this.optionInterestRateMode.series[1].markArea.data[0][0].xAxis = _optimalUtilizationRateIntervalStart;
        this.optionInterestRateMode.series[1].markArea.data[0][0].yAxis = _optimalBorrowRate;
        this.optionInterestRateMode.series[1].markArea.data[0][1].xAxis = _optimalUtilizationRateIntervalEnd;
        this.optionInterestRateMode.series[1].markArea.data[0][0].yAxis = _optimalBorrowRate;
      }
    },
    calcStableChartData() {
      let stableYData = [];
      let stableXData = [];
      let step = 50;
      let maxUt = 10000;
      let startUt = 0;
      let optimalUtilizationRateIntervalStart = this.optimalUtilizationRateIntervalStart / E23;
      let optimalBorrowRate = this.optimalBorrowRate / E23;
      for (let ut = startUt; ut <= maxUt; ut = ut + step) {
        let data = 0n;
        if (ut <= optimalUtilizationRateIntervalStart) {
          data = optimalBorrowRate;
        } else {
          data = this.calcStableRateFormula(WadRayMath.rayDiv(toBigInt(ut), 10000n));
        }
        stableYData.push((parseFloat(data.toString()) / 100).toFixed(2));
        stableXData.push((ut / 100).toFixed(1));
      }
      this.optionInterestRateMode.xAxis.data = stableXData;
      this.optionInterestRateMode.series[0].data = stableYData;
    },
    /* eslint-disable */
    calcVariableChartData() {
      let variableYData = [];
      let step = 50n;
      let maxUt = 10000n;
      let startUt = 0n;
      let optimalUtilizationRateIntervalEnd = this.optimalUtilizationRateIntervalEnd / E23;
      let maxSeadLoadRateInterval = this.maxSeadLoadRateInterval / E23;
      let baseVariableBorrowRate = this.baseVariableBorrowRate / E23;
      let k1 = WadRayMath.rayDiv(this.optimalBorrowRate - this.baseVariableBorrowRate, this.optimalUtilizationRateIntervalEnd);
      let k2 = WadRayMath.rayDiv(this.reserveRatioBorrowVariableRate - this.optimalBorrowRate, this.maxSeadLoadRateInterval - this.optimalUtilizationRateIntervalEnd);
      let k3 = WadRayMath.rayDiv(this.maxBorrowRate - this.reserveRatioBorrowVariableRate, RAY - this.maxSeadLoadRateInterval);
      let rMax2 = this.reserveRatioBorrowVariableRate / E23;
      let rMax = this.maxBorrowRate / E23;
      for (let ut = startUt; ut <= maxUt; ut = ut + step) {
        let data = 0n;
        if (ut <= optimalUtilizationRateIntervalEnd) {
          data = baseVariableBorrowRate + WadRayMath.rayMul(k1, ut);
        } else if (ut <= maxSeadLoadRateInterval) {
          data = rMax2 - toBigInt(WadRayMath.rayMul(k2, maxSeadLoadRateInterval - ut));
        } else {
          data = rMax - toBigInt(WadRayMath.rayMul(k3, toBigInt(10000) - ut));
        }
        variableYData.push((parseFloat(data.toString()) / 100).toFixed(2));
      }
      this.optionInterestRateMode.series[1].data = variableYData;
    },
    calcVariableData(t) {
      if (t > 10000n) {
        t = 10000n;
      }
      let optimalUtilizationRateIntervalEnd = this.optimalUtilizationRateIntervalEnd / E23;
      let baseVariableBorrowRate = this.baseVariableBorrowRate / E23;
      let k1 = WadRayMath.rayDiv(this.optimalBorrowRate - this.baseVariableBorrowRate, this.optimalUtilizationRateIntervalEnd);
      let k2 = WadRayMath.rayDiv(this.maxBorrowRate - this.optimalBorrowRate, RAY - this.optimalUtilizationRateIntervalEnd);
      let rMax = this.maxBorrowRate / E23;
      let res = 0;
      if (t <= optimalUtilizationRateIntervalEnd) {
        res = baseVariableBorrowRate + WadRayMath.rayMul(k1, t);
      } else {
        res = rMax - toBigInt(WadRayMath.rayMul(k2, toBigInt(10000) - t));
      }
      if (this.youAreBorrowing === 0n) {
        this.$store.commit('SET_AFTER_VARIABLE_RATE', {
          afterVariableRate: this.variableBorrowRate / E23
        });
      } else {
        this.$store.commit('SET_AFTER_VARIABLE_RATE', {
          afterVariableRate: res
        });
      }
      return res;
    },
    //计算当前的利用率下的稳定利率
    calcStableRate(t) {
      if (t > 10000n) {
        t = 10000n;
      }
      let _utilizationRate = WadRayMath.rayDiv(t, 10000n);
      let y = this.calcStableRateFormula(_utilizationRate);
      let optimalBorrowRate = this.optimalBorrowRate / E23;
      if (y < optimalBorrowRate) {
        y = optimalBorrowRate;
      }
      return y;
    },
    calcStableRateFormulaToY(stableRate) {
      let optimalBorrowRate = this.optimalBorrowRate / E23;
      let up = stableRate - optimalBorrowRate;
      if (up < 0) {
        up = 0n;
      }
      let down = (this.maxBorrowRate - this.optimalBorrowRate) / E23;
      up = parseInt(up.toString());
      down = parseInt(down.toString());
      return Math.floor(Math.cbrt(parseFloat((up / down).toString())) * 200);
    },
    calcStableRateFormula(utilizationRate) {
      let bezierUtilizationRate = WadRayMath.rayMul(utilizationRate - this.optimalUtilizationRateIntervalStart, WadRayMath.rayDiv(WadRayMath.RAY, WadRayMath.RAY - this.optimalUtilizationRateIntervalStart));
      const utilizationMulti = WadRayMath.rayMultiPow(bezierUtilizationRate, 2);
      const vacancyMulti = WadRayMath.rayMultiPow(WadRayMath.RAY - bezierUtilizationRate, 2);
      let res = WadRayMath.rayMul(vacancyMulti[2], this.optimalBorrowRate) + WadRayMath.rayMul(WadRayMath.rayMul(WadRayMath.rayMul(WadRayMath.TOW_RAY, vacancyMulti[1]), utilizationMulti[1]), this.optimalBorrowRate) + WadRayMath.rayMul(utilizationMulti[2], this.currentAPR);
      return res / E23;
    },
    _calcStableRateAvg() {
      let _userStableRate = toBigInt(this.userStableRate / E23);
      let up = PercentageMath.mul(this.stableDebtValue, _userStableRate);
      let ret = PercentageMath.div(up, this.stableDebtValue);
    },
    async getNodeInterest() {
      if (isEmpty(this.nodeId)) {
        return;
      }
      this.nodeInterestLoading = true;
      let interest = await this.$store.dispatch('getNodeInterest', {
        nodeId: this.nodeId
      });
      this.nodeInterestData = toBigInt(interest);
      this.nodeInterestLoading = false;
    }
  },
  computed: mapState({
    currentLeverage: state => state.nodeData.currentLeverage,
    utilizationRate() {
      return PercentageMath.div(this.borrowBalance + this.youAreBorrowing, this.totalSupply);
    },
    // 当前时刻固定的利用率
    currentUtilizationRate() {
      return PercentageMath.div(this.borrowBalance, this.totalSupply);
    },
    totalSupply() {
      return this.borrowBalance + this.poolStFilBalanceBig;
    },
    liquidityReserveRatio: state => state.publicInfo.liquidityReserveRatio,
    maxSeadLoadRateInterval() {
      return RAY - this.liquidityReserveRatio;
    },
    reserveRatioBorrowVariableRate: state => state.publicInfo.reserveRatioBorrowVariableRate,
    poolStFilBalanceBig: state => state.publicInfo.poolStFilBalance,
    borrowRateType: state => state.nodeData.borrowRateType,
    maxBorrowRate: state => state.publicInfo.maxBorrowRate,
    maxBorrowSimpleRate() {
      return (parseFloat((this.maxBorrowRate / E23).toString()) / 100).toFixed(2);
    },
    optimalBorrowSimpleRate() {
      return (parseFloat((this.optimalBorrowRate / E23).toString()) / 100).toFixed(2);
    },
    optimalBorrowRate: state => state.publicInfo.optimalBorrowRate,
    optimalUtilizationRateIntervalStart: state => state.publicInfo.optimalUtilizationRateIntervalStart,
    optimalUtilizationRateIntervalEnd: state => state.publicInfo.optimalUtilizationRateIntervalEnd,
    currentAPR: state => state.publicInfo.currentAPR,
    baseVariableBorrowRate: state => state.publicInfo.baseVariableBorrowRate,
    afterDebtValue() {
      return this.debtValue + this.youAreBorrowing;
    },
    afterCurrentNodeDebtValue() {
      return this.guaranteeCurrenDebtValue + this.youAreBorrowing;
    },
    afterPositionValue() {
      return this.positionValue + this.youAreBorrowing;
    },
    afterPositionValueByWithDraw() {
      let res = this.positionValue - this.youAreWithdraw;
      if (res < 0n) {
        return 0n;
      }
      return res;
    },
    equityValue() {
      return this.positionValue - this.debtValue;
    },
    afterEquityValue() {
      let res = this.afterPositionValue - this.afterDebtValue;
      if (res < 0n) {
        return 0n;
      }
      return res;
    },
    afterEquityValueByWithDraw() {
      let res = this.positionValue - this.debtValue - this.youAreWithdraw;
      if (res < 0n) {
        return 0;
      }
      return res;
    },
    availableBalance() {
      return toBigInt(this.currentNode.availableBalance);
    },
    variableDebtValue() {
      return toBigInt(this.currentNode.variable_debt_value);
    },
    variableDebtValueProportion() {
      return PercentageMath.div(this.guaranteeCurrenVariableDebtValue, this.guaranteeCurrenDebtValue);
    },
    stableDebtValueProportion() {
      return PercentageMath.div(this.guaranteeCurrenStableDebtValue, this.guaranteeCurrenDebtValue);
    },
    afterVariableDebtValue() {
      if (this.borrowMode === 0) {
        return this.variableDebtValue;
      }
      if (this.borrowMode === 2) {
        return this.variableDebtValue + this.youAreBorrowing;
      } else {
        return this.variableDebtValue;
      }
    },
    stableDebtValue() {
      return toBigInt(this.currentNode.stable_debt_value);
    },
    afterStableDebtValue() {
      if (this.borrowMode === 1) {
        return this.stableDebtValue + this.youAreBorrowing;
      } else {
        return this.stableDebtValue;
      }
    },
    positionValue() {
      return toBigInt(this.currentNode.position_value);
    },
    maxLeverage() {
      return toBigInt(this.currentNode.max_leverage);
    },
    debtValue() {
      return toBigInt(this.currentNode.debt_value);
    },
    availableBorrowingAmount() {
      let debt_value = toBigInt(this.currentNode.debt_value);
      let res = PercentageMath.mul(this.equityValue, toBigInt(this.currentNode.max_leverage) - toBigInt(10000)) - debt_value;
      if (res < 0) {
        return 0n;
      }
      return res;
    },
    afterAvailableBorrowingAmount() {
      let debt_value = toBigInt(this.currentNode.debt_value) + this.youAreBorrowing;
      let res = PercentageMath.mul(this.equityValue, toBigInt(this.currentNode.max_leverage) - toBigInt(10000)) - debt_value;
      if (res < 0) {
        return 0n;
      }
      return res;
    },
    afterAvailableBorrowingAmountByWithdraw() {
      let debt_value = toBigInt(this.currentNode.debt_value);
      let equityValue = this.equityValue - this.youAreWithdraw;
      let res = PercentageMath.mul(equityValue, toBigInt(this.currentNode.max_leverage) - toBigInt(10000)) - debt_value;
      if (res < 0n) {
        return 0n;
      }
      return res;
    },
    afterDebtRatioByWithdraw() {
      let debtValue = toBigInt(this.currentNode.debt_value);
      if (debtValue === 0n) {
        return 0n;
      }
      let positionValue = toBigInt(this.currentNode.position_value) - this.youAreWithdraw;
      return PercentageMath.div(debtValue, positionValue);
    },
    borrowBalance: state => {
      return state.publicInfo.variableDebtTokenTotalSupply + state.publicInfo.stableDebtTokenTotalSupply;
    },
    supplyBalance: state => state.stakeInfo.totalStakingTokens,
    liquidityRate: state => state.publicInfo.liquidityRate,
    variableBorrowRate: state => state.publicInfo.variableBorrowRate,
    stableBorrowRate: state => state.publicInfo.stableBorrowRate,
    userStableRate() {
      return this.currentNode.userStableRate;
    },
    userAvgRate() {
      let all = PercentageMath.mul(this.currentNode.userStableRate, this.guaranteeCurrenStableDebtValue) + PercentageMath.mul(this.variableBorrowRate, this.guaranteeCurrenVariableDebtValue);
      return PercentageMath.div(all, this.guaranteeCurrenDebtValue);
    },
    variableBorrowIndex: state => state.publicInfo.variableBorrowIndex,
    liquidityIndex: state => state.publicInfo.liquidityIndex,
    liquidationThreshold: state => state.publicInfo.liquidationThreshold,
    currentNode() {
      return this.$store.state.nodeData.currentNode;
    },
    currentNodeId() {
      return this.currentNode.node;
    },
    role() {
      let _role = parseInt(this.currentNode.role);
      if (_role === 1) {
        return 'Owner';
      } else if (_role === 2) {
        return 'Beneficiary';
      }
    },
    rightPanelType() {
      return this.$store.state.nodeData.rightPanelType;
    },
    youAreBorrowing() {
      return this.$store.state.nodeData.youAreBorrowing;
    },
    youAreWithdraw() {
      return this.$store.state.nodeData.youAreWithdraw;
    },
    borrowMode() {
      return this.$store.state.nodeData.borrowMode;
    },
    isBorrowing() {
      return this.currentNode.borrowing;
    },
    maxDebtRatio() {
      return PercentageMath.div(toBigInt(this.currentNode.max_leverage) - toBigInt(10000), toBigInt(this.currentNode.max_leverage));
    },
    nodeDataLoading: state => state.nodeData.loading,
    publicInfoLoading: state => state.publicInfo.loading,
    stakeInfoLoading: state => state.stakeInfo.loading,
    currentLoading() {
      return this.nodeDataLoading || this.publicInfoLoading || this.stakeInfoLoading;
    },
    debtRatio() {
      let debtValue = this.debtValue;
      if (debtValue === 0n) {
        return 0n;
      }
      let positionValue = this.positionValue;
      return PercentageMath.div(debtValue, positionValue);
    },
    afterDebtRatio() {
      let debtValue = this.debtValue + this.youAreBorrowing;
      if (debtValue === 0n) {
        return 0n;
      }
      let positionValue = this.positionValue + this.youAreBorrowing;
      return PercentageMath.div(debtValue, positionValue);
    },
    safetyBuffer() {
      return toBigInt(this.currentNode.safetyBuffer);
    },
    afterSafetyBuffer() {
      if (this.youAreBorrowing <= 0) {
        if (this.youAreBorrowing + this.debtValue <= 0) {
          return 0n;
        }
        return this.safetyBuffer + this.youAreBorrowing;
      } else {
        let _debtValue = this.debtValue + this.youAreBorrowing;
        let tmp = PercentageMath.div(_debtValue, this.maxLeverage - toBigInt(10000));
        let sb1 = _debtValue + tmp;
        let sb2 = this.positionValue - this.availableBalance + this.youAreBorrowing;
        let sb3 = this.safetyBuffer + this.youAreBorrowing;
        let sbv = sb1 > sb2 ? sb1 : sb2;
        return sbv > sb3 ? sbv : sb3;
      }
    },
    currentFarmAPR() {
      return PercentageMath.mul(PercentageMath.div(this.positionValue, this.equityValue), toBigInt(this.maxBorrowRate / E23));
    },
    afterCurrentFarmAPR() {
      return PercentageMath.mul(PercentageMath.div(this.afterPositionValue, this.equityValue), toBigInt(this.maxBorrowRate / E23));
    },
    currentBorrowingInterestAPR() {
      let _variable = PercentageMath.mul(this.variableDebtValue, toBigInt(this.variableBorrowRate / E23));
      let _stable = PercentageMath.mul(this.stableDebtValue, toBigInt(this.userStableRate / E23));
      return PercentageMath.div(_variable + _stable, this.equityValue);
    },
    afterVariableRate: state => state.nodeData.afterVariableRate,
    afterStableRate: state => state.nodeData.afterStableRate,
    afterCurrentBorrowingInterestAPR() {
      let addVariable = 0n;
      let addStable = 0n;
      if (this.borrowMode === 0 || this.borrowMode === 2) {
        addVariable = this.youAreBorrowing;
      } else {
        addStable = this.youAreBorrowing;
      }
      let _variable = PercentageMath.mul(this.variableDebtValue + addVariable, this.afterVariableRate);
      let _stable = PercentageMath.mul(this.stableDebtValue + addStable, this.afterStableRate);
      return PercentageMath.div(_variable + _stable, this.equityValue);
    },
    totalAPR() {
      return this.currentFarmAPR - this.currentBorrowingInterestAPR;
    },
    afterTotalAPR() {
      return this.afterCurrentFarmAPR - this.afterCurrentBorrowingInterestAPR;
    },
    dailyAPR() {
      return toBigInt(Math.floor(parseFloat(formatUnits(this.totalAPR.toString(), 2)) / 365 * 1000000));
    },
    afterDailyAPR() {
      return toBigInt(Math.floor(parseFloat(formatUnits(this.afterTotalAPR.toString(), 2)) / 365 * 1000000));
    },
    estimatedDailyEarnings() {
      return PercentageMath.mul(this.positionValue, this.dailyAPR) / 10000n;
    },
    afterEstimatedDailyEarnings() {
      return PercentageMath.mul(this.afterPositionValue, this.afterDailyAPR) / 10000n;
    },
    guaranteeNodeId() {
      return this.currentNode.guaranteeNodeId;
    },
    guaranteeNode() {
      return this.currentNode.guarantee.guaranteeNode;
    },
    guaranteePositionValue() {
      return this.guaranteeNode.position_value;
    },
    guaranteeVariableDebtValue() {
      return this.guaranteeNode.variable_debt_value;
    },
    guaranteeVtableDebtValue() {
      return this.guaranteeNode.stable_debt_value;
    },
    guaranteeCurrenNode() {
      return this.currentNode.guarantee.currentNode;
    },
    guaranteeCurrenVariableDebtValue() {
      return this.guaranteeCurrenNode.variable_debt_value;
    },
    guaranteeCurrenStableDebtValue() {
      return this.guaranteeCurrenNode.stable_debt_value;
    },
    guaranteeCurrentPositionValue() {
      return this.guaranteeCurrenNode.position_value;
    },
    guaranteeCurrenDebtValue() {
      return this.guaranteeCurrenNode.debt_value;
    },
    guaranteeDebtValue() {
      return this.guaranteeNode.debt_value;
    }
  })
};