import {BigNumber, ethers} from "ethers";
import {formatUnits} from "ethers/lib/utils";
import {isEmpty} from "@/utils/model";

const ZERO = '000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000'

let toMaxFixed = (val, fixed) => {

    let _val = val.toString()
    if (_val.indexOf('.') > 0) {

        let arr = _val.split('.')
        if (arr[1].length <= fixed) {
            return _val
        } else {
            return arr[0] + '.' + arr[1].substr(0, fixed)
        }

    } else {
        return _val
    }

}

let formatPrice = (price, toFixed = 4, emptyRes) => {
    let _price = price
    if (isNaN(_price)) {
        if (!emptyRes) {
            return '0' + '.' + ZERO.substr(0, toFixed)
        } else {
            return emptyRes
        }
    }

    if (!toFixed) {
        toFixed = 6
    }

    _price = _price.toString()
    if (_price.indexOf('.') > 0) {
        let data = _price.split(".");
        if (data[1].length >= toFixed) {
            return parseInt(data[0]).toLocaleString('zh-CN') + '.' + data[1].substr(0, toFixed)
        } else {
            return parseInt(data[0]).toLocaleString('zh-CN') + '.' + data[1].substr(0, data[1].length) + ZERO.substr(0, toFixed - data[1].length)
        }
    } else {
        return parseInt(_price).toLocaleString('zh-CN')
    }
}

let formatPriceToMaxFixed = (price, toFixed = 4, emptyRes) => {
    let _price = price
    if (isNaN(parseFloat(_price.toString()))) {
        if (!emptyRes) {
            return '0' + '.' + ZERO.substr(0, toFixed)
        } else {
            return emptyRes
        }
    }

    if (toFixed === null) {
        toFixed = 6
    }

    _price = _price.toString()
    if (_price.indexOf('.') > 0) {
        let data = _price.split(".");
        if (toFixed === 0) {
            return parseInt(data[0]).toLocaleString('zh-CN')
        }
        if (data[1].length >= toFixed) {
            return parseInt(data[0]).toLocaleString('zh-CN') + '.' + data[1].substr(0, toFixed)
        } else {
            return parseInt(data[0]).toLocaleString('zh-CN') + '.' + data[1].substr(0, data[1].length) + ZERO.substr(0, toFixed - data[1].length)
        }
    } else {
        return parseInt(_price).toLocaleString('zh-CN')
    }
}

let formatEthPrice = (price, toFixed = 4) => {
    let _price = BigNumber.from(price)
    _price = ethers.utils.formatEther(_price)
    return formatPrice(_price, toFixed)
}

// 将 fil 格式化
let formatFilPrice = (price, toFixed, isStFil = false, showUnit = true) => {

    let unitStr = ' FIL'
    if (isStFil) {
        unitStr = ' stFIL'
    }
    if (!showUnit) {
        unitStr = ''
    }
    if (!price || isEmpty(price)) {
        return `0.0 ${unitStr}`
    }
    let _price = price.toString()
    let len = _price.length
    if (len <= 9) {
        if (_price === '0' || _price === '') {
            return '0' + unitStr
        }
        if (unitStr !== '') {
            unitStr = `atto${unitStr}`
        }
        return formatPrice(_price, 0) + ` ${unitStr}`
    } else if (len > 9 && len <= 15) {

        let nano = formatUnits(_price, 9)
        if (unitStr !== '') {
            unitStr = `nano${unitStr}`
        }
        return formatPriceToMaxFixed(nano, 9) + ` ${unitStr}`

    } else if (len > 15 && len <= 18) {
        let nano = formatUnits(_price, 9)
        if (unitStr !== '') {
            unitStr = `nano${unitStr}`
        }
        return formatPriceToMaxFixed(nano, 6) + ` ${unitStr}`
    } else {
        let fil = formatUnits(_price, 18)
        return formatPriceToMaxFixed(fil, 6) + unitStr
    }
}

// 钱包余额用
let formatWalletFilPrice = (price, toFixed = 2, isStFil = false, showLongZero = true, showUniStr = true) => {
    return formatWalletFilPrice2(price, toFixed, isStFil, showLongZero, showUniStr)
}

let formatWalletFilPrice2 = (price, toFixed = 2, isStFil = false, showLongZero = true, showUniStr = true, fixed = false) => {

    let unitStr = ' FIL'
    if (isStFil) {
        unitStr = ' stFIL'
    }
    if (!showUniStr) {
        unitStr = ''
    }
    if (!price || isEmpty(price)) {
        return `0.0 ${unitStr}`
    }
    let _price = price.toString()
    let len = _price.length
    if (len <= 18) {
        if ((_price === '0' || !_price) && !showLongZero) {
            return `0.0 ${unitStr}`
        }
        let fil = formatUnits(_price, 18)
        return formatPriceToMaxFixed(fil, fixed ? toFixed : 8) + `${unitStr}`
    } else {
        let fil = formatUnits(_price, 18)
        return formatPriceToMaxFixed(fil, toFixed) + unitStr
    }

}

// 钱包余额用
let formatWalletFilPriceInterest = (price, toFixed, isStFil = false, showUniStr = true) => {

    let unitStr = ' FIL'
    if (isStFil) {
        unitStr = ' stFIL'
    }
    if (!showUniStr) {
        unitStr = ''
    }
    if (!price || isEmpty(price)) {
        return `0.0 ${unitStr}`
    }
    let _price = price.toString()
    let len = _price.length
    if (len <= 18) {
        return `0`
    } else {
        let fil = formatUnits(_price, 18)
        return formatPriceToMaxFixed(fil, 2) + unitStr
    }

}

let externalFormatFilPrice = (price, toFixed, isStFil = false, showUnit = true) => {

    let unitStr = ' FIL'
    if (isStFil) {
        unitStr = ' stFIL'
    }
    if (!showUnit) {
        unitStr = ''
    }
    if (!price || isEmpty(price)) {
        return `0.0 ${unitStr}`
    }
    let _price = price.toString()
    let len = _price.length
    if (len <= 7) {
        if (_price === '0' || _price === '') {
            return '0' + unitStr
        }
        if (unitStr !== '') {
            unitStr = `atto${unitStr}`
        }
        return formatPrice(_price, 0) + ` ${unitStr}`
    } else if (len > 7 && len <= 16) {
        let nano = formatUnits(_price, 9)
        if (unitStr !== '') {
            unitStr = `nano${unitStr}`
        }
        return formatPriceToMaxFixed(nano, 4) + ` ${unitStr}`
    } else {
        let fil = formatUnits(_price, 18)
        return formatPriceToMaxFixed(fil, 2) + unitStr
    }

}

let externalFormatFilPriceNoFractional = (price, toFixed, isStFil = false, showUnit = true) => {

    let unitStr = ' FIL'
    if (isStFil) {
        unitStr = ' stFIL'
    }
    if (!showUnit) {
        unitStr = ''
    }
    if (!price || isEmpty(price)) {
        return `0 ${unitStr}`
    }
    let _price = price.toString()
    let len = _price.length
    if (len <= 7) {
        if (_price === '0' || _price === '') {
            return '0' + unitStr
        }
        return formatPrice(_price, 0) + ` atto${unitStr}`
    } else if (len > 7 && len <= 16) {
        let nano = formatUnits(_price, 9)
        return formatPriceToMaxFixed(nano, 0) + ` nano${unitStr}`
    } else {
        let fil = formatUnits(_price, 18)
        return formatPriceToMaxFixed(fil, 0) + unitStr
    }

}

let formatSubEthPrice = (price, price2, toFixed = 4) => {
    let _price = BigNumber.from(price)
    let _price2 = BigNumber.from(price2)

    return externalFormatFilPrice(_price.sub(_price2).toString(), toFixed)
}

let formatAddEthPrice = (price, price2, toFixed = 4) => {
    let _price = BigNumber.from(price)
    let _price2 = BigNumber.from(price2)

    _price = ethers.utils.formatEther(_price.add(_price2))
    return formatPrice(_price, toFixed)
}

let formatBit = (val, toPrecision = 2) => {
    let _val = parseInt(val)
    if (isNaN(_val)) {
        return '0'
    }
    if (_val === 0) return '0 B';
    let k = 1024, // or 1024
        sizes = ['B', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'],
        i = Math.floor(Math.log(_val) / Math.log(k));

    if (!toPrecision) {
        return (_val / Math.pow(k, i)) + ' ' + sizes[i];
    }
    return toMaxFixed((_val / Math.pow(k, i)), toPrecision) + ' ' + sizes[i]
}

let formatBitTopPib = (val, toPrecision = 2) => {
    let _val = parseInt(val)
    if (isNaN(_val)) {
        return '0'
    }
    if (_val === 0) return '0 B';
    let k = 1024, // or 1024
        sizes = ['B', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB'],
        i = Math.floor(Math.log(_val) / Math.log(k));
    if (i > sizes.length - 1) {
        i = sizes.length - 1
    }
    if (!toPrecision) {
        return (_val / Math.pow(k, i)) + ' ' + sizes[i];
    }
    return toMaxFixed((_val / Math.pow(k, i)), toPrecision) + ' ' + sizes[i]
}

let toBigInt = (val) => {
    // eslint-disable-next-line no-undef
    return BigInt(val)
}

let toBigIntDefault = (val, defaultVal = 0n) => {
    if (val === null || val === undefined) {
        return defaultVal
    }
    // eslint-disable-next-line no-undef
    return BigInt(val)
}

let toEth = (val) => {
    if (val === undefined || val === '') {
        return 0n
    }
    return ethers.utils.parseEther(val.toString()).toBigInt()
}

let ethTo = (val) => {
    if (val === undefined || val === '') {
        return 0n
    }
    return parseFloat(ethers.utils.formatEther(val.toString()))
}

let floatToFixed = (val, fixed) => {
    if (val.toString().indexOf('.') > 0) {
        let arr = val.toString().split(".");
        return arr[0] + "." + arr[1].substr(0, fixed)
    } else {
        return val.toString()
    }

}

export {
    formatPrice,
    formatBit,
    formatEthPrice,
    formatSubEthPrice,
    formatAddEthPrice,
    toBigInt,
    toEth,
    ethTo,
    floatToFixed,
    formatFilPrice,
    externalFormatFilPrice,
    toMaxFixed,
    formatWalletFilPrice,
    toBigIntDefault,
    formatWalletFilPriceInterest,
    externalFormatFilPriceNoFractional,
    formatPriceToMaxFixed,
    formatBitTopPib,
    formatWalletFilPrice2
}
