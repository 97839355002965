import nodejsHttp from '@/utils/http/nodejsHttp'
import {readContract} from '@wagmi/core'
import {contracts} from "@/utils/contracts/config";
import StakingPoolABI from '@/utils/abi/StakingPool.json'
import VariableDebtToken from '@/utils/abi/VariableDebtToken.json'
import StableDebtToken from '@/utils/abi/StableDebtToken.json'
import StableDebtTokenABI from '@/utils/abi/StableDebtToken.json'
import {retryFun, walletProvider} from "@/utils/LoutsRpc";
import {BigNumber} from "ethers";
import {getActorAddress, getPrefix, NotificationErr} from "@/utils/common";
import {NodeConfiguration} from "@/utils/contracts/NodeConfiguration";
import {toBigInt} from "@/utils/NumU";
import {E23} from "@/utils/contracts/WadRayMath";
import PercentageMath from "@/utils/contracts/PercentageMath";
import {isEmpty} from "@/utils/model";

let nodeData = {
    state: {
        domain: process.env.VUE_APP_ADMIN_API,
        nodes: [],
        allNodes: [],
        history: [],
        youAreBorrowing: 0n,
        youAreWithdraw: 0n,
        borrowRateType: 'Variable',
        rightPanelType: 'Borrowing & Repayment',
        borrowMode: 1,
        loading: false,
        currentNode: {
            beneficiary: '0',
            borrowing: '1',
            debt_value: '0',
            stable_debt_value: '0',
            variable_debt_value: '0',
            delegate: '1',
            delegate_tipset: '0',
            liquidation_threshold: '0',
            max_leverage: 0,
            node: '',
            owner: '',
            position_value: '0',
            quality_adj_power: '0',
            raw_byte_power: '0',
            role: '1',
            sector_active: '0',
            sector_faulty: '0',
            sector_live: '0',
            sector_size: '0',
            undelegate_tipset: '0',
            operator: '',
            safetyBuffer: '0',
            availableBalance: '0',
            pledge: '0',
            lockedRewards: '0',
            is_active: '1',
            oldOwner: '',
            userStableRate: 0n,
            guaranteeNodeId: -1,
            guarantee: {
                currentNode: {
                    position_value: 0n,
                    variable_debt_value: 0n,
                    stable_debt_value: 0n,
                    debt_value: 0n,
                    max_leverage: 0
                },
                guaranteeNode: {
                    position_value: 0n,
                    variable_debt_value: 0n,
                    stable_debt_value: 0n,
                    debt_value: 0n,
                    max_leverage: 0
                }
            }
        },
        dataLoading: {
            positionValueLoading: false,
            debtValueLoading: false,
        },
        afterStableRate: 0n,
        afterVariableRate: 0n,
        currentLeverage: 0n,
    },
    getters: {},
    mutations: {
        SET_ALL_NODES(state, allNodes){
            state.allNodes = allNodes
        },
        SET_POSITION_VALUE(state, {position_value}) {
            state.currentNode.position_value = position_value
        },
        SET_POSITION_VALUE_LOADING(state, {positionValueLoading}) {
            state.dataLoading.positionValueLoading = positionValueLoading
        },
        SET_DEBT_VALUE(state, {debtValue}) {
            state.currentNode.debt_value = debtValue
        },
        SET_DEBT_VALUE_LOADING(state, {debtValueLoading}) {
            state.dataLoading.debtValueLoading = debtValueLoading
        },
        SET_CURRENT_LEVERAGE(state, {currentLeverage}) {
            state.currentLeverage = currentLeverage
        },
        SET_AFTER_STABLE_RATE(state, {afterStableRate}) {
            state.afterStableRate = afterStableRate
        },
        SET_AFTER_VARIABLE_RATE(state, {afterVariableRate}) {
            state.afterVariableRate = afterVariableRate
        },
        SET_CURRENT_NODE_DATA_LOADING(state, {loading}) {
            state.loading = loading
        },
        SET_BORROW_RATE_TYPE(state, {borrowRateType}) {
            state.borrowRateType = borrowRateType
        },
        SET_YOU_ARE_BORROWING(state, {youAreBorrowing, borrowMode}) {
            state.youAreBorrowing = youAreBorrowing
            state.borrowMode = borrowMode
        },
        SET_YOU_ARE_WITHDRAW(state, {youAreWithdraw}) {
            state.youAreWithdraw = youAreWithdraw
        },
        SET_RIGHT_PANEL_TYPE(state, {rightPanelType}) {
            state.rightPanelType = rightPanelType
        },
        RESET_YOU_ARE_BORROWING(state) {
            state.youAreBorrowing = 0n
            state.borrowMode = 0
        },
        RESET_YOU_ARE__WITHDRAW(state) {
            state.youAreWithdraw = 0n
        },
        SET_NODES_DATA(state, nodes) {
            state.nodes = nodes
        },
        SET_NODE_HISTORY_DATA(state, history) {
            state.history = history
        },
        SET_CURRENT_NODE(state, node) {
            state.currentNode = Object.assign(state.currentNode, node)
        },
        RESET_CURRENT_NODE_DATA(state) {
            state.currentNode = {
                beneficiary: '0',
                borrowing: '1',
                debt_value: '0',
                stable_debt_value: '0',
                variable_debt_value: '0',
                delegate: '1',
                delegate_tipset: '0',
                liquidation_threshold: '0',
                max_leverage: 0,
                node: '',
                owner: '',
                position_value: '0',
                quality_adj_power: '0',
                raw_byte_power: '0',
                role: '1',
                sector_active: '0',
                sector_faulty: '0',
                sector_live: '0',
                sector_size: '0',
                undelegate_tipset: '0',
                operator: '',
                safetyBuffer: '0',
                availableBalance: '0',
                pledge: '0',
                lockedRewards: '0',
                is_active: '1',
                userStableRate: 0n,
                guaranteeNodeId: -1,
                guarantee: {
                    currentNode: {
                        position_value: 0n,
                        variable_debt_value: 0n,
                        stable_debt_value: 0n,
                        debt_value: 0n,
                        max_leverage: 0
                    },
                    guaranteeNode: {
                        position_value: 0n,
                        variable_debt_value: 0n,
                        stable_debt_value: 0n,
                        debt_value: 0n,
                        max_leverage: 0
                    }
                }
            }
        }
    },
    actions: {
        // eslint-disable-next-line no-empty-pattern
        async updateRemoteNodeData({}, {node}) {
            try {
                await nodejsHttp.put('node?node=' + node)
            } catch (e) {
                console.log('err updateNodeData')
            }
        },
        async getNodesData({commit}, {delegate, user}) {
            try {
                let nodes = await nodejsHttp.get('node?delegate=' + delegate + '&user=' + user)
                let newNodes = []
                let allNodes = []
                let bindNodeMap = {}
                for (let i = 0; i < nodes.length; i++) {
                    let node = nodes[i]
                    allNodes.push({
                        'quality_adj_power': node['quality_adj_power']
                    })
                    if (!isEmpty(node.pool_address)) {
                        continue
                    }
                    if (bindNodeMap[node.node] === 1) {
                        continue
                    }
                    let {bind_node} = node
                    if (bind_node !== null) {
                        let mergeNode = {}
                        let bNode = nodes.find(n => n.node === bind_node)

                        mergeNode.isMergeNode = true
                        mergeNode.position_value = (toBigInt(node.position_value) + toBigInt(bNode.position_value)).toString()
                        mergeNode.max_leverage = Math.min(node.max_leverage, bNode.max_leverage)
                        mergeNode.node = [node.node, bNode.node]
                        mergeNode.role = node.role
                        mergeNode.debt_value = (toBigInt(node.debt_value) + toBigInt(bNode.debt_value)).toString()
                        mergeNode.operator = [node.operator, bNode.operator]
                        mergeNode.raw_byte_power = (toBigInt(node.raw_byte_power) + toBigInt(bNode.raw_byte_power)).toString()
                        mergeNode.quality_adj_power = (toBigInt(node.quality_adj_power) + toBigInt(bNode.quality_adj_power)).toString()
                        mergeNode.interest = node.interest + bNode.interest
                        mergeNode.interestBigInt = (toBigInt(node.interestBigInt) + toBigInt(bNode.interestBigInt)).toString()
                        mergeNode.locked_funds = (toBigInt(node.locked_funds) + toBigInt(bNode.locked_funds)).toString()
                        mergeNode.initial_pledge = (toBigInt(node.initial_pledge) + toBigInt(bNode.initial_pledge)).toString()
                        mergeNode.pre_commit_deposits = (toBigInt(node.pre_commit_deposits) + toBigInt(bNode.pre_commit_deposits)).toString()
                        mergeNode.currentNode = node;
                        mergeNode.bindNode = bNode;
                        newNodes.push(mergeNode)

                        bindNodeMap[node.node] = 1
                        bindNodeMap[bNode.node] = 1

                        continue

                    }
                    newNodes.push(node)
                }
                nodes = newNodes
                nodes.sort(function (a, b) {
                    // eslint-disable-next-line no-extra-boolean-cast
                    if (!!user) {
                        let userEqualsA = false
                        let userEqualsB = false
                        if (a.isMergeNode) {
                            userEqualsA = user === a.currentNode.operator || user === a.bindNode.operator
                        } else {
                            userEqualsA = user === a.operator
                        }
                        if (b.isMergeNode) {
                            userEqualsB = user === b.currentNode.operator || user === b.bindNode.operator
                        } else {
                            userEqualsB = user === b.operator
                        }

                        if (userEqualsA && !userEqualsB) {
                            return -1
                        } else if (!userEqualsA && userEqualsB) {
                            return 1
                        } else {
                            let bCollect = b.collect
                            if (b.isMergeNode) {
                                bCollect = b.currentNode.collect + b.bindNode.collect
                            }
                            let aCollect = a.collect
                            if (a.isMergeNode) {
                                aCollect = a.currentNode.collect + a.bindNode.collect
                            }
                            return bCollect - aCollect
                        }
                    }
                    return b.collect - a.collect
                })
                for (let i = 0; i < nodes.length; i++) {
                    let node = nodes[i]

                    nodes[i]['availableBalance'] = toBigInt(node.position_value) - toBigInt(node.locked_funds) - toBigInt(node.initial_pledge) - toBigInt(node.pre_commit_deposits)
                    if (node.isMergeNode) {
                        nodes[i]['currentNode']['availableBalance'] = toBigInt(node['currentNode'].position_value) - toBigInt(node['currentNode'].locked_funds) - toBigInt(node['currentNode'].initial_pledge) - toBigInt(node['currentNode'].pre_commit_deposits)
                        nodes[i]['bindNode']['availableBalance'] = toBigInt(node['bindNode'].position_value) - toBigInt(node['bindNode'].locked_funds) - toBigInt(node['bindNode'].initial_pledge) - toBigInt(node['bindNode'].pre_commit_deposits)
                    }

                    let debtValue = toBigInt(node.debt_value);
                    if (debtValue === 0n) {
                        nodes[i]['debtRatio'] = 0n
                        nodes[i]['debtRatioByLeverage'] = 0n
                        nodes[i]['currentLeverage'] = 0n
                    } else {
                        let positionValue = toBigInt(node.position_value);
                        nodes[i]['debtRatio'] = PercentageMath.div(debtValue, positionValue)
                        let equityValue = positionValue - debtValue
                        nodes[i]['currentLeverage'] = PercentageMath.div(equityValue + toBigInt(debtValue), equityValue)
                        nodes[i]['debtRatioByLeverage'] = PercentageMath.div(nodes[i]['currentLeverage'] - 10000n, toBigInt(node.max_leverage) - 10000n)
                    }
                }
                commit('SET_NODES_DATA', nodes)
                commit('SET_ALL_NODES', allNodes)
            } catch (e) {
                console.log('err node', e)
                NotificationErr("There's something wrong with the Internet!")
            }
        },
        // eslint-disable-next-line no-empty-pattern
        async setCollect({}, {node, collect, user}) {
            try {
                await nodejsHttp.put(`node/collect?user=${user}&node=${node}&collect=${collect}`)
            } catch (e) {
                console.log('err setCollect')
                NotificationErr("There's something wrong with the Internet!")
            }
        },
        // eslint-disable-next-line no-empty-pattern
        async setPoolCollect({}, {poolAddress, collect, user}) {
            try {
                await nodejsHttp.put(`pool/collect?user=${user}&poolAddress=${poolAddress}&collect=${collect}`)
            } catch (e) {
                console.log('err setCollect')
                NotificationErr("There's something wrong with the Internet!")
            }
        },
        async getNodeHistory({commit}, {actorId, event}) {
            try {
                commit('SET_NODE_HISTORY_DATA', [])
                let history = await nodejsHttp.get('history?actorId=' + actorId + '&event=' + event)
                if (!history) {
                    history = []
                }
                commit('SET_NODE_HISTORY_DATA', history)
            } catch (e) {
                console.log('err history')
                NotificationErr("There's something wrong with the Internet!")
            }
        },
        async initCurrentNodeData({commit, rootState}, {nodeId}) {
            commit('SET_CURRENT_NODE_DATA_LOADING', {loading: true})
            let actorId = getPrefix() + nodeId
            let minerPower = await retryFun(() => walletProvider.minerPower(actorId), 3)
            let minerInfo = await retryFun(() => walletProvider.minerInfo(actorId), 3)
            let minerSectorCount = await retryFun(() => walletProvider.minerSectorCount(actorId), 3)
            let stateReadState = await retryFun(() => walletProvider.readState(actorId), 3)
            let nodeData = await readContract({
                address: contracts[rootState.evn].StakingPool.eth,
                abi: StakingPoolABI.abi,
                functionName: 'getNodeData',
                args: [nodeId],
            })
            let guaranteeNodeId = nodeData[4].toBigInt() & toBigInt("0xFFFFFFFFFFF")
            let configuration = nodeData[0]['configuration']['data']
            let nodeConfiguration = new NodeConfiguration(configuration)
            let isActive = nodeConfiguration.isActive()
            let LiquidationThreshold = nodeConfiguration.getLiquidationThreshold()
            let MaxLeverage = nodeConfiguration.getMaxLeverage()
            let Borrowing = nodeConfiguration.getBorrowing()
            console.log('nodeData', nodeData, MaxLeverage, LiquidationThreshold)

            let actorAddress = getActorAddress(nodeId)

            let variableDebtBalance = await readContract({
                address: contracts[rootState.evn].VariableDebtToken.eth,
                abi: VariableDebtToken.abi,
                functionName: 'balanceOf',
                args: [actorAddress],
            })
            let stableDebtBalance = await readContract({
                address: contracts[rootState.evn].StableDebtToken.eth,
                abi: StableDebtToken.abi,
                functionName: 'balanceOf',
                args: [actorAddress],
            })

            let userStableRate = await readContract({
                address: contracts[rootState.evn].StableDebtToken.eth,
                abi: StableDebtTokenABI.abi,
                functionName: 'getUserStableRate',
                args: [actorAddress],
            })

            userStableRate = userStableRate.toBigInt()
            // 第一次触发
            console.log('userStableRate', userStableRate, toBigInt(userStableRate / E23))
            commit('SET_AFTER_STABLE_RATE', {afterStableRate: toBigInt(userStableRate / E23)})

            let DebtValue = BigNumber.from(variableDebtBalance).add(BigNumber.from(stableDebtBalance)).toString()
            let {Balance} = stateReadState
            let PositionValue = Balance
            let {Active, Faulty, Live} = minerSectorCount
            let {Beneficiary, Owner, SectorSize} = minerInfo
            let {MinerPower} = minerPower
            let {RawBytePower, QualityAdjPower} = MinerPower

            variableDebtBalance = variableDebtBalance.toBigInt()
            stableDebtBalance = stableDebtBalance.toBigInt()

            let guarantee = {
                currentNode: {
                    position_value: 0n,
                    variable_debt_value: 0n,
                    stable_debt_value: 0n,
                    debt_value: 0n,
                    max_leverage: 0
                },
                guaranteeNode: {
                    position_value: 0n,
                    variable_debt_value: 0n,
                    stable_debt_value: 0n,
                    debt_value: 0n,
                    max_leverage: 0
                }
            }
            guarantee.currentNode.position_value = toBigInt(PositionValue)
            guarantee.currentNode.debt_value = toBigInt(DebtValue)
            guarantee.currentNode.variable_debt_value = variableDebtBalance
            guarantee.currentNode.stable_debt_value = stableDebtBalance
            guarantee.currentNode.max_leverage = parseInt(MaxLeverage.toString())

            if (toBigInt(guaranteeNodeId) !== 0n) {
                let _guaranteeStateReadState = await retryFun(() => walletProvider.readState(getPrefix() + guaranteeNodeId.toString()), 3)
                let _guaranteePositionValue = toBigInt(_guaranteeStateReadState.Balance)
                PositionValue = toBigInt(PositionValue) + _guaranteePositionValue
                let _guaranteeNodeData = await readContract({
                    address: contracts[rootState.evn].StakingPool.eth,
                    abi: StakingPoolABI.abi,
                    functionName: 'getNodeData',
                    args: [guaranteeNodeId],
                })

                let guaranteeDebtValue = BigNumber.from(_guaranteeNodeData[2]).add(BigNumber.from(_guaranteeNodeData[3])).toString()

                guarantee.guaranteeNode.position_value = _guaranteePositionValue
                guarantee.guaranteeNode.debt_value = toBigInt(guaranteeDebtValue)
                guarantee.guaranteeNode.variable_debt_value = _guaranteeNodeData[3].toBigInt()
                guarantee.guaranteeNode.stable_debt_value = _guaranteeNodeData[2].toBigInt()

                DebtValue = toBigInt(DebtValue) + toBigInt(guaranteeDebtValue)

                let configuration = _guaranteeNodeData[0]['configuration']['data']
                let nodeConfiguration = new NodeConfiguration(configuration)
                let _MaxLeverage = nodeConfiguration.getMaxLeverage();
                MaxLeverage = MaxLeverage < _MaxLeverage ? MaxLeverage : _MaxLeverage
                guarantee.guaranteeNode.max_leverage = parseInt(_MaxLeverage.toString())
            }

            let node = {
                position_value: PositionValue,
                debt_value: DebtValue,
                variable_debt_value: variableDebtBalance,
                stable_debt_value: stableDebtBalance,
                max_leverage: parseInt(MaxLeverage.toString()),
                liquidation_threshold: LiquidationThreshold,
                sector_size: SectorSize,
                owner: Owner,
                beneficiary: Beneficiary,
                raw_byte_power: RawBytePower,
                quality_adj_power: QualityAdjPower,
                live: toBigInt(Live),
                active: toBigInt(Active),
                faulty: toBigInt(Faulty),
                is_active: isActive,
                borrowing: Borrowing,
                actor_id: actorId,
                node: nodeId,
                userStableRate: userStableRate,
                guaranteeNodeId: parseInt(guaranteeNodeId.toString()),
            }

            let {operator, safetyBuffer, owner, role} = nodeData[0]
            if (role === 2) {
                owner = Owner
            }

            let {LockedFunds, InitialPledge, PreCommitDeposits} = stateReadState.State
            let lockedRewards = LockedFunds
            let pledge = InitialPledge
            let availableBalance = BigNumber.from(Balance).sub(BigNumber.from(LockedFunds)).sub(BigNumber.from(InitialPledge)).sub(BigNumber.from(PreCommitDeposits)).toString()

            commit('SET_CURRENT_NODE', {
                ...node,
                operator,
                safetyBuffer,
                lockedRewards,
                pledge,
                availableBalance,
                oldOwner: owner,
                role,
                guarantee,
                sector_active: node.active,
                sector_faulty: node.faulty,
                sector_live: node.live,
            })
            commit('SET_CURRENT_NODE_DATA_LOADING', {loading: false})
        }
    },
    modules: {}
}

export default nodeData
