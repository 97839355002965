import RightStake from '@/components/stake/RightStake';
import PageHead from '@/components/header/PageHead.vue';
import { mapState } from "vuex";
import { externalFormatFilPrice, formatPrice, formatWalletFilPrice, toBigInt } from "@/utils/NumU";
import { WadRayMath } from "@/utils/contracts/WadRayMath";
import { formatUnits, parseUnits } from "ethers/lib/utils";
import ScrollNumber from '@/components/ScrollNumber.vue';
import AfterValue from '@/components/farm/overview/indicator/AfterValue';
export default {
  name: 'HomeView',
  components: {
    RightStake,
    PageHead,
    ScrollNumber,
    AfterValue
  },
  data() {
    return {};
  },
  created() {
    this.init();
  },
  mounted() {},
  methods: {
    gotNetAPYTip() {
      window.open(`${this.docsUrlPrefix}tokenomics/stfil`);
    },
    gotoAudited() {
      window.open('https://skynet.certik.com/zh-CN/projects/stfil');
    },
    init() {
      this.$store.commit('INIT_SHOW_TOP_VISIBLE');
      this.initStakeInfo();
    },
    initStakeInfo() {
      this.$store.dispatch('initStakeInfo');
    },
    formatUnits,
    formatPrice,
    externalFormatFilPrice,
    formatWalletFilPrice,
    showComputer() {
      this.$store.state.refs['iHeader'].showApyComputerShowDialog();
    },
    mouseleaveTopShow() {
      this.showTopClose = false;
    },
    closeTopShow() {
      this.$store.commit('SET_SHOW_TOP_VISIBLE', {
        showTopVisible: false
      });
    }
  },
  computed: mapState({
    stakeNumber: state => state.stakeInfo.stakeNumber,
    stakeNumberBig() {
      return parseUnits(this.stakeNumber.toString(), 18).toBigInt();
    },
    timingFlag: state => state.timingFlag,
    showTopVisible: state => state.showTopVisible,
    variableDebtTokenTotalSupplyLoading: state => state.publicInfo.variableDebtTokenTotalSupplyLoading,
    stableDebtTokenTotalSupplyLoading: state => state.publicInfo.stableDebtTokenTotalSupplyLoading,
    poolStFilBalanceLoading: state => state.publicInfo.poolStFilBalanceLoading,
    totalStakingTokensLoading: state => state.stakeInfo.loading,
    totalSupply() {
      return this.borrowBalance + this.poolStFilBalanceBig;
    },
    afterTotalSupply() {
      return this.borrowBalance + this.poolStFilBalanceBig + this.stakeNumberBig;
    },
    tvlLoading: state => state.publicInfo.tvlLoading,
    nodeCount: state => state.publicInfo.nodeCount,
    stakeCount: state => state.publicInfo.stakeCount,
    supplyBalance: state => state.stakeInfo.totalStakingTokens,
    afterSupplyBalance() {
      return this.supplyBalance + this.stakeNumberBig;
    },
    supplyBalanceLoading: state => state.stakeInfo.loading,
    poolStFilBalanceBig: state => state.publicInfo.poolStFilBalance,
    afterPoolStFilBalanceBig() {
      return this.poolStFilBalanceBig + this.stakeNumberBig;
    },
    totalStakingTokens: state => state.stakeInfo.totalStakingTokens,
    filPrice() {
      return this.$store.state.filPrice;
    },
    borrowBalance: state => {
      return state.publicInfo.variableDebtTokenTotalSupply + state.publicInfo.stableDebtTokenTotalSupply;
    },
    utilizationRateFloat() {
      let res = parseInt(this.utilizationRate) / 100.0;
      if (res > 100) {
        return '100';
      }
      return res.toFixed(2);
    },
    utilizationRate() {
      return toBigInt(parseInt(formatUnits(WadRayMath.rayDiv(this.borrowBalance, this.totalSupply).toString(), 23)));
    },
    afterUtilizationRate() {
      return toBigInt(parseInt(formatUnits(WadRayMath.rayDiv(this.borrowBalance, this.afterTotalSupply).toString(), 23)));
    },
    liquidityRate: state => state.publicInfo.liquidityRate,
    liquidityRateApr() {
      let _liquidityRate = parseFloat(formatUnits(this.liquidityRate, 27));
      let rate = Math.pow(_liquidityRate / 365 + 1, 365) - 1;
      return formatPrice(rate * 100, 2);
    },
    docsUrlPrefix() {
      return `https://docs.stfil.io/${this.$i18n.locale === "zh" ? 'v/cn/' : ''}`;
    }
  })
};