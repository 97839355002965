import { ElSlider } from 'element-plus';
import RadioBtn from '@/components/RadioBtn';
import { ethTo, externalFormatFilPrice, floatToFixed, formatPrice, formatSubEthPrice, formatWalletFilPrice, toBigInt, toEth } from "@/utils/NumU";
import { mapState } from "vuex";
import PercentageMath from "@/utils/contracts/PercentageMath";
import StorageProviderLendingPoolABI from '@/utils/abi/StorageProviderLendingPool.json';
import { gotoHash, NotificationErr, NotificationSuccess } from "@/utils/common";
import { iWriteContract } from "@/utils/contracts/opertion";
import { BigNumber, ethers } from "ethers";
import IndicatorValue from '@/components/farm/overview/indicator/IndicatorValue';
import IndicatorRay from '@/components/farm/overview/indicator/IndicatorRay';
import { E23, WadRayMath } from "@/utils/contracts/WadRayMath";
import { formatUnits } from "ethers/lib.esm/utils";
export default {
  name: "SubPoolAdjustP",
  components: {
    ElSlider,
    RadioBtn,
    IndicatorValue,
    IndicatorRay
  },
  data() {
    return {
      radio1: 'By Leverage',
      radio2: 'Borrowing',
      radio3: 'Variable',
      radio4: 'repay',
      sliderValue: 0,
      oldSliderValue: 0,
      firstSliderValue: 0,
      loading: false,
      adjustBorrowingValue: '',
      adjustRepayingValue: '',
      tabOptions1: [],
      tabOptions2: [],
      tabOptions3: [],
      tabOptions4: [],
      btnText: '',
      hash: '',
      mainSliderWidth: 0
    };
  },
  props: {
    adjustType: {
      type: String,
      default: 'borrow'
    }
  },
  created() {
    this.init();
  },
  watch: {
    'currentPoolNode.debt_value'() {
      this.initSliderValue();
    },
    poolInfoLoading() {
      this.initSliderValue();
    },
    '$i18n.locale'() {
      this.initTabOption();
    },
    adjustBorrowingValue() {},
    radio4() {
      this.sliderValue = this.firstSliderValue;
      this.adjustBorrowingValue = '';
      this.adjustRepayingValue = '';
    },
    radio3() {
      this.$store.commit('SET_BORROW_RATE_TYPE', {
        borrowRateType: this.radio3
      });
      this.sliderValue = this.firstSliderValue;
      this.adjustBorrowingValue = '';
      this.adjustRepayingValue = '';
    },
    radio2() {
      this.sliderValue = this.firstSliderValue;
      this.adjustBorrowingValue = '';
      this.adjustRepayingValue = '';
    },
    radio1() {
      this.sliderValue = this.firstSliderValue;
      this.adjustBorrowingValue = '';
      this.adjustRepayingValue = '';
    },
    currentLoading() {
      if (!this.currentLoading) {
        this.setMainSliderWidth();
      }
    }
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.setMainSliderWidth();
    });
  },
  methods: {
    goToRepay() {
      this.$emit('gotoRepay');
    },
    setMainSliderWidth() {
      this.$nextTick(() => {
        if (this.$refs['main-slider']) {
          this.mainSliderWidth = this.$refs['main-slider'].offsetWidth - 16;
        }
      });
    },
    initTabOption() {
      this.radio2 = this.adjustType === 'borrow' ? 'Borrowing' : 'Repayment';
      this.tabOptions1 = [{
        label: this.$t('farm.console.ByLeverage'),
        value: 'By Leverage'
      }, {
        label: this.$t('farm.console.ByAmount'),
        value: 'By Amount'
      }];
      this.tabOptions2 = [{
        label: this.$t('farm.console.Borrowing'),
        value: 'Borrowing'
      }, {
        label: this.$t('farm.console.Repayment'),
        value: 'Repayment'
      }];
      this.tabOptions3 = [{
        label: this.$t('farm.console.Variable'),
        value: 'Variable'
      }, {
        label: this.$t('farm.console.Stable'),
        value: 'Stable'
      }];
      this.tabOptions4 = [{
        label: this.$t('farm.console.repay1'),
        value: 'repay'
      }, {
        label: this.$t('farm.console.repay2'),
        value: 'repayWithCash'
      }];
    },
    formatUnits,
    formatPrice,
    init() {
      this.setMainSliderWidth();
      this.initTabOption();
      this.$store.commit('SET_BORROW_RATE_TYPE', {
        borrowRateType: 'Variable'
      });
      this.sliderValue = 0;
      if (this.debtValue > toBigInt(0)) {
        this.initSliderValue();
      }
    },
    initSliderValue() {
      let leverage = this.currentLeverage;
      this.sliderValue = parseFloat((parseInt((leverage - toBigInt(10000)).toString()) / parseInt((this.maxLeverage - toBigInt(10000)).toString()) * 100 + 0.0001).toFixed(4));
      if (this.sliderValue > 100) {
        this.sliderValue = 100;
      }
      this.firstSliderValue = this.sliderValue;
    },
    maxRepay() {
      if (this.loading) {
        return;
      }
      if (this.radio3 === 'Variable') {
        let variable_debt_value = ethTo(this.variableDebtValue);
        if (this.radio4 === 'repay') {
          if (this.availableBalance < this.variableDebtValue) {
            let _availableBalance = ethTo(this.availableBalance);
            this.adjustRepayingValue = floatToFixed(_availableBalance, 4);
          } else {
            this.adjustRepayingValue = floatToFixed(variable_debt_value, 4);
          }
        } else if (this.radio4 === 'repayWithCash') {
          if (this.balance < this.variableDebtValue) {
            let _balance = ethTo(this.balance - 500000000000000000n);
            this.adjustRepayingValue = floatToFixed(_balance, 4);
          } else {
            this.adjustRepayingValue = floatToFixed(variable_debt_value, 4);
          }
        }
      } else if (this.radio3 === 'Stable') {
        let stable_debt_value = ethTo(this.stableDebtValue);
        if (this.radio4 === 'repay') {
          if (this.availableBalance < this.stableDebtValue) {
            let _availableBalance = ethTo(this.availableBalance);
            this.adjustRepayingValue = floatToFixed(_availableBalance, 4);
          } else {
            this.adjustRepayingValue = floatToFixed(stable_debt_value, 4);
          }
        } else if (this.radio4 === 'repayWithCash') {
          if (this.balance < this.stableDebtValue) {
            let _balance = ethTo(this.balance - 500000000000000000n);
            this.adjustRepayingValue = floatToFixed(_balance, 4);
          } else {
            this.adjustRepayingValue = floatToFixed(stable_debt_value, 4);
          }
        }
      }
      this.calcSliderValue(ethers.utils.parseEther((this.adjustRepayingValue * -1).toString()).toBigInt());
    },
    borrowMax() {
      if (this.loading) {
        return;
      }
      // 最大可借金额
      let leftDebtValue = this.availableBorrowingAmount > this.maxBorrowableLiquidityAmount ? this.maxBorrowableLiquidityAmount : this.availableBorrowingAmount;
      if (leftDebtValue < 0n) {
        return;
      }
      if (this.radio3 !== 'Variable') {
        leftDebtValue = leftDebtValue > this.stableBorrowingAmount ? this.stableBorrowingAmount : leftDebtValue;
      }
      if (leftDebtValue > this.poolStFilBalance) {
        this.adjustBorrowingValue = floatToFixed(ethTo(this.poolStFilBalance.toString()), 4);
      } else {
        this.adjustBorrowingValue = floatToFixed(ethTo(leftDebtValue.toString()), 4);
      }
      this.calcSliderValue(ethers.utils.parseEther(this.adjustBorrowingValue.toString()).toBigInt());
    },
    adjustRepayingValueChange() {
      if (this.adjustRepayingValue < 0) {
        this.adjustRepayingValue = 0;
      }
      let _availableBalance = ethTo(this.availableBalance);
      let _balance = ethTo(this.balance - 500000000000000000n);
      if (this.radio3 === 'Variable') {
        let variable_debt_value = ethTo(this.variableDebtValue);
        if (this.adjustRepayingValue > variable_debt_value) {
          this.adjustRepayingValue = floatToFixed(variable_debt_value, 4);
        }
        if (this.radio4 === 'repay') {
          if (this.adjustRepayingValue > _availableBalance) {
            this.adjustRepayingValue = floatToFixed(_availableBalance, 4);
          } else {
            this.adjustRepayingValue = floatToFixed(this.adjustRepayingValue, 4);
          }
        } else if (this.radio4 === 'repayWithCash') {
          if (this.adjustRepayingValue > _balance) {
            this.adjustRepayingValue = floatToFixed(_balance, 4);
          } else {
            this.adjustRepayingValue = floatToFixed(this.adjustRepayingValue, 4);
          }
        }
      } else if (this.radio3 === 'Stable') {
        let stable_debt_value = ethTo(this.stableDebtValue);
        if (this.adjustRepayingValue > stable_debt_value) {
          this.adjustRepayingValue = floatToFixed(_availableBalance, 4);
        }
        if (this.radio4 === 'repay') {
          if (this.adjustRepayingValue > _availableBalance) {
            this.adjustRepayingValue = floatToFixed(_availableBalance, 4);
          } else {
            this.adjustRepayingValue = floatToFixed(this.adjustRepayingValue, 4);
          }
        } else if (this.radio4 === 'repayWithCash') {
          if (this.adjustRepayingValue > _balance) {
            this.adjustRepayingValue = floatToFixed(_balance, 4);
          } else {
            this.adjustRepayingValue = floatToFixed(this.adjustRepayingValue, 4);
          }
        }
      }
      this.calcSliderValue(ethers.utils.parseEther((this.adjustRepayingValue * -1).toString()).toBigInt());
    },
    adjustBorrowingValueChange() {
      if (this.adjustBorrowingValue === '') {
        this.sliderValue = this.firstSliderValue;
        return;
      }
      if (this.adjustBorrowingValue < 0) {
        this.adjustBorrowingValue = 0;
      }

      // 最大可借金额
      let leftDebtValue = this.availableBorrowingAmount > this.maxBorrowableLiquidityAmount ? this.maxBorrowableLiquidityAmount : this.availableBorrowingAmount;
      if (this.radio3 !== 'Variable') {
        leftDebtValue = leftDebtValue > this.stableBorrowingAmount ? this.stableBorrowingAmount : leftDebtValue;
      }
      let bigIntAdjustBorrowingValue = toEth(this.adjustBorrowingValue.toString());

      // 超过最大可借
      if (bigIntAdjustBorrowingValue > leftDebtValue) {
        if (leftDebtValue > this.poolStFilBalance) {
          this.adjustBorrowingValue = floatToFixed(ethTo(this.poolStFilBalance.toString()), 4);
        } else {
          this.adjustBorrowingValue = floatToFixed(ethTo(leftDebtValue), 4);
        }
      } else {
        this.adjustBorrowingValue = floatToFixed(this.adjustBorrowingValue, 4);
      }
      this.calcSliderValue(ethers.utils.parseEther(this.adjustBorrowingValue.toString()).toBigInt());
    },
    calcSliderValue(val) {
      let debt_value = this.debtValue;
      let new_debt_value = debt_value + val;
      let leverage = toBigInt(0);
      leverage = this.calcCurrentLeverage(new_debt_value);
      this.sliderValue = parseInt(PercentageMath.div(leverage - toBigInt(10000), this.maxLeverage - toBigInt(10000)).toString()) / 100;
    },
    calcCurrentLeverage(new_debt_value) {
      if (new_debt_value < this.debtValue && new_debt_value >= this.otherNodeDebt + this.nodeOriginalDebt) {
        let currentWithdrawLoadAmount = this.debtValue - new_debt_value;
        let currentWithdrawLoadLeverage = PercentageMath.mul(PercentageMath.div(currentWithdrawLoadAmount, this.equityValue), this.leverageRate);
        return this.currentLeverage - currentWithdrawLoadLeverage;
      } else if (new_debt_value < this.otherNodeDebt + this.nodeOriginalDebt) {
        return PercentageMath.div(new_debt_value - this.otherNodeDebt, this.equityValue) + this.otherNodeLeverage;
      } else {
        return this.currentLeverage + PercentageMath.div(new_debt_value - this.debtValue, this.equityValue);
      }
    },
    calcOnlyMySelfCurrentLeverage(my_debt_value) {
      if (my_debt_value < this.debtValue && my_debt_value >= this.otherNodeDebt + this.nodeOriginalDebt) {
        let currentWithdrawLoadAmount = this.debtValue - my_debt_value;
        let currentWithdrawLoadLeverage = PercentageMath.mul(PercentageMath.div(currentWithdrawLoadAmount, this.equityValue), this.leverageRate);
        return this.currentLeverage - currentWithdrawLoadLeverage;
      } else if (my_debt_value < this.otherNodeDebt + this.nodeOriginalDebt) {
        return PercentageMath.div(my_debt_value - this.otherNodeDebt, this.equityValue) + this.otherNodeLeverage;
      } else {
        return this.currentLeverage + PercentageMath.div(my_debt_value - this.debtValue, this.equityValue);
      }
    },
    changeSliderValue(val) {
      if (this.youAreBorrowing === 0n) {
        this.sliderValue = this.firstSliderValue;
        this.oldSliderValue = val;
        return;
      }
      if (this.adjustType === 'borrow' && !this.isBorrowed) {
        this.sliderValue = this.firstSliderValue;
        this.oldSliderValue = val;
        return;
      }
      if (this.adjustType === 'repay' && this.isBorrowed) {
        this.sliderValue = this.firstSliderValue;
        this.oldSliderValue = val;
        return;
      }
      if (!this.isBorrowed && this.radio3 === 'Variable') {
        let variable_debt_value = this.variableDebtValue;
        let absYouAreBorrowing = BigNumber.from(this.youAreBorrowing.toString()).abs().toBigInt();
        if (absYouAreBorrowing >= variable_debt_value) {
          if (this.radio4 === 'repayWithCash' && variable_debt_value >= this.balance) {
            this.sliderValue = this.valueToSliderValue(this.debtValue - this.balance + 500000000000000000n);
            return;
          } else if (this.radio4 === 'repay' && variable_debt_value >= this.availableBalance) {
            this.sliderValue = this.valueToSliderValue(this.debtValue - this.availableBalance);
            return;
          } else {
            // 这里 * 1.0001 为了让还款时杠杆能到底
            this.sliderValue = this.valueToSliderValue(this.debtValue - PercentageMath.mul(variable_debt_value, 10004n));
            return;
          }
        } else {
          if (this.radio4 === 'repayWithCash' && absYouAreBorrowing >= this.balance) {
            this.sliderValue = this.valueToSliderValue(this.debtValue - this.balance + 500000000000000000n);
            return;
          } else if (this.radio4 === 'repay' && absYouAreBorrowing >= this.availableBalance) {
            this.sliderValue = this.valueToSliderValue(this.debtValue - this.availableBalance);
            return;
          }
        }
      } else if (!this.isBorrowed && this.radio3 === 'Stable') {
        let stable_debt_value = this.stableDebtValue;
        let absYouAreBorrowing = BigNumber.from(this.youAreBorrowing.toString()).abs().toBigInt();
        if (absYouAreBorrowing >= stable_debt_value) {
          if (this.radio4 === 'repayWithCash' && stable_debt_value >= this.balance) {
            this.sliderValue = this.valueToSliderValue(this.debtValue - this.balance + 500000000000000000n);
            return;
          } else if (this.radio4 === 'repay' && stable_debt_value >= this.availableBalance) {
            this.sliderValue = this.valueToSliderValue(this.debtValue - this.availableBalance);
            return;
          } else {
            // 这里 * 1.0001 为了让还款时杠杆能到底
            this.sliderValue = this.valueToSliderValue(this.debtValue - PercentageMath.mul(stable_debt_value, 10004n));
            return;
          }
        } else {
          if (this.radio4 === 'repayWithCash' && absYouAreBorrowing >= this.balance) {
            this.sliderValue = this.valueToSliderValue(this.debtValue - this.balance + 500000000000000000n);
            return;
          } else if (this.radio4 === 'repay' && absYouAreBorrowing >= this.availableBalance) {
            this.sliderValue = this.valueToSliderValue(this.debtValue - this.availableBalance);
            return;
          }
        }
      }
      if (this.isBorrowed) {
        if (this.radio3 === 'Stable') {
          let leftDebtValue = this.maxBorrowableLiquidityAmount > this.stableBorrowingAmount ? this.stableBorrowingAmount : this.maxBorrowableLiquidityAmount;
          if (this.youAreBorrowing >= leftDebtValue) {
            this.sliderValue = this.firstSliderValue + this.valueToSliderValue(leftDebtValue);
            return;
          }
        }
        if (this.youAreBorrowing >= this.maxBorrowableLiquidityAmount) {
          this.sliderValue = this.firstSliderValue + this.valueToSliderValue(this.maxBorrowableLiquidityAmount);
          return;
        }
        if (this.youAreBorrowing >= this.poolStFilBalance) {
          this.sliderValue = this.firstSliderValue + this.valueToSliderValue(this.poolStFilBalance);
          return;
        }
      }
      this.oldSliderValue = val;
    },
    valueToSliderValue(val) {
      let leverage = this.calcOnlyMySelfCurrentLeverage(val);
      let diff = leverage - 10000n;
      let maxDiff = this.maxLeverage - 10000n;
      let res = (parseInt(diff.toString()) / parseInt(maxDiff.toString()) * 100).toFixed(4);
      return parseFloat(res);
    },
    sliderFormatTip(val) {
      let res = val / 100 * (this.maxLeverageInt - 10000) + 10000;
      return (res / 10000).toFixed(4) + 'x';
    },
    gotoHash,
    connectWallet() {
      if (this.approveOperator()) {
        return;
      }
      this.$store.state.refs['iHeader'].connectWallet();
    },
    approveOperator() {
      if (this.operator === '0x0000000000000000000000000000000000000000') {
        this.$emit('approveOperator');
        return true;
      }
      if (this.isConnected && this.operator !== this.address) {
        NotificationErr(this.$t('subpool.You are not the creator of the loan pool'));
        return true;
      }
      return false;
    },
    repay() {
      if (this.approveOperator()) {
        return;
      }
      if (this.youAreBorrowing >= 0) {
        NotificationErr('Please enter the amount repay');
        return;
      }
      let isAllVariableDebtValue = false;
      let isAllStableDebtValue = false;
      this.loading = true;
      let repayData = BigNumber.from(this.youAreBorrowing).abs();
      if (this.sliderValue === 0) {
        repayData = repayData.add(ethers.utils.parseEther('1'));
        console.log('repay all');
        if (this.radio3 === 'Variable') {
          isAllVariableDebtValue = true;
        } else {
          isAllStableDebtValue = true;
        }
      } else {
        if (this.radio3 === 'Variable') {
          console.log('repay all');
          console.log(this.youAreBorrowing, this.variableDebtValue, this.youAreBorrowing + this.variableDebtValue);
          if (this.youAreBorrowing + this.variableDebtValue <= 1000000000000000n) {
            repayData = repayData.add(ethers.utils.parseEther('1'));
            isAllVariableDebtValue = true;
          }
        } else {
          console.log('repay all');
          if (this.youAreBorrowing + this.stableDebtValue <= 1000000000000000n) {
            repayData = repayData.add(ethers.utils.parseEther('1'));
            isAllStableDebtValue = true;
          }
        }
      }
      this.btnText = this.$t('common.Wait for transaction confirmation');
      iWriteContract({
        address: this.currentPool.address.eth,
        f0address: this.currentPool.address.f0address,
        abi: StorageProviderLendingPoolABI.abi,
        functionName: "repay",
        args: [this.currentPoolNode.node, repayData, this.getRoteMode]
      }, res => {
        let {
          hash
        } = res;
        this.btnText = this.$t('common.Waiting for winding');
        this.hash = hash;
      }).then(() => {
        this.btnText = this.$t('common.Waiting for msg');
        setTimeout(() => {
          if (isAllVariableDebtValue) {
            NotificationSuccess(`You have successfully paid off your variable debt.`);
          } else if (isAllStableDebtValue) {
            NotificationSuccess(`You have successfully paid off your stable debt.`);
          } else {
            NotificationSuccess(`You have successfully repaid ${parseFloat(ethers.utils.formatEther(repayData)).toFixed(6)}FIL.`);
          }
          this.$store.dispatch('updateRemoteNodeData', {
            node: this.currentPoolNode.node
          });
          this.$emit('updateInfo');
          this.loading = false;
          this.adjustRepayingValue = '';
          this.adjustBorrowingValue = '';
          this.clearContent();
        }, 30000);
      }).catch(err => {
        NotificationErr(err.message);
        this.loading = false;
        this.clearContent();
      });
    },
    repayWithCash() {
      if (this.youAreBorrowing >= 0) {
        NotificationErr('Please enter the amount repay');
        return;
      }
      let isAllVariableDebtValue = false;
      let isAllStableDebtValue = false;
      this.loading = true;
      let repayData = BigNumber.from(this.youAreBorrowing).abs();
      if (this.sliderValue === 0) {
        repayData = repayData.add(ethers.utils.parseEther('1'));
        console.log('repay all');
        if (this.radio3 === 'Variable') {
          isAllVariableDebtValue = true;
        } else {
          isAllStableDebtValue = true;
        }
      } else {
        if (this.radio3 === 'Variable') {
          console.log('repay all');
          console.log(this.youAreBorrowing, this.variableDebtValue, this.youAreBorrowing + this.variableDebtValue);
          if (this.youAreBorrowing + this.variableDebtValue <= 1000000000000000n) {
            repayData = repayData.add(ethers.utils.parseEther('1'));
            isAllVariableDebtValue = true;
          }
        } else {
          console.log('repay all');
          if (this.youAreBorrowing + this.stableDebtValue <= 1000000000000000n) {
            repayData = repayData.add(ethers.utils.parseEther('1'));
            isAllStableDebtValue = true;
          }
        }
      }
      this.btnText = this.$t('common.Wait for transaction confirmation');
      iWriteContract({
        address: this.currentPool.address.eth,
        f0address: this.currentPool.address.f0address,
        abi: StorageProviderLendingPoolABI.abi,
        functionName: "repayWithCash",
        overrides: {
          value: repayData
        },
        args: [this.currentPoolNode.node, this.getRoteMode]
      }, res => {
        let {
          hash
        } = res;
        this.btnText = this.$t('common.Waiting for winding');
        this.hash = hash;
      }).then(() => {
        this.btnText = this.$t('common.Waiting for msg');
        setTimeout(() => {
          if (isAllVariableDebtValue) {
            NotificationSuccess(`You have successfully paid off your variable debt.`);
          } else if (isAllStableDebtValue) {
            NotificationSuccess(`You have successfully paid off your stable debt.`);
          } else {
            NotificationSuccess(`You have successfully repaid ${parseFloat(ethers.utils.formatEther(repayData)).toFixed(6)}FIL.`);
          }
          this.$store.dispatch('updateRemoteNodeData', {
            node: this.currentPoolNode.node
          });
          this.$emit('updateInfo');
          this.loading = false;
          this.adjustRepayingValue = '';
          this.adjustBorrowingValue = '';
          this.clearContent();
        }, 30000);
      }).catch(err => {
        NotificationErr(err.message);
        this.loading = false;
        this.clearContent();
      });
    },
    clearContent() {
      this.btnText = '';
      this.hash = '';
    },
    borrow() {
      if (this.paused) {
        return;
      }
      if (this.approveOperator()) {
        return;
      }
      if (this.youAreBorrowing <= 0) {
        NotificationErr('Please enter the amount borrowed');
        return;
      }
      this.loading = true;
      this.btnText = this.$t('common.Wait for transaction confirmation');
      let youAreBorrowing = this.youAreBorrowing;
      if (this.sliderValue >= 99) {
        youAreBorrowing = this.youAreBorrowing - 99999999999999n;
      }
      iWriteContract({
        address: this.currentPool.address.eth,
        f0address: this.currentPool.address.f0address,
        abi: StorageProviderLendingPoolABI.abi,
        functionName: "sealLoan",
        args: [this.currentPoolNode.node, BigNumber.from(youAreBorrowing), this.getRoteMode]
      }, res => {
        let {
          hash
        } = res;
        this.btnText = this.$t('common.Waiting for winding');
        this.hash = hash;
      }).then(() => {
        this.btnText = this.$t('common.Waiting for msg');
        setTimeout(() => {
          NotificationSuccess(`You have successfully borrowed ${parseFloat(ethers.utils.formatEther(this.youAreBorrowing)).toFixed(6)}FIL.`);
          this.loading = false;
          this.clearContent();
          this.adjustRepayingValue = '';
          this.adjustBorrowingValue = '';
          this.$store.dispatch('updateRemoteNodeData', {
            node: this.currentPoolNode.node
          });
          this.$emit('updateInfo');
        }, 30000);
      }).catch(err => {
        NotificationErr(err.message);
        this.loading = false;
        this.clearContent();
      });
    },
    externalFormatFilPrice,
    formatWalletFilPrice,
    formatSubEthPrice
  },
  computed: mapState({
    stableDebtTokenTotalSupply: state => state.publicInfo.stableDebtTokenTotalSupply,
    variableDebtTokenTotalSupply: state => state.publicInfo.variableDebtTokenTotalSupply,
    poolInfoLoading: state => state.subpool.loading.initCurrentPoolInfoLoading,
    poolNodeInfoLoading: state => state.subpool.loading.initCurrentPoolNodeDataLoading,
    publicInfoLoading: state => state.publicInfo.loading,
    stakeInfoLoading: state => state.stakeInfo.loading,
    currentLoading() {
      return this.poolInfoLoading || this.poolNodeInfoLoading || this.publicInfoLoading || this.stakeInfoLoading;
    },
    isExceedMaxDebtRate() {
      return this.debtRatio > this.poolMaxDebtRatio;
    },
    operator() {
      return this.currentPool.admin;
    },
    isBorrowed() {
      return this.sliderValue >= this.firstSliderValue;
    },
    sliderChangeClass() {
      if (this.adjustType === 'borrow') {
        return '';
      } else {
        return 'i-auto-slider';
      }
    },
    afterVariableRate: state => {
      return state.nodeData.afterVariableRate;
    },
    afterStableRate() {
      if (this.youAreBorrowing > 0n) {
        return PercentageMath.div(1000000000000000n + PercentageMath.mul(this.userStableSimpleRate, this.stableDebtValue) + PercentageMath.mul(this.youAreBorrowing, this.stableBorrowSimpleRate), this.stableDebtValue + this.youAreBorrowing);
      }
      if (this.youAreBorrowing + this.stableDebtValue <= 0) {
        return 0n;
      }
      return this.userStableSimpleRate;
    },
    afterAvailableBorrowingAmount() {
      let res = this.availableBorrowingAmount - this.youAreBorrowing;
      if (res < 10000000000000000n) {
        return 0n;
      }
      return res;
    },
    borrowMode() {
      return this.$store.state.nodeData.borrowMode;
    },
    afterVariableDebtValue() {
      if (this.borrowMode === 0) {
        return this.variableDebtValue;
      }
      if (this.borrowMode === 2) {
        if (this.variableDebtValue + this.youAreBorrowing <= 1000000000000000n) {
          return 0n;
        }
        return this.variableDebtValue + this.youAreBorrowing;
      } else {
        return this.variableDebtValue;
      }
    },
    afterStableDebtValue() {
      if (this.borrowMode === 1) {
        if (this.stableDebtValue + this.youAreBorrowing <= 1000000000000000n) {
          return 0n;
        }
        return this.stableDebtValue + this.youAreBorrowing;
      } else {
        return this.stableDebtValue;
      }
    },
    availableBorrowingAmount() {
      return this.currentPool.maxSealedLoadAmount;
    },
    leftAvailableBorrowingAmount() {
      let res = this.currentPool.maxSealedLoadAmount;
      if (res > this.maxBorrowableLiquidityAmount) {
        return this.maxBorrowableLiquidityAmount;
      }
      return res;
    },
    afterLeftAvailableBorrowingAmount() {
      let res = this.leftAvailableBorrowingAmount - this.youAreBorrowing;
      if (res < 10000000000000000n) {
        return 0n;
      }
      return res;
    },
    stableRateEnabled: state => state.publicInfo.stableRateEnabled,
    poolIsPaused: state => state.publicInfo.poolIsPaused,
    isActive: state => state.currentNode.is_active === '1',
    getRoteMode() {
      if (this.radio3 === 'Variable') {
        return 2;
      }
      return 1;
    },
    address() {
      return this.$store.state.address;
    },
    balance() {
      if (!this.isConnected) {
        return 0n;
      }
      return this.$store.state.balance;
    },
    expectedBalance() {
      if (!this.isConnected) {
        return 0n;
      }
      let res = this.balance + this.youAreBorrowing;
      if (res < 0n) {
        return 0n;
      }
      return res;
    },
    simpleAddress() {
      if (!this.address) {
        return '';
      }
      return this.address.toString().substring(0, 6) + '...' + this.address.toString().substring(this.address.length - 2);
    },
    isConnected() {
      return this.$store.state.isConnected;
    },
    isConnecting() {
      return this.$store.state.isConnecting;
    },
    currentNode() {
      return this.$store.state.nodeData.currentNode;
    },
    currentPoolNode() {
      return this.$store.state.subpool.currentPoolNode;
    },
    currentPool() {
      return this.$store.state.subpool.currentPool;
    },
    stableDebtValue() {
      return this.currentPoolNode.stable_debt_value;
    },
    variableDebtValue() {
      return this.currentPoolNode.variable_debt_value;
    },
    disableRate() {
      if (!this.stableRateEnabled) {
        return ['Stable'];
      }
      return [];
    },
    marksMax() {
      let leverage = this.currentLeverage;
      let sliderValue = parseInt(PercentageMath.div(leverage - toBigInt(10000), this.maxLeverage - toBigInt(10000)).toString()) / 100;
      if (sliderValue > 100) {
        return sliderValue;
      } else {
        return 100;
      }
    },
    marks() {
      let maxLeverage = this.maxLeverageInt;
      let res = {
        0: {
          style: {
            top: '0'
          },
          label: '1.00x'
        },
        100: {
          style: {
            top: '0'
          },
          label: (maxLeverage / 10000).toFixed(2) + 'x'
        }
      };
      if (this.currentLeverage > 1) {
        let leverage = this.currentLeverage;
        let sliderValue = parseInt(PercentageMath.div(leverage - toBigInt(10000), this.maxLeverage - toBigInt(10000)).toString()) / 100;
        if (sliderValue > 0) {
          res[0].style.top = '17px';
          res[100].style.top = '17px';
          res[sliderValue] = {
            style: {
              color: '#00CE92',
              bottom: '17px'
            },
            label: `${parseInt(leverage.toString()) / 10000}x`
          };
        }
      }
      if (this.debtValue > this.nodeDebtValue) {
        let sliderValue = parseInt(PercentageMath.div(this.otherNodeLeverage - toBigInt(10000), this.maxLeverage - toBigInt(10000)).toString()) / 100;
        if (sliderValue > 0 && !res[sliderValue]) {
          res[sliderValue] = {
            style: {
              color: '#8b8b8b',
              bottom: '-25px',
              fontSize: '12px'
            },
            label: `${parseInt(this.otherNodeLeverage.toString()) / 10000}x`
          };
          console.log('res', res);
        }
      }
      // 借款提现部分
      if (this.nodeWithdrawLoadAmount > 0n) {
        let sliderValue = 0;
        let leverage = 0n;
        leverage = this.otherNodeLeverage + this.nodeWithdrawLoadLeverage - 10000n;
        sliderValue = parseInt(PercentageMath.div(leverage - toBigInt(10000), this.maxLeverage - toBigInt(10000)).toString()) / 100;
        if (sliderValue > 0 && !res[sliderValue]) {
          res[sliderValue] = {
            style: {
              color: '#8b8b8b',
              bottom: '-25px',
              fontSize: '12px'
            },
            label: `${parseInt(leverage.toString()) / 10000}x`
          };
        }
      }
      return res;
    },
    isYouAreRePayingAll() {
      if (this.youAreBorrowing < 0n) {
        if (this.radio3 === 'Variable') {
          if (this.youAreBorrowing + this.variableDebtValue <= 1000000000000000n) {
            return true;
          }
        } else {
          if (this.youAreBorrowing + this.stableDebtValue <= 1000000000000000n) {
            return true;
          }
        }
      }
      return false;
    },
    youAreBorrowing() {
      let youAreBorrowing = 0n;
      if (this.radio2 === 'Borrowing' && this.radio1 === 'By Amount') {
        youAreBorrowing = toEth(this.adjustBorrowingValue);
      } else if (this.radio2 === 'Repayment' && this.radio1 === 'By Amount') {
        youAreBorrowing = toEth(this.adjustRepayingValue) * -1n;
        if (this.radio3 === 'Variable') {
          if (youAreBorrowing + this.variableDebtValue <= 0 || youAreBorrowing + this.variableDebtValue <= 99999999999999n) {
            youAreBorrowing = this.variableDebtValue * -1n;
          }
        } else {
          if (youAreBorrowing + this.stableDebtValue <= 0 || youAreBorrowing + this.variableDebtValue <= 99999999999999n) {
            youAreBorrowing = this.stableDebtValue * -1n;
          }
        }
      } else {
        let leverage = parseInt((this.sliderValue / 100 * (this.maxLeverageInt - 10000) + 10000).toString());
        if (this.currentLeverage === toBigInt(leverage)) {
          this.$store.commit('SET_YOU_ARE_BORROWING', {
            youAreBorrowing: 0n,
            borrowMode: this.getRoteMode
          });
          return toBigInt(0);
        }
        let leverageBigInt = toBigInt(leverage);
        if (this.adjustType === 'borrow') {
          youAreBorrowing = PercentageMath.mul(leverageBigInt - this.currentLeverage, this.equityValue);
        } else {
          if (leverageBigInt <= this.currentLeverage && leverageBigInt >= this.otherNodeLeverage + this.nodeOriginalDebtLeverage - 10000n) {
            // 4 * (wa / ev) = (leverage / 4) * ev
            youAreBorrowing = PercentageMath.mul(PercentageMath.div(leverageBigInt - this.currentLeverage, this.leverageRate), this.equityValue);
          } else if (leverageBigInt > this.otherNodeLeverage) {
            youAreBorrowing = PercentageMath.mul(leverageBigInt - (this.otherNodeLeverage + this.nodeOriginalDebtLeverage - 10000n), this.equityValue) - this.nodeWithdrawLoadAmount;
          } else {
            if (this.radio3 === 'Variable') {
              if (leverageBigInt <= this.otherNodeLeverage) {
                youAreBorrowing = this.variableDebtValue * -1n;
              }
            } else {
              if (leverageBigInt <= this.otherNodeLeverage) {
                youAreBorrowing = this.stableDebtValue * -1n;
              }
            }
          }
        }
        if (youAreBorrowing < 0n) {
          if (this.radio3 === 'Variable') {
            if (leverageBigInt <= this.otherNodeLeverage) {
              youAreBorrowing = this.variableDebtValue * -1n;
            }
          } else {
            if (leverageBigInt <= this.otherNodeLeverage) {
              youAreBorrowing = this.stableDebtValue * -1n;
            }
          }
        } else {
          if (leverage >= this.maxLeverage) {
            youAreBorrowing = this.availableBorrowingAmount;
          }
          if (youAreBorrowing > this.poolStFilBalance) {
            youAreBorrowing = this.poolStFilBalance;
          }
          if (youAreBorrowing > this.maxBorrowableLiquidityAmount) {
            youAreBorrowing = this.maxBorrowableLiquidityAmount;
          }
        }
      }
      this.$store.commit('SET_YOU_ARE_BORROWING', {
        youAreBorrowing,
        borrowMode: this.getRoteMode
      });
      return youAreBorrowing;
    },
    leverageRate() {
      return PercentageMath.div(this.currentPool.maxSealLoadLeverage - WadRayMath.WAD, this.currentPool.maxWithdrawLoadLeverage - WadRayMath.WAD);
    },
    totalWithdrawLoadAmount() {
      return this.currentPool.withdrawLoadTotalAmount;
    },
    totalWithdrawLoadLeverage() {
      return PercentageMath.mul(PercentageMath.div(this.totalWithdrawLoadAmount, this.equityValue), this.leverageRate) + 10000n;
    },
    totalOriginalDebt() {
      return this.debtValue - this.totalWithdrawLoadAmount;
    },
    totalOriginalLeverage() {
      return PercentageMath.div(this.totalOriginalDebt, this.equityValue) + 10000n;
    },
    currentLeverage() {
      let res = this.totalOriginalLeverage + this.totalWithdrawLoadLeverage - 10000n;
      this.$store.commit('SET_CURRENT_LEVERAGE', {
        currentLeverage: res
      });
      return res;
    },
    nodeDebtValue() {
      return this.currentPoolNode.debt_value;
    },
    nodeOriginalDebt() {
      return this.nodeDebtValue - this.nodeWithdrawLoadAmount;
    },
    nodeOriginalDebtLeverage() {
      return PercentageMath.div(this.nodeOriginalDebt, this.equityValue) + 10000n;
    },
    nodeWithdrawLoadAmount() {
      return this.currentPoolNode.withdrawLoadAmount;
    },
    nodeWithdrawLoadLeverage() {
      return PercentageMath.mul(PercentageMath.div(this.nodeWithdrawLoadAmount, this.equityValue), this.leverageRate) + 10000n;
    },
    nodeLeverage() {
      return this.nodeOriginalDebtLeverage + this.nodeWithdrawLoadLeverage - 10000n;
    },
    otherNodeOriginalDebt() {
      return this.totalOriginalDebt - this.nodeOriginalDebt;
    },
    otherNodeDebt() {
      return this.otherNodeOriginalDebt + this.otherNodeWithdrawLoadAmount;
    },
    otherNodeOriginalDebtLeverage() {
      return PercentageMath.div(this.otherNodeOriginalDebt, this.equityValue) + 10000n;
    },
    otherNodeWithdrawLoadAmount() {
      return this.totalWithdrawLoadAmount - this.nodeWithdrawLoadAmount;
    },
    otherNodeWithdrawLoadLeverage() {
      return PercentageMath.mul(PercentageMath.div(this.otherNodeWithdrawLoadAmount, this.equityValue), this.leverageRate) + 10000n;
    },
    otherNodeLeverage() {
      return this.otherNodeOriginalDebtLeverage + this.otherNodeWithdrawLoadLeverage - 10000n;
    },
    otherNodeLeverageSliderWidth() {
      return `${parseFloat((this.otherNodeLeverage - 10000n).toString()) / parseFloat((this.maxLeverage - 10000n).toString()) * this.mainSliderWidth}px`;
    },
    availableBalance() {
      return toBigInt(this.currentPoolNode.availableBalance);
    },
    expectedAvailableBalance() {
      let res = this.availableBalance + this.youAreBorrowing;
      if (res < 0n) {
        return 0n;
      }
      return res;
    },
    debtValue() {
      return this.currentPool.totalDebt;
    },
    afterDebtValue() {
      return this.currentPool.totalDebt + this.youAreBorrowing;
    },
    withdrawLoadLeverageSliderWidth() {
      let diff_leverage = this.nodeWithdrawLoadLeverage + this.otherNodeLeverage - 10000n - toBigInt(parseInt((this.sliderValue / 100 * (this.maxLeverageInt - 10000) + 10000).toString()));
      if (diff_leverage < 0n) {
        diff_leverage = 0n;
      }
      let leverage = this.nodeWithdrawLoadLeverage - diff_leverage - 10000n;
      leverage = leverage < 0n ? 0 : leverage;
      return `${parseFloat(leverage.toString()) / parseFloat((this.maxLeverage - 10000n).toString()) * this.mainSliderWidth}px`;
    },
    withdrawLoadLeverageSliderLeft() {
      return `${20 + parseFloat((this.otherNodeLeverage - 10000n).toString()) / parseFloat((this.maxLeverage - 10000n).toString()) * this.mainSliderWidth}px`;
    },
    maxLeverage() {
      return this.currentPool.maxSealLoadLeverage / WadRayMath.WAD_2;
    },
    maxWithdrawLoadLeverage() {
      return this.currentPool.maxWithdrawLoadLeverage / WadRayMath.WAD_2;
    },
    maxLeverageInt() {
      return parseInt(this.maxLeverage.toString());
    },
    positionValue() {
      return this.currentPool.totalPosition;
    },
    nodePositionValue() {
      return this.currentPoolNode.position_value;
    },
    afterPositionValue() {
      return this.currentPoolNode.position_value;
    },
    guaranteeCurrentPositionValue() {
      return this.guaranteeCurrenNode.position_value;
    },
    poolStFilBalance: state => state.publicInfo.poolStFilBalance,
    poolMaxDebtRatio() {
      return this.currentPool.maxDebtRatio / WadRayMath.WAD_2;
    },
    debtRatio() {
      if (this.debtValue === toBigInt(0)) {
        return toBigInt(0);
      }
      return PercentageMath.div(this.debtValue, this.positionValue);
    },
    firstExpectedInitialBalance() {
      let debt = this.debtValue;
      let tmp = PercentageMath.div(debt, this.maxLeverage - toBigInt(10000));
      return debt + (this.positionValue > tmp ? this.positionValue : tmp);
    },
    expectedInitialBalance() {
      let debt = this.debtValue + this.youAreBorrowing;
      let tmp = PercentageMath.div(debt, this.maxLeverage - toBigInt(10000));
      return debt + (this.positionValue > tmp ? this.positionValue : tmp);
    },
    safetyBuffer() {
      return this.currentPoolNode.safetyBuffer;
    },
    afterSafetyBuffer() {
      if (this.youAreBorrowing <= 0) {
        if (this.youAreBorrowing + this.debtValue <= 0) {
          return 0n;
        }
        return this.safetyBuffer + this.youAreBorrowing;
      } else {
        let _debtValue = this.debtValue + this.youAreBorrowing;
        let tmp = PercentageMath.div(_debtValue, this.maxLeverage - toBigInt(10000));
        let sb1 = _debtValue + tmp;
        let sb2 = this.positionValue - this.availableBalance + this.youAreBorrowing;
        return sb1 > sb2 ? sb1 : sb2;
      }
    },
    expectedExpectedPower() {
      return 0;
    },
    expectedIncreasedEarnings() {
      return 0;
    },
    summaryPositionValue() {
      let res = this.positionValue + this.youAreBorrowing;
      if (res < 0n) {
        return 0n;
      }
      return res;
    },
    summaryDebtValue() {
      return this.debtValue + this.youAreBorrowing;
    },
    summaryDebtRatio() {
      let debt = this.debtValue + this.youAreBorrowing;
      if (debt === toBigInt(0)) {
        return toBigInt(0);
      }
      return PercentageMath.div(debt, this.positionValue);
    },
    summaryLeverage() {
      return toBigInt(this.sliderValue / 100 * (this.maxLeverageInt - 10000) + 10000);
    },
    summarySafetyBuffer() {
      return 0;
    },
    equityValue() {
      return this.positionValue - this.debtValue + this.totalWithdrawLoadAmount;
    },
    afterEquityValue() {
      return this.summaryPositionValue - this.summaryDebtValue;
    },
    variableBorrowRate: state => state.publicInfo.variableBorrowRate,
    stableBorrowRate: state => state.publicInfo.stableBorrowRate,
    variableBorrowSimpleRate: state => {
      return toBigInt(state.publicInfo.variableBorrowRate / E23);
    },
    stableBorrowSimpleRate: state => {
      return toBigInt(state.publicInfo.stableBorrowRate / E23);
    },
    userStableRate() {
      return this.currentPoolNode.userStableRate;
    },
    userAvgRate() {
      let all = PercentageMath.mul(this.currentPoolNode.userStableRate, this.stableDebtValue) + PercentageMath.mul(this.variableBorrowRate, this.variableDebtValue);
      return PercentageMath.div(all, this.debtValue) / E23;
    },
    afterUserAvgRate() {
      let all = 0n;
      if (this.radio3 === 'Variable') {
        all = PercentageMath.mul(this.currentPoolNode.userStableRate, this.stableDebtValue) + PercentageMath.mul(this.afterVariableRate * E23, this.variableDebtValue + this.youAreBorrowing);
      } else {
        all = PercentageMath.mul(this.afterStableRate * E23, this.stableDebtValue + this.youAreBorrowing) + PercentageMath.mul(this.variableBorrowRate, this.variableDebtValue);
      }
      return PercentageMath.div(all, this.afterDebtValue) / E23;
    },
    userStableSimpleRate() {
      return toBigInt(this.userStableRate / E23);
    },
    currentFarmAPR() {
      return PercentageMath.mul(PercentageMath.div(this.positionValue, this.equityValue), toBigInt(this.maxBorrowRate / E23));
    },
    afterCurrentFarmAPR() {
      return PercentageMath.mul(PercentageMath.div(this.summaryPositionValue, this.equityValue), toBigInt(this.maxBorrowRate / E23));
    },
    maxBorrowRate: state => state.publicInfo.maxBorrowRate,
    currentBorrowingInterestAPR() {
      let _variable = PercentageMath.mul(this.variableDebtValue, toBigInt(this.variableBorrowRate / E23));
      let _stable = PercentageMath.mul(this.stableDebtValue, toBigInt(this.userStableRate / E23));
      return PercentageMath.div(_variable + _stable, this.equityValue);
    },
    afterCurrentBorrowingInterestAPR() {
      let addVariable = 0n;
      let addStable = 0n;
      if (this.radio3 === 'Variable') {
        addVariable = this.youAreBorrowing;
      } else {
        addStable = this.youAreBorrowing;
      }
      let _variable = PercentageMath.mul(this.variableDebtValue + addVariable, this.afterVariableRate);
      let _stable = PercentageMath.mul(this.stableDebtValue + addStable, this.afterStableRate);
      return PercentageMath.div(_variable + _stable, this.equityValue);
    },
    totalAPR() {
      return this.currentFarmAPR - this.currentBorrowingInterestAPR;
    },
    afterTotalAPR() {
      return this.afterCurrentFarmAPR - this.afterCurrentBorrowingInterestAPR;
    },
    dailyAPR() {
      return toBigInt(Math.floor(parseFloat(formatUnits(this.totalAPR.toString(), 2)) / 365 * 1000000));
    },
    afterDailyAPR() {
      return toBigInt(Math.floor(parseFloat(formatUnits(this.afterTotalAPR.toString(), 2)) / 365 * 1000000));
    },
    estimatedDailyEarnings() {
      return PercentageMath.mul(this.positionValue, this.dailyAPR) / 10000n;
    },
    afterEstimatedDailyEarnings() {
      return PercentageMath.mul(this.summaryPositionValue, this.afterDailyAPR) / 10000n;
    },
    maxStableRateBorrowSizePercent: state => state.stakeInfo.maxStableRateBorrowSizePercent,
    // 稳定利率最大可借
    stableBorrowingAmount() {
      let res = PercentageMath.mul(this.poolStFilBalance, this.maxStableRateBorrowSizePercent) - 1000000000000000n;
      if (res < 0) {
        return 0n;
      }
      if (res > this.maxBorrowableLiquidityAmount) {
        return this.maxBorrowableLiquidityAmount;
      }
      return res;
    },
    liquidityReserveRatio: state => {
      return state.publicInfo.liquidityReserveRatio / E23;
    },
    // 扣除流动性储备最大可借
    maxBorrowableLiquidityAmount: state => state.publicInfo.maxBorrowableLiquidityAmount,
    totalStakeTokens: state => {
      return toBigInt(state.stakeInfo.totalStakingTokens);
    },
    afterStableBorrowingAmount() {
      let res = this.stableBorrowingAmount - this.youAreBorrowing;
      if (res < 10000000000000000n) {
        return 0n;
      }
      return res;
    },
    guaranteeNodeId() {
      return this.currentNode.guaranteeNodeId;
    },
    guaranteeNode() {
      return this.currentNode.guarantee.guaranteeNode;
    },
    guaranteeCurrenNode() {
      return this.currentNode.guarantee.currentNode;
    },
    guaranteePositionValue() {
      return this.guaranteeNode.position_value;
    },
    guaranteeVariableDebtValue() {
      return this.guaranteeNode.variable_debt_value;
    },
    guaranteeVtableDebtValue() {
      return this.guaranteeNode.stable_debt_value;
    },
    guaranteeDebtValue() {
      return this.guaranteeNode.debt_value;
    },
    guaranteeLeverage() {
      return PercentageMath.div(this.equityValue + toBigInt(this.guaranteeDebtValue), this.equityValue);
    },
    guaranteeCurrenVariableDebtValue() {
      return this.guaranteeCurrenNode.variable_debt_value;
    },
    guaranteeCurrenStableDebtValue() {
      return this.guaranteeCurrenNode.stable_debt_value;
    },
    guaranteeCurrenDebtValue() {
      return this.guaranteeCurrenNode.debt_value;
    },
    guaranteeLeverageSliderWidth() {
      return `${parseFloat((this.guaranteeLeverage - 10000n).toString()) / parseFloat((this.maxLeverage - 10000n).toString()) * this.mainSliderWidth}px`;
    },
    assetsLoading: state => state.assetsLoading,
    paused: state => state.SPLP_IS_PAUSED
  })
};