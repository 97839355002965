export default {
    'common': {
        'copy': '复制',
        'copied': '已复制',
        'Overview': '总览',
        'NetAPY': '质押 APY',
        'CurrentFILPrice': 'FIL 价格',
        'Dashboard': '分析',
        'Referrals': '推荐',
        'More': '更多',
        'Stake': '质押',
        'Farm': '借贷',
        'more': '更多',
        'return': '返回',
        'Warning': '警告',
        'Tips': '提示',
        'Errors': '错误',
        'close': '关闭',
        'Node': '节点',
        'Balance':'余额',
        'interest': '累积收入',
        'Net Mode': '网络模式',
        'Disconnect Wallet': '断开钱包',
        'Wallet': '钱包',
        'Connect Wallet': '连接钱包',
        'Connect a wallet': '连接钱包',
        'networkTitle':'糟糕，您的钱包不在正确的网络上。',
        'networkTip':'您的钱包似乎与 stfil.app 在不同的网络上运行。 请手动更改钱包中的网络，或者您可以单击下面的按钮。',
        'Switch network': '切换网络',
        'The app is running in testnet mode': '该应用程序正在测试网模式下运行。',
        'Disable testnet': '关闭测试网',
        'Language': '语言',
        'Wait for transaction confirmation': '等待确认交易',
        'wait for approve': '等待合约批准确认',
        'Awaiting stFIL Approval': '等待批准stFIL支出',
        'Waiting for winding': '等待链上确认',
        'Waiting for msg': '等待数据同步',
        'Transaction ID': '交易ID',
        'Copy and share poster successfully ready to jump': '复制分享海报成功，准备跳转',
        'Copy and share poster successfully ready to jump2': '复制分享海报链接成功，请在手机浏览器中打开',
        'Contract': '合约地址',
        'flaskErrTips': '请禁用已安装的 MetaMask 版本。只保留Flask版本，然后刷新页面。同一个浏览器中启动多个 MetaMask 会破坏 dapp 交互。',
        'Connection timed out': '连接超时，请检查您的网络或者重试。',
        "Failed to fetch": "请检查您的钱包APP版本是否最新，以及检查您的网络",
        "Governance": '治理',
        "AVG": "平均",
        'AUDITED SECURED BY': '安全 & 审计:',
        'Convert to APR': '换算',
        'APR': 'APR',
        'clear': '清除',
        'Wallet not detected': '未检测到钱包。请连接或安装钱包，然后刷新重试',
        'Model': {
            'Interest Rate Model': '利率模型',
            'Variable Interest Rate': '浮动利率',
            'Variable Interest Rate Desc': '提供更公平、更透明的市场定价机制，使市场参与者能够更加灵活地管理自己的资金，并从市场波动中获得更大的收益。',
            'Stable Interest Rate': '稳定利率',
            'Stable Interest Rate Desc': '<p>在固定借贷周期内保持不变的，不受市场供需关系的影响。稳定利率模型通常适用于那些希望在特定时间段内获得可预测收益的存储提供商。</p><p>单次只能借池子可用流动性的20%。</p>',
            'Optimal Utilization Rate': '最佳资金利用率',
            'Optimal Utilization Rate Desc': '定义这样一个区间[80%,90%]作为资产储备为目标利用率，超越它则利率大幅上升，用于维持池子始终具有流动性。',
            'Interest Rate Protection': '利率保护',
            'Interest Rate Protection Desc': '通过设置利率的上限和下限，来维持利率在极端情况下太高或者太低，避免存款人和借款人恐慌。',
            'diffBrowserIn': '需要还款数量',
            'diffBrowserOut': '需要借款数量',
            'liquidityReserveRatio Desc': '协议保留5%的流动性作为存款准备金。借贷池无法借出超出95%的资金利用率的资金，同时利率增长放缓。',
        },
        'Browser Wallet': 'Browser Wallet',
        'NoData': '暂无数据',
        'Lending Paused': '暂停借款',
        'edit': '修改',
        'Invalid Signature': '提交签名错误，请检查签名内容以及发起人余额是否足够支付GAS',
        'Sign the node source Owner offline': '使用节点 源Owner 离线签名？',
        'Loan pool administrator signature': '借贷池管理员签名？',
        'herald': 'xxxxx xxxxxxxxx xxxxxx xxxx xx xxxxxx xxxxx xxxxx xx xxxxxxxx',
        'add address': "添加地址",
        'add derivation path': "添加派生路径",
        'Please enter a derivation path': '请输入派生路径',
        'Allowance': '授权额度',
        'what is wstfil': 'wstFIL（包装的stFIL）是stFIL的非变基版本，wstFIL的价格以stFIL的变化计价。wstFIL 余额只能在转账、铸造和销毁时更改。在任何给定时间，任何持有 wstFIL的人都可以按固定汇率将任意数量的 wstFIL 转换为 stFIL，反之亦然。通常，当 stFIL 进行变基时，汇率将会更新。'
    },
    'change': {
        'ChangeWorker': '修改 Worker 地址',
        'ChangeWorkerDesc': '通过STFIL协议修改节点Worker。',
        'ChangeControl': '修改 Control 地址',
        'ChangeControlDesc': '通过STFIL协议修改节点Control。',
        'settingWorker': '现在设置',
        'settingControl': '现在设置',
        'current': '当前',
        'change': '修改',
        'new': '新',
        'workerInputTip': '新 Worker ID 地址 (f0地址)',
        'controlInputTip': '新 Control ID 地址 (f0地址)',
        'newTime': '生效时间',
        'cancel': '取消',
        'workerOk': '您已成功修改 Worker ID',
        'controlOk': '您已成功修改 Control ID',
        'workerOkWait': '修改Worker地址需要等待一段时间生效',
        'workerInputErr': '请输入正确的 Worker ID',
        'controlInputErr': '请输入正确的 Control ID',
        'Please enter Worker ID': '请输入 Worker ID',
        'Please enter Control ID': '请输入 Control ID',
    },
    "transfer": {
        "transfer": "钱包转账",
        "transferForwardTip": "并非所有交易所都能正确接受使用 FILForward 进行的交易。 请先联系您的交易所确认或发送小额测试交易。",
        "transfer0XTip": "一些交易所目前暂时不接受FIL，请考虑进行小额试交易以确保正常处理。",
        "from": "从",
        "to" : "到",
        "amount": "金额",
        "balance": "余额",
        "send": "发送",
        "receive": "接收",
        "clear": "重置",
        "noEthAddressTip": "这不是一个有效的0x地址",
        "transferBalanceTip": "转账金额超过了你的余额",
        "transfeStfilTip": "该地址没有f0,f4地址，无法转账stFIL",
        "Transfer successful": "转账成功",
        "contractTipTile": "注意",
        "contractTipTileErr": "错误",
        "contractTipContent": "向智能合约转移资金，无法调用它的代码。",
        "To FilForwarder": "由 FilForwarder 转发",
        'Scan the address to receive payment': '扫描地址以接收付款',
    },
    "nft": {
        "title": 'StableJumper NFTs',
        "decs": "加入5,000个勇敢之跃的行列，踏上稳健之旅，拥有StableJumper NFT的独特身份！",
        "Total Risk Reserve Value": "风险储备金总额",
        "Earn real profit as a Community Ambassador": "作为社区大使，与社区共同成长，分享丰富的收获。",
        "Earn Airdrop from DAO with your NFT": "通过你的NFT在DAO中获得空投奖励。",
        "You dont have any StableJumper NFTs": "您还没有StableJumper NFTs。",
        "Get Your StableJumper NFTs":  "获得你的 StableJumper NFTs",
        "How to get reward with StableJumper NFTs": "如何通过StableJumper NFT获得奖励？",
        "referring": "推荐",
        "By referring a staker to join you can get it for free": "通过推荐staker加入，免费获得。",
        "Referral now": "现在推荐",
        "Stake your NFT to earn rewards from STFIL DAO Tokens": "质押您的NFT，以获得STFIL DAO代币的奖励",
        "The longer you stake the higher rewards you earn from STFIL DAO": "您质押的时间越长，您从 STFIL DAO 获得的奖励就越高。",
        "All income from minting is deposited into the risk reserve fund": "铸造NFT的收益全部存入风险准备金",
        "Current epoch": "当前纪元",
        "Staked NFT": "质押的 NFT",
        "Total distributed": "总分配",
        "Next epoch": "下一个纪元",
        "Max APR": "最大年利率",
        "Next epoch reward": "下一个纪元奖励",
        "Your Nft": "你的NFT",
        "Your staked NFT": "你质押的NFT",
        "Whitelist complete": "白名单Mint完成",
        "Whitelist Mint NFT": "通过白名单Mint",
        "Off Whitelist": "不在白名单",
        "Get NFT": "获取NFT",
        "Stake NFT": "质押NFT",
        "Earn reward": "获得奖励",
        'Coming soon': '快来了...',
        'Pending public sale': '等待公售',
        'Public sale ended': '公售结束',
        'public sale start': '公售时间',
        'public sale end': '停售时间',
        'Round': '第$$$$轮',
        'Round End': '第$$$$轮公售截止时间',
        'Mint through Rare WhiteList': '通过稀有白名单Mint',
        'From': '来自',
        'stake': '质押',
        'unStake': '解除质押',
        'First round of presale remaining': '第1轮预售，剩余50'
    },
    'Referrals': {
        'Referrals': '推荐',
        'Introduction': '通过推荐计划成为社区大使，并获得治理权和分红权。如需更多信息，<a href="https://docs.stfil.io/referral-program/introduction-to-referral" target="_blank">请阅读推荐计划详情</a>。',
        'Referral Code': '推荐码',
        'ReferralTip': '看起来您还没有可以分享的推荐码。现在创建一个并开始赚取返利吧！',
        'GenerateCode': '生成推荐码',
        'copyGenerateCode': '复制分享链接',
        'copyGenerateCodeOk': '复制成功',
        'GenerateCodeNew': '生成新的推荐码',
        'GenerateCodeInput': '请输入纯数字推荐码',
        'GenerateCodeInputBtn': '输入推荐码',
        'GenerateCodeInputErr': '推荐代码必须介于$$$$1-$$$$2之间',
        'Target': '目标',
        'TargetTip': '只要您的推荐总利息达到目标值，就能成为社区大使并领取NFT。',
        'TotalRebates': '推荐数',
        'Referral Codes': '推荐码列表',
        'Stakes Referred': '推荐数',
        'Rewards Distribution History': '奖励分配历史记录',
        'Referral Details': '推荐详情',
        'WithdrawRecord':'提现记录',
        'WithdrawTime':'提交时间',
        'WithdrawAmount':'金额',
        'Fee': '手续费',
        'WithdrawStatus':'状态',
        'WithdrawStatus_1':'已提现',
        'WithdrawStatus_0':'等待处理',
        'NFT has been obtained': '社区大使',
        'Go whitelist MINT': 'Go白名单MINT',
        'Waiting to add whitelist': '等待确认NFT',
        'Waiting to add whitelist NFT': '等待确认白名单',
        'Address': '地址',
        'Reward': '成为大使',
        'No referral codes': '没有推荐码。',
        'No Rewards Distribution History yet': '没有质押详情列表。',
        'No Withdraw Distribution History yet': '没有提现记录。',
        'Create': '创建',
        'Checking code': '检查推荐码...',
        'Code already token': '推荐码已存在',
        "Your Referral Code": "你的推荐代码数",
        "Your Stakes Referred": "你的推荐数",
        "commission": "佣金",
        "commissionTip": "佣金:（总利息-目标）* 您的返佣比例",
        "commissionRate": "你的返佣比例",
        "commissionRateTip": "通过推荐计划成为社区大使，并获得治理权和分红权。",
        "commissioned": "已提现",
        "Withdraw commission": "提现申请",
        'NFT pick up address': 'NFT领取地址',
        'Please MINT NFT through the NFT claim address': '请通过NFT领取地址MINT NFT',
        'Progress': '进度',
        'NFT whitelist address': 'NFT白名单地址',
        'Submit': '提交',
        'Enter the address to get the NFT':'输入获取NFT的地址',
        'Please enter the correct wallet address' :'请输入正确的钱包地址',
        'Please enter the wallet address': '请输入钱包地址',
        'Setting': '设置',
        'rule': {
            't': '简介',
            'title': '创建您的专属推荐码，并分享推荐码给其他用户，免费领取StableJumper NFTs',
            'rule1': '推荐计划的返利由金库来支付，不会扣除被推荐人的奖励。',
            'rule2': '您可以为不同渠道创建不同的推荐码，奖励是累积总和。',
            'rule3': '其他用户通过推荐码参与质押，您将从中获得利息回扣。',
            'rule4': '通过带有推荐码的地址参与质押，所产生的利息将会被统计到您的总利息中。',
            'rule5': '您的总利息达到指定的目标值后，即可免费获得NFT白名单。',
            'rule6': '不得通过非法手段进行恶意刷量和作弊行为，一经发现将取消奖励资格。',
            'rule7': 'STFIL DAO保留任何时候修改、调整或终止活动规则的权利。',
            'tip': '如果您有任何疑问或建议，请随时联系我们，谢谢！',
            'learMore': '了解更多',
        },
        'Customized': '定制',
        'refresh': '刷新',
        'Wait': '等待',
        'poster': '海报',
        'saveImg': '保存图片',
        'NoCansaveImg': '请使用PC端保存图片',
        'Stake': '我的',
        'Affiliates': '联盟',
        'Your Active Referral Code': '您的活跃推荐码',
        'Your Active Referral Code Tip': '输入推荐码以获取高达 2% 的返利，返利将通过空投的方式发送到您的钱包地址。',
        'Total Interest': '总利息',
        'Share link': '分享链接',
        'Total Rebates': '总返利',
        'Can withdraw': '可提现',
        'Settlement Period': '结算周期',
        'Current Interest': '本轮利息',
        'Discount Rate': '返利费率',
        'Rebate Amount': '返利金额',
        'No settlement record': '没有结算记录。',
        'Your Rebates Rate': '你的返利率',
        'Enter Referral Code': '设置推荐码',
        'Your Identity': '你的身份',
        'Next Level': '下一级',
        'Upgrade condition': '升级条件',
        'Staking one NFT': '质押一个NFT',
        'stFIL balance greater than': 'stFIL 余额超过',
        'Complete cycle goals obtain rare NFT and pledge':'完成周期目标,获取稀有NFT并质押',
        'Waiting to airdrop rare NFT': '等待空投稀有NFT',
        'super_rule2': '通过 <span class="link-text-2">Mint稀有白名单NFT</span>， 并<span class="link-text-2">Sake</span>你的NFT',
        'Total Referral Interest': '总推荐利息',
        'Total Referral Stake': '总推荐质押',
        'Total Referral Rebates': '总推荐返佣',
        'Total Amount Withdrawn': '可提现',
        'level': {
            'Referrer': '推荐人',
            'Ambassador': '大使',
            'Super Ambassador': '超级大使',
        },
        'The referral code does not exist': '推荐代码不存在',
        'Successfully added to queue': '已经成功添加到队列。',
        'The minimum withdrawal amount for commissions': '最低提现数量为1FIL。'
    },
    'ledgerSp': {
        'Connect with Ledger Device': '连接Ledger设备',
        'Something went wrong':'出了什么问题',
        'Please unlock your Ledger device and open the Filecoin App': '请解锁您的Ledger设备并打开Filecoin应用程序',
        'Awaiting confirmation': '等待确认...',
        'To complete the transaction please review the recipient and amount on your': '要完成交易，请查看您的收款人地址在',
        'Ledger Device': 'Ledger设备',
        'If the details match what you see in select': '上是否一致. 如果细节与您在stfil.io上的一致。',
        'Approve': '批准',
        'Remember': '提示',
        'Once approved the message is submitted transactions are final': '一旦批准，消息就被提交，交易就确认了。',
        'Sign Message': '签名消息',
        'Please do not close this page Waiting for message to link up': '请不要关闭此页面。等待消息链接...',
        'Sign the message with your Ledger by clicking the below': '通过点击下面的“签名消息”，用您的分类帐对消息进行签名。',
        'Search Account': '查询地址...',
        'Connect Ledger': '连接 Ledger',
        'Wait Approve': '等待批准...',
        'SignMessage': '签名消息',
        'Waiting up chain': '等待消息上链...',
        'Please approve the request on your Ledger device': '请在您的Ledger设备上批准请求。',
        'The account address is not found': '没有找到账户地址，您可以从这个地址查看您的钱包是否存在。或者再试一次!',
        'Is your Ledger device unlocked': '您的Ledger设备是否已解锁?',
        'Is your Ledger device locked or busy': '您的Ledger设备是锁定的还是忙着?',
        'Were sorry but we cant communicate with your': '很抱歉，我们无法与您的Ledger设备通信，因为您的浏览器与我们的分类帐通信机制不兼容。',
        'Is your Ledger device plugged in':'您的Ledger设备是否已插入电源?',
        'Please quit the Filecoin appand unplugreplug your Ledger deviceand try again':'请退出Filecoin应用程序，并重新进入Filecoin应用程序，然后再试一次。',
        'Please quit any other App using your Ledger device':'请退出使用您的Ledger设备的任何其他应用程序。',
        'Please update your Filecoin':'请更新您的Filecoin Ledger 应用程序到v0.23.0以上，然后再试一次。',
        'Transaction rejected':'交易拒绝',
        'signFair':'签名失败',
        'openExpertMode':'打开专家模式失败',
        'LedgerTransactionRejectedErrors':'签名被拒绝',
        'LedgerOpenExpertModeModeErrors': '请打开 Ledger Filecoin app 的专家模式 (Expert mode)',
        'LedgerAddAddressExistErrors': '该地址已存在',
        'LedgerAddAddressFormatErrors': '请输入正确格式的派生路径. e.g (m/44\'/461\'/6\'/0/0)',
        'Please quit the Fi':'请退出Filecoin应用程序，并拔掉/重新插入您的Ledger设备，然后再试一次。',
        'accounts': '选择账户',
        'filecoin app not open on device': 'Filecoin应用程序未在设备上打开。'
    },
    'footer': {
        'tips': 'STFIL 是 Filecoin 网络的 Liquid Staking 协议。 它允许用户在不锁定资产和维护存储提供商基础设施的情况下，通过允许存储提供商在协议池中获得更多的 FIL 进行质押来获得区块奖励。',
        'Social Links': '社交链接',
        'Learn': '学习',
        'Introduction': '介绍',
        'Guides': '导游',
        'Developers': '开发者',
        'Quick Links': '快速链接',
        'TermsConditions': '条款和条件',
        'PrivacyPolicy': '隐私政策',
        'document': '文档',
        'audit': '审计',
        'BountyProgram': 'Bug 赏金计划',
        'Stay up to date with': '获取 stfil.io 的最新信息',
        'Subscribe to the newsletter for Oasis updates': '订阅我们的电子报以获得 stfil.io 的最新信息',
        'Subscribe OK': '感谢您订阅我们的电子报！',
        'Subscribe ': '感谢您订阅我们的电子报！',
        'Subscribe Btn': '订阅',
        'Subscribe Placeholder': '电子邮箱',
    },
    'dashboard': {
        'maxBorrowableLiquidityAmount': '剩余可借款流动性金额',
        'liquidityReserveRatio': '存款准备金',
        'Dashboard': '数据分析',
        'Introduction': '透明数据分析，实时追踪您的奖励变化！',
        'TVL': '锁定的总价值',
        'STVL': '(TVL)',
        'TotalValueLocked': '锁定的总价值',
        'Farm Balance': '节点资产',
        'DailyEarnings': '每日收入',
        'AvailableLiquidity': '可用流动资金',
        'TotalBorrowed': '总借入数量',
        'dashTotalBorrowed': '总借出数量',
        'ReserveFactor': '储备系数',
        'ReserveFactorTip': '储备系数(10%)是利息的百分比，用于提供 STFIL 的社区长期治理，以促进生态系统增长。',
        'UtilizationRate': '资金利用率',
        'OptimalUtilizationRate': '最佳资金利用率',
        'FarmBalance': '节点资产',
        'StakingPoolInfo': '质押池信息',
        'TotalSupply': '总供应量',
        'Supply': '供应量',
        'adTitle': '成为高收益的存储提供商',
        'adContent': '我们为您提供充足的质押资产，助您赚取更多存储奖励，为Web3基础设施赋能！',
        'adBtn': '现在入职',
        'BorrowInfo': '借贷信息',
        'Variable': '借出数量,浮动利率',
        'Stable': '借出数量,稳定利率',
        'InterestRateMode': '利率信息',
        'APYVariable': 'APY, 浮动借款利率',
        'variableRateAvg': '30天平均APY, 浮动利率',
        'APYStable': 'APY, 稳定借款利率',
        'BorrowStable': '借出数量,稳定利率',
        'BorrowVariable': '借出数量,浮动利率',
        'BorrowAPRstable': 'APR,稳定利率',
        'BorrowAPRvariable': 'APR,浮动利率',
        'BorrowAPYstable': 'APY,稳定借款利率',
        'BorrowAPYvariable': 'APY,浮动借款利率',
        'supply': 'FIL供应量',
        'StakersNodes': '质押人数/节点数',
        'Stakers': '质押人数',
        'nodes': '节点数',
        'total power': '总算力',
        'Collateral': '总仓位',
        'Total Collateral Automated': '总抵押物',
        'Total Adj Power': '总有效算力',
    },
    'stake': {
        'title': '质押',
        'Staked APY': '质押年收益率',
        'APY': '(APY)',
        'Introduction': '活期质押，让你的FIL实时生息，自动复利。',
        'TotalSupply': '总供应量',
        'UtilizationRate': '资金利用率',
        'SupplyAPY': '质押年收益率',
        'NetAPY': '质押年收益率',
        'NetAPYTip': '收益是由存储提供商节点的收益提供，收益会通过stFIL的余额增多获得。',
        'WhatDoYouGainWhenHoldStFIL': '持有 stFIL 有什么好处？',
        'WhatDoYouGainWhenHoldStFILTip': 'Filecoin 网络中扇区质押周期的规则对质押者是透明的。 stFIL让持有者在参与Filecoin时有更多的可能。',
        'LearnMore': '了解更多',
        'WhichActionWouldYouLikeToChoose': '你想选择哪个操作？',
        'YouWillReceive': '您将收到',
        'AvailableLiquidity': '可用流动资金',
        'ExchangeRate': '汇率',
        'Stake': '质押',
        'unStake': '解除质押',
        'AddSTFILToMetaMask':'添加Token到钱包',
        'Expected': '预计月回报',
        'stakeInputErr limit max': 'STAKE金额将超过你的FIL余额',
        'stakeInputErr gas max': '预留至少0.5FIL的余额用于GAS',
        'Gas may be insufficient Reserve a balance of at least': 'Gas可能不足，请预留至少0.5FIL!',
        'You have successfully staked FIL': '您已成功质押 xxxx FIL',
        'You have successfully unstaked FIL': '您已成功解除质押 xxxx FIL',
        'You have successfully wrap stFIL': '您已成功Wrap xxxx stFIL',
        'You have successfully unwrap stFIL': '您已成功Unwrap xxxx stFIL',
        'Zap stFIL to wstFIL': '将 stFIL 兑换为 wstFIL',
        'unStakeInputErr': '当前可用流动资金不足',
    },
    'farm': {
        'Next Update': '下次更新',
        'title': '借贷',
        'Introduction': '给你的存储提供商节点添加灵活的杠杆，提高年化收益！',
        'TotalBorrowed': '总借入数量',
        'APYVariable': 'APY, 浮动利率',
        'APYStable': 'APY, 稳定利率',
        'StorageProviders': '存储提供商',
        'Search': '搜索节点',
        'Active': '已入职',
        'Closed': '已离职',
        'OnboardingNow': '现在入职',
        'Node': '节点',
        'Position': '仓位价值',
        'Debt': '债务价值',
        'Equity': '权益价值',
        'DebtRatio': '债务比率',
        'lever': '杠杆',
        'MaxDebtRatio': '最大债务比率',
        'LiqThreshold': '清算阈值',
        'View': '查看',
        'LessThan': '小于',
        'The current node is a collateralized node': '当前节点已担保节点',
        'Please select a collateralized node': '请选择担保节点',
        'Cannot find a node with the same owner': '找不到相同owner的节点',
        'The debt of the node has exceeded the maximum debt': '节点的债务超过了最大债务',
        'You have successfully collateralized the node': '您已成功担保节点',
        'Unsecured node': '解除担保的节点',
        'You have successfully released the collateralized node': '您已成功解除担保节点',
        'There are outstanding debts The guarantee cannot be released': '有未偿还的债务。不能解除担保。',
        'The current node is not collateralized with any other nodes': '当前节点并未担保任何节点',
        'power': '有效算力',
        'collateralNode': {
            'rule1': '担保节点必须是同一个Owner所有者。',
            'rule2': '担保节点需要有相同的抵押角色。',
            'rule3': '担保节点和本节点的债务不能超过最大杠杆。',
            'rule4': '担保后的两个节点杠杆使用最低杠杆。',
            'rule5': '解除担保需要两个节点都没有债务。',
        },
        'minerOverview' : {
            'Address Balance': '节点余额',
            'Available Balance': '可用余额',
            'Initial Pledge': '扇区抵押',
            'Locked Rewards': '提供存储服务锁仓',
            'Average pledge': '平均每TiB质押量',
            'Sector Liquidation Percentage': '扇区终止惩罚比例',
            'Adjusted Power': '有效算力',
            'Rate': '占比',
            'Raw Byte Power': '原值算力',
            'Sector Size': '扇区大小',
            'Sector Status': '扇区状态',
            'total': '全部',
            'active': '有效',
            'faults': '错误',
            'recoveries': '恢复',
            'Account Overview': '访问权限',
            'Contract Operator': '合约操作人',
        },
        'console': {
            'Overview': '总览',
            'History': '历史记录',
            'Miner Overview': '概况',
            'Settings': '配置',
            'PositionValue': '仓位价值',
            'PositionValueTip': '仓位价值是指存储商持有的资产总价值，包括可用余额、初始质押和锁定奖励。',
            'DebtValue': '债务价值',
            'DebtValueTip': '债务价值是指存储提供者在协议中的债务总额，包括存储提供者需要偿还的贷款总额，是浮动债务和稳定债务的总和。',
            'EquityValue': '权益价值',
            'InterestValue': '累积利息',
            'EquityValueTip': '权益价值是指存储提供商的资产净值。 这个值通常是存储提供者的仓位值减去Debt值得到的。',
            'CurrentAPR': '当前APR',
            'EstimatedDailyEarnings': '预计每日收益',
            'DebtRatio': '债务比率',
            'DebtRatioTip': '债务比率衡量存储提供商的总债务与其仓位价值的比值。 它有助于评估债务风险水平，并确定其财务稳定性和偿还能力。',
            'DebtRatioTip2': '当前债务比率包含了担保节点的债务。',
            'SafetyBuffer': '安全缓冲',
            'SafetyBufferTip': '安全缓冲是指本协议设置的一种保护机制，用于保证存储提供者借入的资金用于封装。 它是在存储提供商借钱时产生的，只有当总资产超过安全缓冲部分时才能提款。',
            'MaximumDebtRatio': '最大负债率',
            'LiqThreshold': '清算阈值',
            'LiqThresholdTip': '清算阈值是指本协议约定的存储提供商的债务权益比的阀值。 当存储商的债务比率超过该阈值时，将进行清算以保证平台的稳定性。',
            'MaximumLeverage': '最大杠杆',
            'Delegated': '委托角色',
            'Delegated1': '委托',
            'AvailableBalance': '可用余额',
            'InitialPledge': '扇区抵押',
            'LockedRewards': '提供存储服务锁仓',
            'BorrowingRepayment':'借款 & 还款',
            'BorrowingBtn':'借款封装',
            'RepaymentBtn':'还款',
            'Withdrawal income btn':'提取收益',
            'Loan withdrawal btn':'借款提现',
            'WithdrawFromNode':'提取收益',
            'WarningTip': '不要使用 "<b>lotus-miner actor withdraw ...</b>" 绕过协议进行提现，资金将进入黑洞，导致无法寻回。',
            'learnMore': '了解更多',
            'Choosethewayyoulike': '选择你喜欢的方式',
            'ByLeverage': '通过杠杆',
            'ByAmount': '通过金额',
            'Whatinterestratedoyoupreferforborrowing': '您希望借款的利率是多少？',
            'Whatyourpreferredrepaymentinterestrate': '您偏好的还款利率是多少？',
            'Choose your repayment method': '选择您的还款方式',
            'repay1': '节点可用余额',
            'repay2': '钱包余额',
            'Priority repayment Seal Loan': '优先还款“借款封装”',
            'Priority repayment Withdraw Loan': '优先还款“借款提现”',
            'Pleasemakechangestotheleverage': '请调整杠杆',
            'OutstandingVariableDebt': '未偿还浮动债务',
            'OutstandingVariableDebtTip': '浮动利率将根据市场情况波动。 更加公平透明的市场定价机制，让存储商可以更灵活地管理资金，从市场波动中获取更大的利润。',
            'MaximumBorrowingLimit': '最高借入限额',
            'TargetLeverage': '目标杠杆',
            'Youareborrowing': '你将借款',
            'CurrentVariableDebt': '当前浮动债务',
            'RemainingBorrowingCapacity': '剩余可借',
            'SingleRemainingBorrowingCapacity': '单次剩余可借',
            'The loan amount cannot exceed the maximum leverage': '借款金额不能大于最大杠杆',
            "The loan amount cannot exceed the available liquid funds": '借款金额不能大于可用流动资金',
            "The maximum borrowing amount for stable debt cannot exceed of the available liquid funds": '稳定债务单次借款金额不能大于可用流动资金的$$$%',
            'VariableInterestRate': '浮动利率',
            'Isthiswhatyouexpected': '这是你所期望的吗？',
            'CurrentFarmAPR': '当前收益农业APR',
            'CurrentBorrowingInterestAPR': '当前借款利息 APR',
            'TotalAPR': '总APR',
            'DailyAPR': '每日APR',
            'WhichActionWouldYouLikeToChoose': '你想选择哪个动作？',
            'Borrowing': '借款',
            'Repayment': '还款',
            'Youarerepaying': '你将还款',
            'CurrentLeverage': '当前杠杆',
            'OutstandingStableDebt': '未偿还稳定债务',
            'OutstandingStableDebtTip': '稳定的利率将在您的贷款期限内保持不变。推荐给希望在特定时间段内获得可预测回报的存储提供商。',
            'CurrentStableDebt': '当前稳定的债务',
            'StableInterestRate': '平均稳定利率',
            'avgRate': '平均利率',
            'CurrentStableInterestRate': '当前稳定利率',
            'Howmachmorewouldyouliketowithdrow': '您还想提取多少？',
            'Maximumwithdrawal': '最大可提现',
            'Payee': '收款人',
            'PayeeTip': '节点的owner是唯一的接收地址。',
            'Amount': '数量',
            'Withdraw': '提现',
            'Calculatedbasedonyourleveragedposition': '基于您的',
            'Calculatedbasedonyourleveragedposition2': '杠杆仓位计算',
            'VariableDebt': '浮动债务',
            'StableDebt': '稳定债务',
            'MaxInterestRate': '最高利率',
            'OptimalInterestRate': '最佳利率',
            'OptimalUtilizationRate': '最佳资金利用率',
            'UtilizationRate': '资金利用率',
            'APRstable': 'APR,稳定利率',
            'APRvariable': 'APR,浮动利率',
            'Close': '关闭',
            'Variable': '浮动利率',
            'Stable': '稳定利率',
            'ConnectOperatorWallet': '连接操作人钱包',
            'ConnectAdminWallet': '连接管理员钱包',
            'REPAY': '还款',
            'REPAY-CASH': '钱包还款',
            'BORROW': '借款',
            'DISABLED': '禁用',
            'Yourcurrentdebtratiorateexceedsthemaximumdebtratio':'您当前的负债率超过了最大负债率！',
            'Gotorepay': '去还款',
            'WITHDRAW': '提现',
            'Optimal':'最佳资金利用率区间',
            'Borrows':'借款',
            'Repayments':'还款',
            'Withdraws':'提现',
            'Liquidations':'清算',
            'Action':'节点变更',
            'TxnHash': 'Txn Hash',
            'From': '操作员',
            'To': '接收方',
            'Age': '时间',
            'Mode': '利率模型',
            'Rate': '当前利率',
            'Liquidator': '清算人',
            'Award': '奖励',
            'ApproveOperator': '授予操作人',
            'ApproveOperatorJIANJIE': '欢迎来到授予操作人页面。 在这里，您可以授予受信任人员安全访问权限以进行借贷、还款和取款等金融交易。 STFIL协议将存储提供者的所有权和操作人分离，确保节点的授权更加安全。',
            'ApproveOperatorTip': 'STFIL 协议通过分离存储提供商的所有权和运营来确保节点的安全，只允许授权的操作人执行借贷、还款和取款权操作。',
            'ApproveOperatorBtn': '设置操作人',
            'Unrestricted': '无限制退出，完全去中心化',
            'UnrestrictedTip': 'STFIL 协议允许完全不受限制的入职和离职流程。 您可以随时自由离开或重新加入。 请确保在离开前已偿还所有债务。',
            'UnrestrictedBtn': '立即离职',
            'Oopsunauthorized operator': '节点还没有设置操作人!',
            'Oopsunauthorized operator2': 'STFIL协议只允许授权的运营商执行借款、还款和取款等操作。您可以点击下面的按钮审批操作员。',
            'actorId': '节点',
            'role': '角色',
            'action': '动作',
            'NodeDelegate': '入职',
            'NodeUndelegate': '离职',
            'TerminateGuarantee': '终止担保关系',
            'TerminateGuaranteeTip': '终止与 f0xxx 节点的担保关系，不再为它提供借款担保。',
            'TerminateGuaranteeNow': '现在解除',
            'ProvideGuarantee': '为节点提供担保',
            'ProvideGuaranteeTip': 'STFIL 协议允许两个节点形成互相担保的关系，共享仓位、共担风险。',
            'ProvideGuaranteeNow': '现在担保',
            'Contains the Position': '与$$$$共享',
            'current' : '当前',
            'This is the debt of the collateral node': '这是担保节点的债务',
            "Make sure that all debts have been repaid": '当前节点有未偿还完的债务',
            'Please enter the amount withdraw': '请输入提现金额。',
            'Successfully upgraded to Owner delegate': '已成功升级为Owner委托。',
            'The current node delegate role is not beneficiary': '当前节点委托角色不是受益人。',
            'The actual repayment TIP': '实际还款金额以合约最终执行金额为准。',
        }
    },
    'Onboarding': {
        'title':'节点入职',
        'tips':'欢迎来到 STFIL 存储提供商节点入职。 作为Filecoin生态的一份子，您将获得贷款杠杆，可以更好地为全球用户提供安全、可靠、高效的数据存储服务，并从中获得更多收益。 我们期待您的加入，并为您提供必要的支持和资源，帮助您成为优质存储提供商。',
        'Search': '搜索',
        'SearchTip': '搜索地址',
        'Position Value': '仓位价值',
        'Position Value tip': '仓位价值是指存储商持有的资产总价值，包括可用余额、初始质押和锁定奖励。',
        'Available Balance': '可用余额',
        'Locked Rewards': '提供存储服务锁仓',
        'Initial Pledge': '扇区抵押',
        'Adjusted Power': '有效算力',
        'Raw Byte Power': '原值算力',
        'Sector Size': '扇区大小',
        'Sector Count': '扇区数量',
        'Please select the role to delegate': '请选择要委托的角色',
        'Owner': 'Owner',
        'Beneficiary': 'Beneficiary',
        'Original Owner': 'Original Owner',
        'Address': '地址',
        'AddressTips1': '后续步骤需要此Owner对消息进行签名才能成功委托。',
        'Available Borrowing': '可用借贷',
        'Maximum Debt Ratio': '最大负债率',
        'LiqThreshold': '清算阈值',
        'LiqThreshold Tip': '清算阈值是指本协议约定的存储提供商的债务权益比的阀值。 当存储商的债务比率超过该阈值时，将进行清算以保证平台的稳定性。',
        'Maximum Leverage': '最大杠杆',
        'Pre': '上一步',
        'Next': '下一步',
        'Submit a' : '提交',
        'proposal' : ' 提案.',
        'Actor': 'Actor',
        'Approval Threshold': 'Approval Threshold',
        'NoofSinger': 'No. of Singer',
        'Please select an address to initiate this proposal': '请选择一个地址来发起这个提案',
        'Approved': '确认',
        'Confirm': '确认',
        'Approval proposal by STFIL staking pool': 'STFIL 质押池的批准提案。',
        'Message Content': '消息内容',
        'Method': '方法',
        'Fee': '费率',
        'params': '参数',
        'Please choose your signature method': '请选择您的签名方式。',
        'Sign Message': '签名消息',
        'Sign message via related offline signature account': '通过相关的离线签名账户对消息进行签名。 使用下面的 Lotus Cli 程序制作签名',
        'venus Sign message via related offline signature account': '通过相关的离线签名账户对消息进行签名。 使用下面的 Venus Wallet 制作签名',
        'Sign message via related offline signature account2': '通过相关的离线签名账户对消息进行签名。 打开离线应用程序并扫描下方二维码进行签名。',
        'STFILWallet': 'FiveToken Pro',
        'Please enter the result': '请输入结果',
        'Rest': '重置',
        'Push': '提交',
        'Not installed?': '未安装？',
        'Text Import': '文本输入？',
        'Scan QR code here': '扫描二维码',
        'Message ID': '消息 ID',
        'Multisig': '多签',
        'This node has delegated the owner role': '该节点已委托Owner角色！',
        'You have successfully joined': '已成功入职！',
        'Please take good care the original owner': '请保管好原Owner，它仍然是这个节点的主人。',
        'You have successfully completed your entry application': '你已成功完成入入职！',
        'View Node': '查看节点',
        'Approval proposal by original owner':'Original owner 批准提案',
        'The original owner will take over the ownership':'原Owner将接管节点所有权，请妥善保管。',
        'Once the first step is complete':'一旦第一步完成，就相当于已经离职了。 如果进程中断，请返回此处重试。',
        'roposal by staking pool':'权益池的提议。',
        'Thank you for your support for': '感谢一路以来对STFIL协议的支持。',
        'Node Offboarding': '节点离职',
        'Try Again': '重试',
        'You have successfully offboarded':'您已成功离职。',
        'Thank you for your unwavering':'感谢您对 STFIL 协议的坚定支持。 我期待着将来有机会再次合作。',
        'Safety Buffer':'安全缓冲',
        'Safety Buffer Tip': '安全缓冲是指本协议设置的一种保护机制，用于保证存储提供者借入的资金用于封装。 它是在存储提供商借钱时产生的，只有当总资产超过安全缓冲部分时才能提款。',
        'Equity Value':'权益价值',
        'Equity Value Tip': '权益价值是指存储提供商的资产净值。 这个值通常是存储提供者的仓位值减去Debt值得到的。',
        'Debt Value':'债务价值',
        'Debt Value Tips':'债务价值是指存储提供者在协议中的债务总额，包括存储提供者需要偿还的贷款总额，是浮动债务和稳定债务的总和。',
        'Debt Ratio':'债务比率',
        'Debt Ratio Tips':'债务比率衡量存储提供商的总债务与其仓位价值的比值。 它有助于评估债务风险水平，并确定其财务稳定性和偿还能力。',
        'LiqThreshold Tips':'清算阈值是指本协议约定的存储提供商的债务权益比的阀值。 当存储商的债务比率超过该阈值时，将进行清算以保证平台的稳定性。',
        'This node has been delegated!':'该节点已委托！',
        'There can be no other beneficiaries': '不能有其他受益人！',
        'Scan the QR code after offline': '离线签名后扫描二维码',
        'Click here to scan the QR code': '点击此处扫描二维码',
        'Qr Scan': '扫描二维码？',
        'F4 address does not support entry': 'f4的 Owner 地址不支持入职！',
    },
    'Offboarding': {
        'title' : '节点离职',
        'tips' : '感谢一路以来对STFIL协议的支持。',
        'Node ' : '节点 ',
        'Submit a' : '提交',
        'proposal by staking pool' : ' 提案到 Staking pool.',
        'proposal by lengin pool' : ' 提案',
        'The original owner will take over the authority of the node beneficiary':'原Owner将接管节点受益人的权限，请妥善保管。',
        'Once the first step is complete':'一旦第一步完成，就相当于已经离职了。 如果进程中断，请返回此处重试。',
        'There are outstanding debts': '有未偿还的债务。',
        'There are haveGuaranteeNodeId debts': '请先解除担保节点再离职。',
        'Address': '地址',
        'not skip this step': '上一步不可逆，请勿跳过此步',
        'not skip this step2': ', 否则需要离线发送“ChangeOwnerAddress”消息。',
        'isFineOffBoardTip': '节点 $$$nodeId$$$ 已经离职。准备跳转到历史记录。'
    },
    'Operator': {
        'New Operator': '新操作人',
        'Current Wallet': '当前钱包',
        'Current Wallet Tip': '请输入操作人地址',
        'You have successfully approved the operator': '您已成功设置操作人。',
        'If the operator is changed lost': '如果操作人发生变更、丢失或泄露，您可以返回此处重新审核。',
        'View Node': '查看节点',
        'Reapprove': '重新批准',
        'Actor Not Found': '未找到地址.',
        'Current Operator':'当前操作人',
        'You should recharge this address with some FIL': '你应该给这个地址充值一些FIL',
    },
    'FAQs': {
        'FAQs': '常见问题',
        'Why node onboarding': '为什么要入职节点？',
        'What is required for node onboarding': '节点入职需要什么？',
        'How much loan can a node get': '一个节点可以获得多少贷款？',
        'The difference between delegation methods': '委托方式的区别？',
        'How to redeem a node': '如何赎回节点？',
        'Additional Resources': '其他资源',
        'STFILlending strategy': 'STFIL的贷款策略',
        'Lending Rate Model': '贷款利率模型',
        'Debt interest calculation method': '债务利息计算方法',
        'Debt liquidation': '债务清算',
        'Storage provider KYC': '存储提供商 KYC',
        'NodeOperator operation manual': '节点操作人操作手册',
    },
    'computer': {
        'ROI Calculator':'投资回报率计算器',
        'FIL STATKED': 'FIL 质押',
        'MyBalance': '我的余额',
        'STAKED FOR': '质押时长',
        '1D': '1天',
        '7D': '7天',
        '15D': '15天',
        '30D': '30天',
        '1Y': '1年',
        '3Y': '3年',
        'ROI AT CURRENT RATES': '当前APY下的投资回报率',
        'STAKEFil': '质押 FIL',
    },
    'subpool': {
        'The total amount borrowed by the platform cannot be greater than the liquidity pool': '平台总借款金额不能大于流动池的$$$',
        'Confirm the proposal with the new Owner': '使用新Owner确认提案',
        'Initiate a proposal using the source Owner': '使用源Owner发起提案',
        'new owner tip': '请输入新的Owner地址,支持 f0,f1,f3',
        'new owner err f4 tip': '不支持f4地址',
        'new owner err f2 tip': '暂不支持f2地址',
        'new owner': '新Owner地址',
        'changeOwner': '修改节点源Owner',
        'Please enter the node you want to join': '请输入要入职节点',
        'none': '无',
        'Entry requirement': '入职条件',
        'The node balance is less than': '节点余额小于1FIL',
        'The node balance is less than 2': '节点余额小于1FIL',
        'The node balance is less than 3': '必须小于1FIL',
        'Yourcurrentdebtratiorateexceedsthemaximumdebtratio': '您的借贷池当前的负债率($$$)超过了最大负债率($2$$)！',
        'Borrows': '借款封装',
        'Withdraws': '提取收益',
        'Show all': '显示全部',
        'SafetyBufferTip': '安全缓冲是用于保护借款封装的资金只能用于扇区封装。 它是在存储提供商借钱时产生的，只有当总资产超过安全缓冲部分时才能提款。',
        'ViewPool': '查看借贷池',
        'created': {
            'poolType': '请选择一个借贷池类型',
            'Storage Provider': '存储提供商',
            'Storage Provider Tip': '存储提供商借贷池是一个为Filecoin存储提供商（SPs）提供充足的质押资产，让他们赚取更多存储奖励，为Web3基础设施赋能！</br>它允许SPs获得大量的资金用于扇区的封装，也允许SPs将已经质押的资产借出资金用于资金周转。</br>每个借贷池都是一个私有化的池子，池子内的所有节点形成互相担保的关系，共享仓位、共担风险。',
            'lendingType': '同意老节点入职？',
            'lendingType Tip1': '由于协议对风险控制是严谨的，非常注重SPs节点的最大终止所有扇区受到惩罚的比例。</br> 在Filecoin网络中，不同时间封装的扇区终止扇区的惩罚量也不同，因此更高的借款封装杠杆做老节点禁用。',
            'lendingType Tip2': '专门为SPs的扇区封装提供的借款杠杆，持有一份仓位价值，可以获得两倍仓位价值的借款。',
            'lendingType Tip2_2': '专门为SPs的扇区封装提供的借款杠杆，持有一份仓位价值，可以获得三倍仓位价值的借款。',
            'lendingType Tip3': '为SPs借款用于资金周转的借款杠杆，协议准许SPs提现节点仓位一半的价值。',
            'lendingType Tip4': 'STFIL 协议通过分离存储提供商的所有权和运营来确保节点的安全，借贷池管理员仅具有委托给池子的节点的运营权，节点的所有权仍然由节点原来的Owner持有。',
            'lendingType Tip5': '借贷池委托的节点越多，每次操作消耗的gas也越多，请合理规划您的节点。',
            'isLocalAdmin': '请设置一个池子管理员',
            'crateBtn': '提交',
            'self': '本人',
            'other': '自定义',
            'address': '管理员地址',
            'coming soon': '敬请期待...'
        },
        'LendingPool': '借贷池',
        'createPoolBtn': '现在借款',
        'Create Lending Pool': '创建借款池',
        'subpool list title': '借贷池',
        'admin address':'管理员地址',
        'createPoolOkTip': '借贷池创建成功。',
        'You are not the creator of the loan pool': '你不是借贷池的创建者。',
        'yes': '是',
        'no': '否',
        'poolAddress': '借贷池地址',
        'adminAddress': '管理员地址',
        'nodeCount': '节点数',
        'poolOnlyAcceptsNewNodes':'只接受新节点',
        'poolOnlyAcceptsOldNodes':'老节点入职',
        'poolOnlyAcceptsOldNodes2':'选择借款封装杠杆',
        'poolPosition': '总仓位价值',
        'poolDebt': '总债务价值',
        'Available Seal Load': '可用的借款封装',
        'Available Withdrawal Loan': '可用的借款提现',
        'poolWithdrawLoadTotalAmount': '未偿还的借款提现',
        'poolSealLoadTotalAmount': '未偿还的借款封装',
        'poolEquity': '总权益价值',
        'poolMaxSealedLoadAmount': '总最大可借',
        'poolAvailableBalance': '总可用余额',
        'MaxSealLoadLeverage': '借款封装杠杆',
        'maxLeverage': '最大综合杠杆',
        'poolMaxWithdrawLoadLeverage': '借款提现杠杆',
        'LiqThreshold': '清算阈值',
        'DebtRatio': '负债率',
        'MaximumDebtRatio': '最大负债率',
        'Join the pool': '加入池子',
        'Transfer pool': '转让池子',
        'MaxNodeCount': '最大节点数',
        'Contract address': '合约地址',
        'poolType': '池子类型',
        'Lending Pool': '借贷池',
        'Storage Provider': '存储提供商',
        'Search Contract Pool': '搜索',
        'Regular Lending Pool': '普通借贷池',
        'Regular Lending Pool Tip': '存储提供商借贷池是一个为Filecoin存储提供商（SPs）提供充足的质押资产，让他们赚取更多存储奖励，为Web3基础设施赋能！\n' +
            '它允许SPs获得大量的资金用于扇区的封装，也允许SPs将已经质押的资产借出资金用于资金周转。',
        'Accepting only new nodes': '只接受新节点',
        'Accepting only new nodes tip': '由于协议对风险控制是严谨的，非常注重SPs节点的最大终止所有扇区受到惩罚的比例。\n' +
            '在Filecoin网络中，不同时间封装的扇区终止扇区的惩罚量也不同，因此更高的借款封装杠杆做老节点禁用。',
        'Create using the current wallet': '使用当前钱包创建',
        'Create with a specified wallet': '指定钱包创建',
        'Enter the contract pool administrator': '输入借贷池管理员',
        'withdrawLoadAmount': '已借款提现金额',
        'currentWithdrawLoadAmount': '当前已借款提现金额',
        "This is the pools collateral section": "这是池子担保部分",
        'The debt of the pools nodes': '池子节点的债务',
        'The current loan withdrawal section': '当前借款提现部分',
        'The maximum loan withdrawal leverage for the contract pool': '借贷池最大借款提现杠杆',
        'The caller must be the pool admin': '当前钱包地址不是管理员地址',
        'Pool Position': '借贷池仓位价值',
        'pool admin': '借贷池管理员',
        'pooladdress': '借贷池地址',
        'Approval proposal by STFIL lednding pool': '批准提案',
        'Migrate to the contract pool': '迁移到借贷池',
        'Migration now': '现在迁移',
        'Beneficiary upgrading owner delegation': '受益人升级Owner委托',
        'Change node delegation role and increase leverage': '更改节点委托角色，提高杠杆',
        'Convert now': '现在转换',
        'Please select the contract pool to join': '请选择要加入的借贷池',
        'You have successfully migrated to the lending pool': '您已成功迁移到借贷池',
        'The node has not joined the StakingPool or has already been upgraded': '节点没有入职StakingPool,或者已经升级',
        'poolInfoTitle': '存储提供商借贷池',
        'select address': '选择一个借贷池',
        'select': '选择',
        'node position': '节点余额',
        'node max lending': '节点最大可借',
        'induction': {
            'admin': '管理员',
            'maxLoan': '借款封装额度',
            'maxWith': '借款提现额度',
            'nodeFull': '容量已经达到上限！',
            'connect admin Wallet' : '连接管理员钱包',
            'Pool Will Receive' : '借款池将获得'
        },
        'Leverage Used': '杠杆使用率',
        'poolInfoTip': {
            'poolAdmin': '借贷池管理员仅具有委托给池子的节点的运营权，节点的所有权仍然由节点原来的Owner持有。',
            'position': '总仓位价值是指借贷池中所有节点持有的资产总价值，包括可用余额、初始质押和锁定奖励。',
            'equity': '总权益价值是指借贷池中所有节点的资产净值。 ',
            'debt': '总债务价值是指借贷池中所有节点的在协议中的债务总额，包括存储提供者需要偿还的贷款总额，是浮动债务和稳定债务的总和。其中包含未偿还的借款提现部分。',
            'borrowLoan': '未偿还的借款提现是指节点通过借款用于资金周转的债务额度。',
            'withLoan': '专门为SPs的扇区封装提供的借款杠杆，持有一份仓位价值，可以获得两倍仓位价值的借款。。',
            'withLoan2': '专门为SPs的扇区封装提供的借款杠杆，持有一份仓位价值，可以获得三倍仓位价值的借款。。',
            'borrowLoanLeverage': '为SPs借款用于资金周转的借款杠杆，协议准许SPs提现节点仓位一半的价值。',
            'debtRate': '负债率是衡量借款池的总债务与其仓位价值的比值。 它有助于评估债务风险水平，并确定其财务稳定性和偿还能力。',
            'litRate': '清算阈值是指的借贷池的债务权益比的阀值。只有节点发生故障后，仓位价值大量削减才会导致负债率超过该阈值，将进行清算以保证协议的稳定性。',
            'only': '这是表示该借贷池是否准许老节点入职。STFIL协议定义的老节点是指节点仓位价值大于1FIL',
            'poolAddress': '合约地址',
        },
        'not find node migrator': '没有找到符合条件的节点',
        'Migrator': '迁移',
        'node must be less than': '新节点的仓位价值必须小于1FIL',
        'transferOwnerTile': '转让借贷池管理员',
        'poolAddressErr': '请输入正确的合约地址',
        'newPoolAdmin': '新管理员地址',
        'newPoolAdminTip': '请输入新管理员地址',
        'newPoolAdminErrTip': '请输入正确的地址',
        'transferOwnerOk': '您已成功转让借贷池。',
        'changeOwnerOk': '您已成功修改节点Owner。',
    },
    'home title': 'Staking',
    'home tips': '质押FIL或接收stFIL。',
    'home': '首页',
    'faq': '问题',
    'max': '最大',
    'you-will-receive': '您将收到',
    'exchange-rate': '汇率',
    'transaction-cost': '交易成本',
    'reward-fee': '奖励费',
    'reward-fee-tip': '建立在 Filecoin 之上的流动性权益协议允许用户在不锁定资产或维护存储提供商基础设施的情况下获得大笔奖励。',
    'unstake-tip-befor': '默认的 stFIL 取消质押期需要大约 3-4 天（80 个纪元）来处理。之后，您可以在',
    'unstake-tip-after': '选项卡中领取您的奖励',
    'claim-tip-befor': '处理提款请求后，您将能够领取奖励。要取消质押您的金额，请转到',
    'claim-tip-after': '选项卡',
    'connect-wallet': '连接钱包',
    'statistics': '统计数据',
    'annual-percentage-rate': '年利率',
    'total-staked-with': '与 STFIL 的总质押',
    'stakers': '质押数',
    'market-cap': 'stFIL 市值',
    'total-claimable-rewards': '总可领取奖励',
    'pending-amount': '待定金额',
    'faq_1': "最低存款是多少？",
    'faq_1_content': "STFIL 协议允许任何人通过低至 0.1 FIL 的存款获得奖励。允许持有者（Asset）将他们的 FIL资产存入流动质押的智能合约，来铸造 1 比 1 的 质押代币stFIL，并且不用被锁定在我们这里，无论在何处获得，stFIL 都会累积质押奖励。",
    'faq_2': "最高存款是多少？",
    'faq_2_content': "随心所欲，无限制。",
    'faq_3': "什么是 Liquid Staking？",
    'faq_3_content': "流动性质押协议允许用户在不锁定资产或维护质押基础设施的情况下获得质押奖励。这些协议的用户可以存入质押代币并获得可交易的流动性代币作为回报。DAO 控制的智能合约然后与 DAO 挑选的存储提供商（SP）进行代币质押。用户存入的资金由 DAO 控制，存储提供商永远无法直接访问用户的资产。",
    'faq_4': "我该如何解除质押？",
    'faq_4_content': "STFIL 协议始终保持stFIL和FIL是1比1的关系，金库将设置存款准备金，只要协议中有可用的流动性来覆盖交换，您就可以将它们交易回协议以获得FIL。",
    'faq_5': "质押APR如何计算？",
    'faq_5_content': "APR 以 stFIL 而非美元计价，并且不是保证或承诺的回报或利润。STFIL 协议将用户质押的资金提供给精心挑选的存储提供商进行代币质押，获得的收益重新铸造stFIL并分给用户，用户可以在存款后的 24 小时内收到质押奖励，而无需等待验证器激活。给用户呈现的是最近7天的平均年化收益。",
    'console-tip': 'Current balance may differ from last balance in the table due to rounding.',
    'lianjieqianbao': '连接钱包',
    'launch': {
        'home': '首页',
        'title': 'STFIL 代币发布',
        'title2': '首次流动性发行',
        'stage': '阶段',
        'the-offer': '报价',
        'total': '总计',
        'slots': '插槽',
        'price-per-slot': '每个插槽的价格',
        'start-time': '开始时间',
        'end-time': '结束时间',
        'More-about-the-token-mics': '有关代币的更多信息',
        'the-return': '回报',
        'fdo-token': 'FDO 代币',
        'founder-nft': '创始人NFT',
        'claim-for-free-when-ready': '准备好后免费领取',
        'more-about-this': '关于此的更多信息',
        'sale-stage': '销售阶段',
        'whitelisted': '白名单',
        'slots-left': '插槽剩余',
        'tip':'开始16小时后，代币销售将进入公开销售阶段，如果没有售罄。一个地址最多可购买1个ILO插槽。',
        'faq': '常见问题',
        'WHITELISTED': '白名单',
        'address-filter': '地址过滤器',
        'your-address': '您的地址',
        'tip2': '输入您的地址以检查您是否包括在白名单中。',
        "can-find-the-address": "找不到地址"
    },
    'vote': {
        'lianjieqianbao': '连接钱包',
        'connecting': '连接中',
        'toupiao': '投票',
        'title': '对 STFIL 的未来发表看法',
        'proposal': '提案',
        'hexin': '核心',
        'shequ': '社区',
        'suoyou': '所有',
        'lijietoupiao': '立即投票',
        'jijiangkaishi': '即将开始',
        'yiguanbi': '已关闭',
        'chakanxiangqing': '查看详情',
        'fanhui': '返回',
        'xinxi': '信息',
        'zhuangtai': '状态',
        'biaoshifu': '标识符',
        'gaodu': '高度',
        'toupiaozhidu': '投票制度',
        'chaungjianshijian': '创建时间',
        'kaishishijian': '开始时间',
        'jieshushijian': '结束日期',
        'dangqianjieguo': '当前结果',
        'zhangtoupiao': '张投票',
        'toupiaoshu': '投票数',
        'zangwutoupiaoshuju':'暂无投票数据',
        'touchunindepiao': '投出您的票',
        'pingzheng': '凭证',
        'quxiao': '取消',
        'zaiyanzheng': '在 Signator.io 上验证',
    }
}
