import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.typed-array.to-reversed.js";
import "core-js/modules/esnext.typed-array.to-sorted.js";
import "core-js/modules/esnext.typed-array.with.js";
import { ElIcon } from 'element-plus';
import { CircleCheckFilled, CopyDocument } from '@element-plus/icons-vue';
import AdCard2 from '@/components/tip/AdCard2';
import { readContract } from '@wagmi/core';
import StakingPoolABI from "@/utils/abi/StakingPool.json";
import { contracts } from "@/utils/contracts/config";
import { BigNumber } from "ethers";
import { loutsRpc, retryFun, walletProvider } from "@/utils/LoutsRpc";
import { ChainHead, ChainReadObj, StateAccountKey, StateGetActor, StateMinerInfo } from "@/utils/FilecoinApi";
import { decode as dagCborDecode } from "@ipld/dag-cbor";
import { newAddressFromBytes } from "@/utils/contracts/singer/filecoin_utils";
import { encode, encode as addressToString, newDelegatedEthAddress } from "@glif/filecoin-address";
import Singer from '@/components/induction/singer/Singer';
import { blockU, dateFormatAll, getPrefix, NotificationErr } from "@/utils/common";
import Tip from '@/components/tip/Tip';
import Clipboard from "clipboard";
import EvmErrTip from '@/components/header/EvmErrTip';
export default {
  name: "ChangeWorkerComponent",
  components: {
    CopyDocument,
    ElIcon,
    AdCard2,
    Singer,
    Tip,
    EvmErrTip
  },
  data() {
    return {
      radio1: 'Node Owner',
      CircleCheckFilled,
      active: 1,
      sendProposal: false,
      approvedProposalRes: false,
      inputs: {
        input1: 'f01234',
        input2: 0,
        input3: 10101
      },
      radio2: 'Content',
      singerTables: [],
      contractParams: [],
      contractMethod: 'approveOperator',
      changeAddress: '',
      nodeRole: 'Owner',
      singerMultiple: false,
      newWorker: '',
      affirmWorker: false,
      showNewWorker: false,
      showCurrentWorker: false,
      loading: false,
      isHaveActorId: true,
      isCopy: false,
      waitNewOwner: '',
      workerChangeEpoch: -1,
      needConfirmChangeWorkerAddress: false,
      isNewWorkerErr: false,
      newWorkerErrTips: '',
      initLoading: true
    };
  },
  props: {
    nodeId: {
      type: String,
      default: ''
    }
  },
  watch: {
    nodeInfo() {
      if (this.nodeInfo.worker !== '') {
        this.showCurrentWorker = true;
      } else {
        this.showCurrentWorker = false;
      }
      if (!this.showCurrentWorker) {
        this.showNewWorker = true;
      }
      this.showNewWorker = false;
      this.initLoading = false;
    }
  },
  created() {
    this.workerInfo();
  },
  methods: {
    async init() {
      try {
        await this.getNode();
        this.initActorState();
      } catch (e) {
        NotificationErr("Network request error, please try again!");
      }
    },
    async workerInfo() {
      if (this.nodeId) {
        let stateMinerInfo = await loutsRpc(StateMinerInfo("f0" + this.nodeId));
        let {
          WorkerChangeEpoch,
          NewWorker
        } = stateMinerInfo.result;
        if (WorkerChangeEpoch > 0) {
          this.workerChangeEpoch = WorkerChangeEpoch;
          this.waitNewOwner = NewWorker;
          let time = blockU.getByBlockNumberToTime(this.workerChangeEpoch);
          let nowTime = new Date().getTime();
          if (time < nowTime) {
            this.needConfirmChangeWorkerAddress = true;
          }
        } else {
          this.workerChangeEpoch = -1;
          this.waitNewOwner = '';
        }
      }
    },
    reset() {
      this.showCurrentWorker = true;
      this.affirmWorker = false;
    },
    viewNode() {
      this.$router.push({
        path: '/farm/provider/' + this.nodeId
      });
    },
    getNetworkPrefix() {
      let prefix = '';
      if (this.env === 'test') {
        prefix = 't';
      } else {
        prefix = 'f';
      }
      return prefix;
    },
    initActorState() {
      loutsRpc(StateGetActor(this.nodeInfo.owner)).then(res => {
        loutsRpc(ChainReadObj(res.result.Head)).then(async res => {
          let stateRawbytes = Uint8Array.from(Buffer.from(res.result, 'base64'));
          let msgSigState = dagCborDecode(stateRawbytes);
          // 有更简单的直接调用api接口得方式
          let signersRawbytes = msgSigState[0];
          if (Array.isArray(signersRawbytes)) {
            let head = loutsRpc(ChainHead());
            this.$store.commit('SET_SINGER_MULTIPLE_THRESHOLD', {
              singerMultipleThreshold: signersRawbytes.length
            });
            this.$store.commit('SET_SINGER_MULTIPLE_NUM', {
              singerMultipleNum: msgSigState[1]
            });
            this.singerTables = [];
            for (let i = 0; i < signersRawbytes.length; i++) {
              let signerBytes = signersRawbytes[i];
              let signerIdAddress = newAddressFromBytes(signerBytes);
              let idAddressStr = addressToString(getPrefix()[0], signerIdAddress);
              let nonIdAddressStr = await loutsRpc(StateAccountKey(idAddressStr, head.Cids)); // 测试网用t, 主网用f
              this.singerTables.push({
                isOk: false,
                isCheck: false,
                address: nonIdAddressStr.result,
                id: idAddressStr,
                index: i
              });
            }
            this.singerMultiple = true;
          } else {
            this.singerMultiple = false;
          }
          let abiNewWorker = parseInt(this.newWorker.substring(2));
          setTimeout(() => {
            this.contractParams = [];
            if (this.needConfirmChangeWorkerAddress) {
              this.contractParams.push(this.nodeId);
            } else {
              this.contractParams.push(this.nodeId, abiNewWorker, this.controlAddresses === null ? [] : this.controlAddresses.map(i => parseInt(i.substring(2))));
            }
            this.$refs['singer'].init();
          }, 100);
        });
      });
    },
    async getNode() {
      const data = await readContract({
        address: contracts[this.env].StakingPool.eth,
        abi: StakingPoolABI.abi,
        functionName: 'getNodeData',
        args: [this.nodeId]
      });
      let _nodeInfo = {
        id: '',
        beneficiary: '',
        owner: '',
        ownerAddress: '',
        beneficiaryAddress: ''
      };

      // eslint-disable-next-line no-undef
      let owner = getPrefix() + (BigNumber.from(data[0].owner).toBigInt() & BigInt("0x00000000000000000000000000000FFFFFFFFFFF")).toString();
      _nodeInfo.id = this.nodeId;
      if (this.needConfirmChangeWorkerAddress) {
        this.contractMethod = 'confirmChangeWorkerAddress';
      } else {
        this.contractMethod = 'changeWorkerAddress';
      }
      _nodeInfo.owner = owner;
      this.$store.commit('UPDATE_NODE_INFO', _nodeInfo);
    },
    pushProposalNext() {
      this.active++;
    },
    connectWallet() {
      this.$store.state.refs['iHeader'].connectWallet();
    },
    setCurrentWallet() {
      this.newWorker = this.address;
    },
    copy() {
      let clipboard = new Clipboard('.copy-text', {
        text: () => {
          return this.currentNode.operator;
        }
      });
      clipboard.on('success', () => {
        this.isCopy = true;
        setTimeout(() => {
          this.isCopy = false;
        }, 1500);
        clipboard.destroy();
      });
      clipboard.on('error', () => {
        this.isCopy = false;
        clipboard.destroy();
      });
    },
    sendProposalFun() {
      this.sendProposal = true;
      this.active++;
      this.$nextTick(() => {
        this.$refs['singer2'].init();
      });
    },
    approvedProposal() {
      this.active++;
    },
    async approvedProposal2() {
      this.workerInfo();
      this.approvedProposalRes = true;
    },
    reapprove() {
      this.showNewWorker = true;
    },
    async checkEthAddress() {
      if (!this.newWorker.toLowerCase().startsWith("0x") || this.newWorker.length !== 42) {
        NotificationErr('Invalid Address!');
        return true;
      }
      let f4Address = encode("f", newDelegatedEthAddress(this.newWorker));
      try {
        let upId = await retryFun(() => walletProvider.lookupId(f4Address), 3);
        if (!upId) {
          this.isHaveActorId = false;
          return true;
        }
      } catch (err) {
        this.isHaveActorId = false;
        return true;
      }
      this.isHaveActorId = true;
      return false;
    },
    async checkWorkerAddress() {
      return false;
    },
    async confirmChangeWorker() {
      this.loading = true;
      this.affirmWorker = true;
      await this.init();
      this.loading = false;
    },
    getByBlockNumberToTime(tipset) {
      let time = blockU.getByBlockNumberToTime(tipset);
      return dateFormatAll(time);
    },
    async checkNewWorkerInput() {
      if (this.newWorker.trim() === '') {
        this.isNewWorkerErr = true;
        this.newWorkerErrTips = this.$t('change.Please enter Control ID');
        return false;
      }
      try {
        await walletProvider.getActor(this.newWorker.trim());
      } catch (e) {
        this.isNewWorkerErr = true;
        this.newWorkerErrTips = this.$t('change.workerInputErr');
        return false;
      }
      this.newWorkerErrTips = '';
      this.isNewWorkerErr = false;
      return true;
    },
    clearErr() {
      this.isNewWorkerErr = false;
      this.newWorkerErrTips = '';
    },
    async affirm() {
      let flag = await this.checkNewWorkerInput();
      if (!flag) {
        return;
      }
      if (this.newWorker === '') {
        NotificationErr('Please enter Worker address');
        return;
      }
      this.newWorker = this.newWorker.toString().trim();
      this.loading = true;
      if (await this.checkWorkerAddress()) {
        NotificationErr('Invalid Worker address!');
        this.loading = false;
        return;
      }
      this.affirmWorker = true;
      await this.init();
      this.showCurrentWorker = false;
      this.loading = false;
    }
  },
  computed: {
    env() {
      return this.$store.state.evn;
    },
    currentNode() {
      return this.$store.state.nodeData.currentNode;
    },
    singerMultipleNum() {
      return this.$store.state.node.singerMultipleNum;
    },
    singerMultipleThreshold() {
      return this.$store.state.node.singerMultipleThreshold;
    },
    nodeInfo() {
      return this.$store.state.node.nodeInfo;
    },
    controlAddresses() {
      return this.$store.state.node.nodeInfo.controlAddresses;
    },
    isConnected() {
      return this.$store.state.isConnected;
    },
    address() {
      return this.$store.state.address;
    }
  }
};