const contracts = {
    test: {
        Provider: {
            eth: "0x1d70B197919F26585A9c41a352974544Ec7684fD",
            id: 't019636',
            address: 't410fdvyldf4rt4tfqwu4igrvff2fitwhnbh5sy7jfmi',
        },
        StakingPool: {
            eth: "0xcCa6294F0F3Ec381D0c1feAd22989C485E32854C",
            id: 't019647',
            address: 't410fzstcstyph3bydugb72wsfge4jbpdfbkmex62t6q',
        },
        StakingPoolConfigurator: {
            eth: "0xA3d5d7f80c7458449f33f32121f09aCb9a5617bD",
            id: 't019649',
            address: 't410fupk5p6amormejhzt6mqsd4e2zonfmf55cpuxqpq',
        },
        STFILToken: {
            eth: "0x6dE5b348540D27Fc6f8493De57b4157c45fa5755",
            id: 't019651',
            address: 't410fnxs3gscubut7y34esppfpnavprc7uv2vn3sghai',
        },
        StableDebtToken: {
            eth: "0x84b3b89E3fc473637c209dC6edd91cc20560C9e0",
            id: 't019656',
            address: 't410fqsz3rhr7yrzwg7batxdo3wi4yicwbspaagyzyky',
        },
        VariableDebtToken: {
            eth: "0xF35bD27021bF5f6B5E9a58d066660C7Dca3D2978",
            id: 't019659',
            address: 't410f6nn5e4bbx5pwwxu2ldigmzqmpxfd2klyysgipcy',
        },
        InterestRateStrategy: {
            eth: "0xb8E05D609F9a1fc7A3Fd9471420D0cf2e845D843",
            id: 't047268',
            address: 't410fxdqf2ye7tip4pi75sryuedim6luelwcdhcwea4y',
        },
        StorageProviderLendingPoolFactory: {
            eth: "0x48a1C8C45F960d750d558233166Abe71D08f4F94",
            id: 't041496',
            address: 't410fjcq4rrc7sygxkdkvqizrm2v6ohii6t4uahqc74y',
        },
        // 金库地址
        TREASURY: {
            eth: "0x59714778b3EacAf57747c3A53EFdc6Cbfb4D4C35",
            id: '',
            address: 't410flfyuo6ft5lfpk52hyost57ogzp5u2tbvuzzzrnq',
        },
        FilForwarderAddress: {
            eth: '0x2B3ef6906429b580b7b2080de5CA893BC282c225',
            id: 't06257',
            address: 't410ffm7pnedefg2ybn5sbag6lsujhpbifqrfspbcqna'
        },
        WSTFILToken: {
            eth: '0xfE5E32858014cD7470f8ffA40deb526448F76A6f',
            id: 't058171',
            address: 't410f7zpdfbmactgxi4hy76sa322smrepo2tpnkdsyji'
        }
    },
    main: {
        Provider: {
            eth: "0xc55e40977A782A0EA221812b9F01203effAC71a1",
            id: 'f02117127',
            address: 'f410fyvpebf32pava5irbqevz6ajah372y4nb356ceva',
        },
        StakingPool: {
            eth: "0xC8E4EF1148D11F8C557f677eE3C73901CD796Bf6",
            id: 'f02117153',
            address: 'f410fzdso6eki2epyyvl7m57ohrzzahgxs27wbwomb2i',
        },
        StakingPoolConfigurator: {
            eth: "0x81cB4889fca11270eBb026F5c24fd65B1D5C22df",
            id: 'f02117156',
            address: 'f410fqhfurcp4uejhb25qe324et6wlmovyiw7z3den7q',
        },
        STFILToken: {
            eth: "0x3C3501E6c353DbaEDDFA90376975Ce7aCe4Ac7a8",
            id: 'f02117164',
            address: 'f410fhq2qdzwdkpn25xp2sa3ws5ooplhevr5ittlp6by',
        },
        StableDebtToken: {
            eth: "0xF440700552A59415d3D14Cf9382C48C86cda8F31",
            id: 'f02117176',
            address: 'f410f6rahabksuwkblu6rjt4tqlcizbwnvdzrm4ifbqa',
        },
        VariableDebtToken: {
            eth: "0x0B24190702018C93E09A55F958D6485Ae31b62A1",
            id: 'f02117182',
            address: 'f410fbmsbsbycaggjhye2kx4vrvsillrrwyvbd5xvogi',
        },
        InterestRateStrategy: {
            eth: "0x44c3958165366e9026b796b6300b1E2D27B8e219",
            id: 'f02848048',
            address: 'f410fitbzlalfgzxjajvxs23dacy6fut3ryqzeeeot3a',
        },
        StorageProviderLendingPoolFactory: {
            eth: "0x3600014960a5Ae4CC340e8e3D58372e03128179F",
            id: 'f02829380',
            address: 'f410fgyaacslauwxezq2a5dr5la3s4aysqf474zkry7a',
        },
        TREASURY: {
            eth: "0x8ca2fb7E86b440163666DC32186E1Dc0b74a505b",
            id: 'f02117099',
            address: 'f410frsrpw7ugwrabmntg3qzbq3q5yc3uuuc32q4ehuy',
        },
        FilForwarderAddress: {
            eth: '0x2B3ef6906429b580b7b2080de5CA893BC282c225',
            id: 'f02064517',
            address: 'f410ffm7pnedefg2ybn5sbag6lsujhpbifqrfspbcqna'
        },
        WSTFILToken: {
            eth: '0x6A3F21d2A92a15752912974BbBD5b1469A72B261',
            id: 'f02863147',
            address: 'f410fni7sduvjfikxkkiss5f3xvnri2nhfmtbujnxg6y'
        }
    }
}

const contractConfig = {

    BENEFICIARY_MAX_LEVERAGE: 15000,
    BENEFICIARY_LIQUIDATION_THRESHOLD: 4100,
    OWNER_DEFAULT_MAX_LEVERAGE: 20000,
    OWNER_LIQUIDATION_THRESHOLD: 6250,
}

const PERCENTAGE_FACTOR = 10000


export {contracts, contractConfig, PERCENTAGE_FACTOR}
