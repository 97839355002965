import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.typed-array.to-reversed.js";
import "core-js/modules/esnext.typed-array.to-sorted.js";
import "core-js/modules/esnext.typed-array.with.js";
/* eslint-disable */
import RadioBtn from '@/components/RadioBtn';
import { Aim, CloseBold, CopyDocument, SuccessFilled } from '@element-plus/icons-vue';
import { BigNumber, ethers } from "ethers";
import StakingPoolABI from "@/utils/abi/StakingPool.json";
import { base64Encode, hexDecode } from "@/utils/contracts/singer/utils";
import { encode as dagCborEncode } from "@ipld/dag-cbor";
import { addressToBytes, bigNumberToBytes } from "@/utils/contracts/singer/filecoin_utils";
import { newFromString as newAddressFromString } from "@glif/filecoin-address";
import { contracts } from "@/utils/contracts/config";
import { CreateMessage, CreateSinger, PushProposal, PushProposalValue, WaitProposal } from "@/utils/contracts/singer/singer";
import { isEmpty } from "@/utils/model";
import { loutsRpc } from "@/utils/LoutsRpc";
import { ChainHead, StateAccountKey, StateGetActor, StateReadState } from "@/utils/FilecoinApi";
import { getLocal, gotoAddress, gotoHash, NotificationErr } from "@/utils/common";
import Clipboard from "clipboard";
import QRCode from 'qrcodejs2';
import { QrcodeStream } from "vue3-qrcode-reader";
import Ledger from './ledger/Ledger';
import { iWriteContract } from "@/utils/contracts/opertion";
import ScreenWithAutMixin from "@/utils/mixin/ScreenWithAutMixin";
export default {
  name: "SingerV2",
  components: {
    RadioBtn,
    CopyDocument,
    SuccessFilled,
    Aim,
    QrcodeStream,
    CloseBold,
    Ledger
  },
  props: {
    waitTime: {
      type: Number,
      default: 30000
    },
    nodeRole: {
      type: String,
      default: 'Owner'
    },
    contractMethod: {
      type: String,
      default: ''
    },
    contractParams: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: []
    },
    btnProposal: {
      type: String,
      default: 'Send Proposal'
    },
    fromAddress: {
      type: String,
      default: ''
    },
    toEthAddress: {
      type: String,
      default: ''
    },
    toF0Address: {
      type: String,
      default: ''
    },
    changeAddress: {
      type: String,
      default: ''
    },
    isBeneficiaryUndelegate: {
      type: Boolean,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: false
    },
    contractABI: {
      type: Object,
      default: () => {}
    },
    isCallEvm: {
      type: Boolean,
      default: false
    }
  },
  mixins: [ScreenWithAutMixin],
  created() {
    this.initRadioOptions();
  },
  data() {
    return {
      loading: false,
      singerMultiple: false,
      singerTables: [],
      radio3: 'DATA',
      radio4: 'Lotus',
      tabOptions1: [],
      confirmSingerFlag: false,
      singMessageLoading: true,
      isCopy: false,
      singerTablesCheckIndex: -1,
      singerMsg: {
        "From": '',
        "To": '',
        'GasLimit': 0,
        'GasFeeCap': '0',
        'GasPremium': '0',
        "Method": 2,
        "Params": '',
        'Version': 0,
        'Nonce': 0,
        'Value': '0'
      },
      singerThresholdNumber: 0,
      inputSingerResult: '',
      finish: false,
      bytesToBeSign: '',
      parentBaseFee: 100,
      openScanQr: false,
      cameraOpen: false,
      qrInputIsScan: true,
      qrSingerInput: '',
      signMessageMaxWidth: 430,
      pushResultCid: '',
      btnText: '',
      hash: ''
    };
  },
  watch: {
    radio4() {
      if (this.radio4 === 'STFIL Wallet') {
        this.$nextTick(() => {
          this.initBytesToBeSignQr();
        });
      } else {
        if (!this.loading) {
          this.openScanQr = false;
          this.cameraOpen = false;
        }
      }
    },
    '$i18n.locale'() {
      this.initRadioOptions();
    },
    address(newValue, oldValue) {
      if (this.isCallEvm && !!oldValue) {
        this.init();
      }
    },
    loading() {
      this.$emit('loading', this.loading);
    },
    singMessageLoading() {
      this.$emit('loading', this.singMessageLoading);
    }
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        this.initSignMessageMaxWidth();
      })();
    };
    this.initSignMessageMaxWidth();
  },
  methods: {
    initRadioOptions() {
      this.tabOptions1 = [{
        label: 'Lotus',
        value: 'Lotus'
      }, {
        label: 'Ledger',
        value: 'Ledger'
      }, {
        label: this.$t('Onboarding.STFILWallet'),
        value: 'STFIL Wallet'
      }, {
        label: "Venus Wallet",
        value: 'Venus Wallet'
      }];
    },
    isHavePushResultCid() {
      return this.pushResultCid !== '' && this.pushResultCid['/'];
    },
    initSignMessageMaxWidth() {
      this.$nextTick(() => {
        setTimeout(() => {
          if (!this.$refs['signContent']) {
            return;
          }
          let width = this.$refs['signContent'].offsetWidth;
          if (width < 400) {
            this.signMessageMaxWidth = Math.floor(width * 0.9);
          } else {
            this.signMessageMaxWidth = Math.floor(width * 0.56);
          }
        }, 200);
      });
    },
    gotoAddress,
    gotoHash,
    isEmpty,
    nextQrCode() {
      this.openScanQr = true;
    },
    connectWallet() {
      this.$store.state.refs['iHeader'].connectWallet();
    },
    callEvmMethod() {
      this.loading = true;
      this.btnText = this.$t('common.Wait for transaction confirmation');
      iWriteContract({
        address: this.toEthAddress,
        f0address: this.toF0Address,
        abi: this.contractABI.abi,
        functionName: this.contractMethod,
        args: this.contractParams
      }, res => {
        let {
          hash
        } = res;
        this.btnText = this.$t('common.Waiting for winding');
        this.hash = hash;
      }).then(() => {
        this.btnText = this.$t('common.Waiting for msg');

        // 这边等待一会。等待接口同步
        setTimeout(() => {
          this.loading = false;
          this.btnText = '';
          this.$emit('next');
          this.finish = true;
        }, this.waitTime);
      }).catch(err => {
        NotificationErr(err.message, "callEvmMethod" + ` ${getLocal('connectType')}`);
        this.loading = false;
        this.btnText = '';
        this.hash = '';
        this.clearContent();
      });
    },
    clearContent() {
      this.btnText = '';
      this.hash = '';
    },
    pushLedgerSinger(message) {
      this.loading = true;
      PushProposalValue(message).then(async res => {
        if (!res.result) {
          if (res.error) {
            NotificationErr(res.error.message, "pushLedgerSinger");
          } else {
            NotificationErr(this.$t('common.Invalid Signature'));
          }
          this.loading = false;
          return;
        }
        this.pushResultCid = res.result;
        this.innerWaitProposal(res.result);
      }).catch(err => {
        console.log('PushProposal', err);
        NotificationErr(this.$t('common.Invalid Signature'), err.message);
        this.loading = false;
      });
    },
    pushSinger() {
      this.loading = true;
      let resData = '';
      try {
        resData = JSON.parse(this.qrSingerInput);
      } catch (e) {
        NotificationErr(this.$t('common.Invalid Signature'));
        this.loading = false;
        return;
      }
      PushProposalValue(resData).then(async res => {
        if (!res.result) {
          NotificationErr(this.$t('common.Invalid Signature'));
          this.loading = false;
          return;
        }
        this.pushResultCid = res.result;
        this.innerWaitProposal(res.result);
        this.openScanQr = false;
      }).catch(err => {
        console.log('PushProposal', err);
        NotificationErr(this.$t('common.Invalid Signature'), err.message);
        this.loading = false;
      });
    },
    textImport() {
      this.qrInputIsScan = false;
    },
    scanQr() {
      this.qrInputIsScan = true;
    },
    onDecode(result) {
      if (isEmpty(result)) {
        return;
      }
      this.loading = true;
      let resData = JSON.parse(result);
      PushProposalValue(resData).then(async res => {
        if (!res.result) {
          NotificationErr(this.$t('common.Invalid Signature'));
          this.loading = false;
          return;
        }
        this.pushResultCid = res.result;
        this.innerWaitProposal(res.result);
        this.openScanQr = false;
      }).catch(err => {
        console.log('PushProposal', err);
        NotificationErr(this.$t('common.Invalid Signature'));
        this.loading = false;
      });
    },
    async onInit(promise) {
      this.cameraOpen = true;
      try {
        await promise;
        this.cameraOpen = false;
      } catch (error) {
        this.cameraOpen = false;
        if (error.name === 'NotAllowedError') {
          NotificationErr('you need to grant camera access permission');
        } else if (error.name === 'NotFoundError') {
          NotificationErr('no camera on this device');
        } else if (error.name === 'NotSupportedError') {
          NotificationErr('secure context required (HTTPS, localhost)');
        } else if (error.name === 'NotReadableError') {
          NotificationErr('is the camera already in use');
        } else if (error.name === 'OverconstrainedError') {
          NotificationErr('installed cameras are not suitable');
        } else if (error.name === 'StreamApiNotSupportedError') {
          NotificationErr('Stream API is not supported in this browser');
        } else if (error.name === 'InsecureContextError') {
          NotificationErr('Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP');
        } else {
          NotificationErr('Camera error (${error.name})');
        }
      }
    },
    initBytesToBeSignQr() {
      if (isEmpty(this.bytesToBeSign)) {
        return;
      }
      let _singerMsg = Object.assign({}, this.singerMsg);
      delete _singerMsg['CID'];
      new QRCode(this.$refs.qrCodeUrl, {
        text: JSON.stringify(_singerMsg),
        // 需要转换为二维码的内容
        width: 200,
        height: 200,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.Q
      });
    },
    // 检查多签地址
    async initSingerTables() {
      // 判断是否是f4地址
      let stateGetActor = await loutsRpc(StateGetActor(this.fromAddress));
      if (stateGetActor.result.Address && stateGetActor.result.Address.toString().charAt(1) === '4') {
        NotificationErr(this.$t('Onboarding.F4 address does not support entry'));
        this.$emit('err');
        return;
      }
      // 获取多签地址
      let stateReadState = await loutsRpc(StateReadState(this.fromAddress));
      let stateReadStateRes = stateReadState.result;
      if (stateReadStateRes.State.Signers && Array.isArray(stateReadStateRes.State.Signers)) {
        this.singerMultiple = true;
        this.$store.commit('SET_SINGER_MULTIPLE_THRESHOLD', {
          singerMultipleThreshold: stateReadStateRes.State.Signers.length
        });
        this.$store.commit('SET_SINGER_MULTIPLE_NUM', {
          singerMultipleNum: stateReadStateRes.State.NumApprovalsThreshold
        });
        this.$store.commit('SET_SINGER_MULTIPLE', {
          singerMultiple: true
        });
        this.singerTables = [];
        for (let i = 0; i < stateReadStateRes.State.Signers.length; i++) {
          let f0Address = stateReadStateRes.State.Signers[i];
          let _address = await loutsRpc(StateAccountKey(f0Address, null)); // 测试网用t, 主网用f
          this.singerTables.push({
            isOk: false,
            isCheck: false,
            address: _address.result,
            id: f0Address,
            index: i
          });
        }
      } else {
        this.$store.commit('SET_SINGER_MULTIPLE', {
          singerMultiple: false
        });
        this.singerMultiple = false;
      }
    },
    async init() {
      this.$emit('loading', this.singMessageLoading);
      if (this.isCallEvm) {
        this.singMessageLoading = false;
        return;
      }
      this.singMessageLoading = true;
      this.finish = false;
      await this.initSingerTables();
      await this.initBaseFee();
      if (!this.singerMultiple) {
        try {
          await this.singleSignatureInit();
        } catch (e) {
          console.error(e);
          NotificationErr('Network request error, please try again!');
          this.$emit('initErr');
        }
      }
      this.singMessageLoading = false;
      this.initSignMessageMaxWidth();
    },
    // 初始化签名信息
    async singleSignatureInit() {
      if (this.nodeRole === 'Owner') {
        if (isEmpty(this.contractMethod)) {
          this.bytesToBeSign = await this.changeOwner();
        } else {
          this.bytesToBeSign = await this.approved();
        }
      } else {
        if (isEmpty(this.contractMethod)) {
          this.bytesToBeSign = await this.changeBeneficiary();
        } else {
          this.bytesToBeSign = await this.approved();
        }
      }
    },
    copy() {
      let clipboard = new Clipboard('.copy-text', {
        text: () => {
          return `lotus wallet sign ${this.singerMsg.From} ${this.bytesToBeSign}`;
        }
      });
      clipboard.on('success', () => {
        this.isCopy = true;
        setTimeout(() => {
          this.isCopy = false;
        }, 1500);
        clipboard.destroy();
      });
      clipboard.on('error', () => {
        this.isCopy = false;
        clipboard.destroy();
      });
    },
    async initBaseFee() {
      let nodeRoleAddress = await loutsRpc(ChainHead());
      let head = nodeRoleAddress.result;
      let blocks = head['Blocks'];
      if (blocks.length > 0) {
        let min = blocks[0];
        this.parentBaseFee = parseInt(min.ParentBaseFee === undefined ? 100 : min.ParentBaseFee);
      }
    },
    async confirmSinger() {
      if (this.singerTablesCheckIndex < 0) {
        NotificationErr('Please select an address to initiate this proposal!');
        return;
      }
      if (this.singerTables[this.singerTablesCheckIndex].isOk) {
        NotificationErr('Please select an address to initiate this proposal!');
        return;
      }
      this.singMessageLoading = true;
      if (this.singerTablesCheckIndex >= 0) {
        try {
          await this.multipleCreateSing();
        } catch (e) {
          NotificationErr('Network request error, please try again!');
          this.singMessageLoading = false;
          return;
        }
      }
      this.singMessageLoading = false;
      this.confirmSingerFlag = true;
      this.initSignMessageMaxWidth();
    },
    // 初始化多签签名信息
    async multipleCreateSing() {
      if (this.nodeRole === 'Owner') {
        if (isEmpty(this.contractMethod)) {
          this.bytesToBeSign = await this.changeMultipleOwner();
        } else {
          this.bytesToBeSign = await this.approvedMultiple();
        }
      } else {
        if (isEmpty(this.contractMethod)) {
          this.bytesToBeSign = await this.changeMultipleBeneficiary();
        } else {
          this.bytesToBeSign = await this.approvedMultiple();
        }
      }
    },
    async changeOwner() {
      const Interface = new ethers.utils.Interface(this.contractABI.abi);
      let params = base64Encode(dagCborEncode(addressToBytes(newAddressFromString(this.changeAddress))));
      this.singerMsg = await CreateMessage(this.fromAddress, this.toF0Address, '0', 23, params);
      return await CreateSinger(this.singerMsg, Interface);
    },
    async changeMultipleOwner() {
      const Interface = new ethers.utils.Interface(this.contractABI.abi);
      if (this.singerThresholdNumber === 0) {
        let params = base64Encode(dagCborEncode([addressToBytes(newAddressFromString(this.toF0Address)), bigNumberToBytes(ethers.BigNumber.from(0)), 23,
        //changeOwner 固定值
        dagCborEncode(addressToBytes(newAddressFromString(this.changeAddress)))]));
        this.singerMsg = await CreateMessage(this.singerTables[this.singerTablesCheckIndex].address, this.fromAddress, '0', 2, params);
      } else {
        let params = Buffer.from(dagCborEncode([this.transactionId, new Uint8Array()])).toString('base64');
        this.singerMsg = await CreateMessage(this.singerTables[this.singerTablesCheckIndex].address, this.fromAddress, '0', 3, params);
      }
      return await CreateSinger(this.singerMsg, Interface);
    },
    async changeMultipleBeneficiary() {
      const Interface = new ethers.utils.Interface(this.contractABI.abi);
      if (this.singerThresholdNumber === 0) {
        // eslint-disable-next-line no-undef
        let params = base64Encode(dagCborEncode([addressToBytes(newAddressFromString(this.toF0Address)), bigNumberToBytes(ethers.BigNumber.from(0)), 30,
        //changeBe 固定值
        // eslint-disable-next-line no-undef
        dagCborEncode([addressToBytes(newAddressFromString(this.changeAddress)), bigNumberToBytes(BigNumber.from(this.isBeneficiaryUndelegate ? '0' : '340282366920938463463374607431768211455')), BigInt(this.isBeneficiaryUndelegate ? '0' : '9223372036854775807')])]));
        this.singerMsg = await CreateMessage(this.singerTables[this.singerTablesCheckIndex].address, this.fromAddress, '0', 2, params);
      } else {
        let params = Buffer.from(dagCborEncode([this.transactionId, new Uint8Array()])).toString('base64');
        this.singerMsg = await CreateMessage(this.singerTables[this.singerTablesCheckIndex].address, this.fromAddress, '0', 3, params);
      }
      return await CreateSinger(this.singerMsg, Interface);
    },
    async changeBeneficiary() {
      const Interface = new ethers.utils.Interface(this.contractABI.abi);
      // eslint-disable-next-line no-undef
      let params = base64Encode(dagCborEncode([addressToBytes(newAddressFromString(this.changeAddress)), bigNumberToBytes(BigNumber.from(this.isBeneficiaryUndelegate ? '0' : '340282366920938463463374607431768211455')), BigInt(this.isBeneficiaryUndelegate ? '0' : '9223372036854775807')]));
      this.singerMsg = await CreateMessage(this.fromAddress, this.toF0Address, '0', 30, params);
      return await CreateSinger(this.singerMsg, Interface);
    },
    async pushProposal() {
      if (isEmpty(this.inputSingerResult)) {
        return;
      }
      this.loading = true;
      PushProposal(this.singerMsg, this.inputSingerResult).then(async res => {
        if (!res.result || res.error) {
          NotificationErr(this.$t('common.Invalid Signature'));
          this.loading = false;
          return;
        }
        this.pushResultCid = res.result;
        console.log('res.result', res.result);
        this.innerWaitProposal(res.result);
      }).catch(err => {
        NotificationErr(this.$t('common.Invalid Signature'));
        this.loading = false;
        console.log('PushProposal', err);
      });
    },
    async tryAgainMessage() {
      this.loading = true;
      try {
        let data = await WaitProposal(this.pushResultCid);
        this.resProposal(data);
      } catch (e) {
        NotificationErr(e.message);
        this.loading = false;
        return;
      }
      this.loading = false;
    },
    innerWaitProposal(result) {
      setTimeout(async () => {
        let data = {};
        try {
          data = await WaitProposal(result);
        } catch (e) {
          NotificationErr(e.message);
          this.loading = false;
          return;
        }
        this.resProposal(data);
      }, 10000);
    },
    resProposal(data) {
      if (data.error !== undefined) {
        NotificationErr(data.error.message);
        this.loading = false;
        return;
      }
      if (this.singerMultiple) {
        this.singerTables[this.singerTablesCheckIndex].isOk = true;
        if (parseInt(data.result.ReturnDec.Code) === 0) {
          if (this.singerThresholdNumber === 0) {
            this.transactionId = data.result.ReturnDec.TxnID;
          }
          this.singerThresholdNumber++;
        }
        if (this.singerMultiple && this.singerThresholdNumber < this.singerMultipleNum) {
          this.loading = false;
          this.confirmSingerReset();
        } else {
          // 这边等待一会。等待接口同步
          setTimeout(() => {
            this.loading = false;
            this.$emit('next');
            this.finish = true;
          }, this.waitTime);
        }
      } else {
        if (data.result.Receipt && data.result.Receipt.ExitCode === 0) {
          setTimeout(() => {
            this.$emit('next');
            this.finish = true;
          }, this.waitTime);
        } else {
          NotificationErr(this.$t('common.Invalid Signature'));
          this.loading = false;
        }
      }
    },
    cellClick(row) {
      let {
        index
      } = row;
      if (this.singerTables[index].isOk) {
        return;
      }
      this.qrSingerInput = '';
      this.pushResultCid = '';
      this.inputSingerResult = '';
      this.singerTablesCheckIndex = index;
    },
    confirmSingerReset() {
      this.radio4 = 'Lotus';
      this.cameraOpen = false;
      this.openScanQr = false;
      this.confirmSingerFlag = false;
    },
    closeCameraOpen() {
      this.cameraOpen = false;
      this.openScanQr = false;
    },
    async approved() {
      const Interface = new ethers.utils.Interface(this.contractABI.abi);
      const invokeContractMethod = 3844450837; // 固定值
      let params = base64Encode(dagCborEncode(hexDecode(Interface.encodeFunctionData(this.contractMethod, this.contractParams))));
      this.singerMsg = await CreateMessage(this.isCallEvm ? this.address : this.fromAddress, this.toF0Address, '0', invokeContractMethod, params);
      return await CreateSinger(this.singerMsg, Interface);
    },
    async approvedMultiple() {
      const Interface = new ethers.utils.Interface(this.contractABI.abi);
      const invokeContractMethod = 3844450837; // 固定值

      if (this.singerThresholdNumber === 0) {
        let depositCalldata = hexDecode(Interface.encodeFunctionData(this.contractMethod, this.contractParams));

        // 合约的传入参数
        let params = [addressToBytes(newAddressFromString(this.toF0Address)), bigNumberToBytes(ethers.BigNumber.from(0)), invokeContractMethod,
        //changeOwner 固定值
        dagCborEncode(depositCalldata)];
        params = base64Encode(dagCborEncode(params));
        this.singerMsg = await CreateMessage(this.singerTables[this.singerTablesCheckIndex].address, this.fromAddress, '0', 2, params);
      } else {
        let params = Buffer.from(dagCborEncode([this.transactionId, new Uint8Array()])).toString('base64');
        this.singerMsg = await CreateMessage(this.singerTables[this.singerTablesCheckIndex].address, this.fromAddress, '0', 3, params);
      }
      return await CreateSinger(this.singerMsg, Interface);
    }
  },
  computed: {
    env() {
      return this.$store.state.evn;
    },
    singerMultipleThreshold() {
      return this.$store.state.node.singerMultipleThreshold;
    },
    singerMultipleNum() {
      return this.$store.state.node.singerMultipleNum;
    },
    fee() {
      return (this.singerMsg.GasLimit + parseInt(this.singerMsg.GasPremium)) * this.parentBaseFee;
    },
    docsUrlPrefix() {
      return `https://docs.stfil.io/${this.$i18n.locale === "zh" ? 'v/cn/' : ''}`;
    },
    notInstalled() {
      return this.$i18n.locale === "zh" ? "https://docs.fivetoken.co/cn/" : "https://docs.fivetoken.co/";
    },
    address() {
      return this.$store.state.address;
    },
    isConnected() {
      return this.$store.state.isConnected;
    },
    isConnecting() {
      return this.$store.state.isConnecting;
    }
  }
};