import "core-js/modules/es.array.push.js";
import { ElIcon } from 'element-plus';
import { CircleCheckFilled, CopyDocument } from '@element-plus/icons-vue';
import AdCard2 from '@/components/tip/AdCard2';
import { readContract } from '@wagmi/core';
import { loutsRpc, retryFun, walletProvider } from "@/utils/LoutsRpc";
import { StateMinerInfo, StateReadState } from "@/utils/FilecoinApi";
import { encode, newDelegatedEthAddress } from "@glif/filecoin-address";
import { blockU, dateFormatAll, NotificationErr } from "@/utils/common";
import Tip from '@/components/tip/Tip';
import Clipboard from "clipboard";
import EvmErrTip from '@/components/header/EvmErrTip';
import SingerV2 from '@/components/induction/singer/SingerV2';
import StorageProviderLendingPoolABI from "@/utils/abi/StorageProviderLendingPool.json";
import StorageProviderLendingPool from "@/utils/abi/StorageProviderLendingPool.json";
import { getF4AddressByEth } from "@/utils/contracts/singer/filecoin_utils";
import store from "@/store";
import { BigNumber } from "ethers";
import { toBigInt } from "@/utils/NumU";
export default {
  name: "PoolChangeWorkerComponent",
  components: {
    CopyDocument,
    ElIcon,
    AdCard2,
    SingerV2,
    Tip,
    EvmErrTip
  },
  data() {
    return {
      radio1: 'Node Owner',
      CircleCheckFilled,
      active: 1,
      sendProposal: false,
      approvedProposalRes: false,
      inputs: {
        input1: 'f01234',
        input2: 0,
        input3: 10101
      },
      radio2: 'Content',
      singerTables: [],
      contractParams: [],
      contractMethod: 'approveOperator',
      contractABI: StorageProviderLendingPoolABI,
      changeAddress: '',
      nodeRole: 'Owner',
      singerMultiple: false,
      newWorker: '',
      affirmWorker: false,
      showNewWorker: false,
      showCurrentWorker: false,
      loading: false,
      isHaveActorId: true,
      isCopy: false,
      waitNewOwner: '',
      workerChangeEpoch: -1,
      needConfirmChangeWorkerAddress: false,
      isNewWorkerErr: false,
      newWorkerErrTips: '',
      initLoading: true,
      fromAddress: '',
      toF0Address: '',
      originalOwnerF0: '',
      originalOwner: '',
      poolF4Address: '',
      poolF0Address: ''
    };
  },
  props: {
    nodeId: {
      type: String,
      default: ''
    },
    poolAddress: {
      type: String,
      default: ''
    }
  },
  watch: {
    nodeInfo() {
      if (this.nodeInfo.worker !== '') {
        this.showCurrentWorker = true;
      } else {
        this.showCurrentWorker = false;
      }
      if (!this.showCurrentWorker) {
        this.showNewWorker = true;
      }
      this.showNewWorker = false;
      this.initLoading = false;
    }
  },
  created() {
    this.workerInfo();
  },
  methods: {
    async init() {
      try {
        this.initActorState();
      } catch (e) {
        NotificationErr("Network request error, please try again!");
      }
    },
    async workerInfo() {
      if (this.nodeId) {
        let stateMinerInfo = await loutsRpc(StateMinerInfo("f0" + this.nodeId));
        let {
          WorkerChangeEpoch,
          NewWorker
        } = stateMinerInfo.result;
        if (WorkerChangeEpoch > 0) {
          this.workerChangeEpoch = WorkerChangeEpoch;
          this.waitNewOwner = NewWorker;
          let time = blockU.getByBlockNumberToTime(this.workerChangeEpoch);
          let nowTime = new Date().getTime();
          if (time < nowTime) {
            this.needConfirmChangeWorkerAddress = true;
          }
        } else {
          this.workerChangeEpoch = -1;
          this.waitNewOwner = '';
        }
      }
    },
    reset() {
      this.showCurrentWorker = true;
      this.affirmWorker = false;
    },
    viewNode() {
      this.$router.push({
        path: `/farm/lendingPool/provider/${this.poolAddress}/${this.nodeId}`
      });
    },
    getNetworkPrefix() {
      let prefix = '';
      if (this.env === 'test') {
        prefix = 't';
      } else {
        prefix = 'f';
      }
      return prefix;
    },
    async initOriginalOwner(originalOwnerF0) {
      this.originalOwnerF0 = originalOwnerF0;
      let stateReadState = await loutsRpc(StateReadState(this.originalOwnerF0));
      let stateReadStateRes = stateReadState.result;
      if (stateReadStateRes.State.Signers && Array.isArray(stateReadStateRes.State.Signers)) {
        this.singerMultiple = true;
        this.originalOwner = this.originalOwnerF0;
      } else {
        if (!stateReadStateRes.State.Address) {
          NotificationErr(this.$t('Onboarding.F4 address does not support entry'));
          // this.singleSignatureInitErr()
          return;
        }
        this.originalOwner = stateReadStateRes.State.Address;
      }
    },
    async initActorState() {
      this.poolF4Address = getF4AddressByEth(this.poolAddress, store.state.evn);
      this.poolF0Address = await walletProvider.lookupId(this.poolF4Address);
      this.contractMethod = 'changeWorkerAddress';
      let abiNewWorker = parseInt(this.newWorker.substring(2));
      this.contractParams = [];
      if (this.needConfirmChangeWorkerAddress) {
        this.contractParams.push(this.nodeId);
        this.contractMethod = 'confirmChangeWorkerAddress';
      } else {
        this.contractParams.push(this.nodeId, abiNewWorker, this.controlAddresses === null ? [] : this.controlAddresses.map(i => parseInt(i.substring(2))));
        this.contractMethod = 'changeWorkerAddress';
      }
      this.contractABI = StorageProviderLendingPool;
      let _originalOwner = await readContract({
        address: this.poolAddress,
        abi: StorageProviderLendingPoolABI.abi,
        functionName: 'nodeOwner',
        args: [this.nodeId]
      });
      let _originalOwnerF0 = this.getNetworkPrefix() + '0' + (BigNumber.from(_originalOwner).toBigInt() & toBigInt("0x0000000000000000FFFFFFFFFFFFFFFFFFFFFFFF")).toString();
      await this.initOriginalOwner(_originalOwnerF0);
      this.fromAddress = this.originalOwner;
      this.toF0Address = this.poolF0Address;
      console.log('this.contractParams', this.contractParams);
      this.$nextTick(async () => {
        this.$refs['singer'].init();
      });
    },
    pushProposalNext() {
      this.active++;
    },
    connectWallet() {
      this.$store.state.refs['iHeader'].connectWallet();
    },
    setCurrentWallet() {
      this.newWorker = this.address;
    },
    copy() {
      let clipboard = new Clipboard('.copy-text', {
        text: () => {
          return this.currentNode.operator;
        }
      });
      clipboard.on('success', () => {
        this.isCopy = true;
        setTimeout(() => {
          this.isCopy = false;
        }, 1500);
        clipboard.destroy();
      });
      clipboard.on('error', () => {
        this.isCopy = false;
        clipboard.destroy();
      });
    },
    sendProposalFun() {
      this.sendProposal = true;
      this.active++;
      this.$nextTick(() => {
        this.$refs['singer2'].init();
      });
    },
    approvedProposal() {
      this.active++;
    },
    async approvedProposal2() {
      this.workerInfo();
      this.approvedProposalRes = true;
    },
    reapprove() {
      this.showNewWorker = true;
    },
    async checkEthAddress() {
      if (!this.newWorker.toLowerCase().startsWith("0x") || this.newWorker.length !== 42) {
        NotificationErr('Invalid Address!');
        return true;
      }
      let f4Address = encode("f", newDelegatedEthAddress(this.newWorker));
      try {
        let upId = await retryFun(() => walletProvider.lookupId(f4Address), 3);
        if (!upId) {
          this.isHaveActorId = false;
          return true;
        }
      } catch (err) {
        this.isHaveActorId = false;
        return true;
      }
      this.isHaveActorId = true;
      return false;
    },
    async checkWorkerAddress() {
      return false;
    },
    async confirmChangeWorker() {
      this.loading = true;
      this.affirmWorker = true;
      await this.init();
      this.loading = false;
    },
    getByBlockNumberToTime(tipset) {
      let time = blockU.getByBlockNumberToTime(tipset);
      return dateFormatAll(time);
    },
    async checkNewWorkerInput() {
      if (this.newWorker.trim() === '') {
        this.isNewWorkerErr = true;
        this.newWorkerErrTips = this.$t('change.Please enter Control ID');
        return false;
      }
      try {
        await walletProvider.getActor(this.newWorker.trim());
      } catch (e) {
        this.isNewWorkerErr = true;
        this.newWorkerErrTips = this.$t('change.workerInputErr');
        return false;
      }
      this.newWorkerErrTips = '';
      this.isNewWorkerErr = false;
      return true;
    },
    clearErr() {
      this.isNewWorkerErr = false;
      this.newWorkerErrTips = '';
    },
    async affirm() {
      let flag = await this.checkNewWorkerInput();
      if (!flag) {
        return;
      }
      if (this.newWorker === '') {
        NotificationErr('Please enter Worker address');
        return;
      }
      this.newWorker = this.newWorker.toString().trim();
      this.loading = true;
      if (await this.checkWorkerAddress()) {
        NotificationErr('Invalid Worker address!');
        this.loading = false;
        return;
      }
      this.affirmWorker = true;
      await this.init();
      this.showCurrentWorker = false;
      this.loading = false;
    }
  },
  computed: {
    env() {
      return this.$store.state.evn;
    },
    currentNode() {
      return this.$store.state.nodeData.currentNode;
    },
    singerMultipleNum() {
      return this.$store.state.node.singerMultipleNum;
    },
    singerMultipleThreshold() {
      return this.$store.state.node.singerMultipleThreshold;
    },
    nodeInfo() {
      return this.$store.state.node.nodeInfo;
    },
    controlAddresses() {
      return this.$store.state.node.nodeInfo.controlAddresses;
    },
    isConnected() {
      return this.$store.state.isConnected;
    },
    address() {
      return this.$store.state.address;
    }
  }
};