import {enableMetaLinkFilecoinSnap} from "@stfil/metalink-filecoin-adapter";
import {getActorAddress} from "@/utils/common";
import {loutsRpc, walletProvider} from "@/utils/LoutsRpc";
import {toBigInt} from "@/utils/NumU";
import {base64Encode, hexDecode} from "@/utils/contracts/singer/utils";
import {encode as dagCborEncode} from "@ipld/dag-cbor";
import {ethers} from "ethers";
import {WaitProposal} from "@/utils/contracts/singer/singer";
import {FilecoinNumber} from '@glif/filecoin-number'
import {GasEstimateMessageGas, MpoolGetNonce} from "@/utils/FilecoinApi";

let _CreateMessage = async (from, to, value, method, params, Nonce) => {
    let msg = {
        "From": from,
        "To": to,
        'GasLimit': 0,
        'GasFeeCap': '0',
        'GasPremium': '0',
        "Method": method,
        "Params": params,
        'Version': 0,
        'Nonce': Nonce,
        'Value': value
    }
    let data = undefined
    try {
        data = await loutsRpc(GasEstimateMessageGas(msg))
    } catch (e) {
        throw new Error(e.error.message)
    }
    if (!data) {
        throw new Error("Null")
    }
    if (data.error) {
        throw new Error(data.error)
    }
    return data.result

}

export class MetamaskFlaskFilsnapSuper {

    isInstalled = false
    metaLinkFilecoinSnap
    snapApi
    isConnected

    f1Address
    f4Address
    ethAddress
    f0Address

    constructor(rpc, $t) {
        this.$t = $t
        this.rpc = rpc
        this.eventEmitter = new EventTarget()
    }

    async connect(network) {
        try {
            await this.initiateFilecoinSnap(network)
            if (this.isInstalled) {
                await this.initAccount()
                this.isConnected = true
            } else {
                this.isConnected = false
            }
        } catch (e) {
            this.isConnected = false
            throw e
        }

    }

    async initiateFilecoinSnap(network) {
        try {
            const metaLinkFilecoinSnap = await enableMetaLinkFilecoinSnap({network}, "npm:@stfil/metalink-filecoin", {version: "1.0.5"});
            this.isInstalled = true;
            console.log('Snap installed!');
            this.metaLinkFilecoinSnap = metaLinkFilecoinSnap
            this.snapApi = await this.metaLinkFilecoinSnap.getApi()
        } catch (e) {
            console.log('Snap not installed!');
            console.error(e)
            this.isInstalled = false;
            if (e.message.toString().indexOf("does not exist") >= 0 || e.message.toString().indexOf("doesn't support") >= 0) {
                throw new Error(this.$t('common.flaskErrTips'))
            } else {
                throw e
            }
        }
    }

    async disConnect() {
        this.isConnected = false
    }

    async signMessageLocal(message) {
        const sigResponse = await this.snapApi.signMessageRaw(message)
        if (sigResponse.error != null) {
            throw sigResponse.error
        } else if (sigResponse.error == null && !sigResponse.confirmed) {
            throw new Error("")
        } else {
            return sigResponse.signature
        }
    }

    _attoFilToFil(attoFilValue) {
        const filecoinNumber = new FilecoinNumber(attoFilValue, 'attofil')
        return filecoinNumber.toFil()
    }

    async signMessage(option, confirmCall = undefined) {

        if (!this.isConnected) {
            try {
                await this.connect()
            } catch (e) {
                console.log(e)
                throw e
            }
        }

        let {overrides, functionName, args, abi, f0address} = option
        const Interface = new ethers.utils.Interface(abi);
        const invokeContractMethod = 3844450837;// 固定值
        let params = base64Encode(dagCborEncode(hexDecode(Interface.encodeFunctionData(functionName, args))))
        let nonce = (await loutsRpc(MpoolGetNonce(this.f1Address))).result
        let singerMsg = await _CreateMessage(this.f1Address, f0address, (overrides && overrides.value) ? overrides.value.toString() : '0', invokeContractMethod, params, nonce)
        let message = {
            to: singerMsg.To,
            value: singerMsg.Value,
            gaslimit: singerMsg.GasLimit,
            gasfeecap: singerMsg.GasFeeCap,
            gaspremium: singerMsg.GasPremium,
            nonce: singerMsg.Nonce,
            method: singerMsg.Method,
            params: singerMsg.Params,
        }

        const signedMessageResponse = await this.snapApi.signMessage(message);
        console.log('signedMessageResponse', signedMessageResponse)
        if (signedMessageResponse.error != null) {
            throw signedMessageResponse.error
        } else if (signedMessageResponse.error == null && !signedMessageResponse.confirmed) {
            throw new Error("")
        } else {

            const txResult = await this.snapApi.sendMessage(signedMessageResponse.signedMessage);
            console.log('txResult', txResult)
            if (confirmCall) {
                confirmCall({
                    hash: txResult.cid
                })
            }
            return new Promise((resolve, reject) => {
                setTimeout(async () => {
                    let data = {}
                    try {
                        data = await WaitProposal({'/': txResult.cid})
                        resolve(data)
                    } catch (e) {
                        reject(e)
                    }
                }, 2000)
            })
        }

    }

    async initAccount() {
        this.f1Address = await this.snapApi.getAddress()
        let res
        try {
            res = await walletProvider.lookupId(this.f1Address)
        } catch (e) {
            res = ''
        }
        this.f0Address = res
        if (res !== '') {
            this.ethAddress = getActorAddress(toBigInt(res.substr(2)))
        } else {
            this.ethAddress = ''
        }
    }

    getConnected() {
        return this.isConnected
    }

    getEthAddress() {
        return this.ethAddress
    }

    getF0Address() {
        return this.f0Address
    }

    getF1Address() {
        return this.f1Address
    }
}
