import "core-js/modules/es.array.push.js";
import { mapState } from "vuex";
import PercentageMath from "@/utils/contracts/PercentageMath";
import { E23, RAY, WadRayMath } from "@/utils/contracts/WadRayMath";
import ChartBlock from '@/components/ChartBlock.vue';
import { externalFormatFilPrice, toBigInt, toBigIntDefault } from "@/utils/NumU";
import { isEmpty } from "@/utils/model";
export default {
  name: "InterestRateModel",
  components: {
    ChartBlock
  },
  data() {
    return {
      tooltipWidth: '220px',
      closeIconRoteClass: '',
      selectMenusIndex: -1,
      menus: [{
        name: this.$t('common.Model.Variable Interest Rate'),
        colorClass: '',
        desc: this.$t('common.Model.Variable Interest Rate Desc')
      }, {
        name: this.$t('common.Model.Stable Interest Rate'),
        colorClass: 'rate-menu-io-orange',
        desc: this.$t('common.Model.Stable Interest Rate Desc')
      }, {
        name: this.$t('common.Model.Optimal Utilization Rate'),
        colorClass: 'rate-menu-io-purple',
        desc: this.$t('common.Model.Optimal Utilization Rate Desc')
      }, {
        name: this.$t('common.Model.Interest Rate Protection'),
        colorClass: 'rate-menu-io-green',
        desc: this.$t('common.Model.Interest Rate Protection Desc')
      }, {
        name: this.$t('dashboard.liquidityReserveRatio'),
        colorClass: 'rate-menu-io-red',
        isNew: true,
        desc: this.$t('common.Model.liquidityReserveRatio Desc')
      }],
      optionInterestRateMode: {
        title: {
          show: false
        },
        legend: {
          orient: 'horizontal',
          top: 0,
          selectedMode: false,
          left: '10px',
          data: ['Borrow APR,stable', 'Borrow APR,variable']
        },
        xAxis: {
          data: ['-', '-', '-', '-', '-', '-', '-', '-', '-'],
          axisTick: {
            show: false
          },
          axisLabel: {
            show: false,
            interval: 199,
            formatter: function (value) {
              return Math.floor(value) + '%';
            }
          }
        },
        grid: {
          left: '50px',
          right: '50px',
          top: '40px',
          bottom: '30px'
        },
        yAxis: {
          type: 'value',
          minInterval: 40,
          max: 100,
          splitLine: {
            show: false
          },
          axisLine: {
            show: true
          },
          axisLabel: {
            show: false,
            formatter: function () {
              return '';
            }
          }
        },
        tooltip: {
          trigger: 'axis',
          showContent: true,
          formatter: params => {
            let diff = this.diffBorrow(params[0].axisValue);
            return `<div style="width: ${this.tooltipWidth};font-size: 12px;">
                        <div style="display: flex;justify-content: space-between; align-items: center;">
                            <div>${diff.flag ? this.$t('common.Model.diffBrowserOut') : this.$t('common.Model.diffBrowserIn')}</div> <div>${diff.val}</div>
                        </div>
                        <div class="horizontal-dividing-line mt-1 mb-1"></div>
                        <div style="display: flex;justify-content: space-between; align-items: center;">
                            <div><span class="point-tip-ur"></span>${this.$t('farm.console.UtilizationRate')}</div>  <div>${params[0].axisValue}%</div>
                        </div>
                            <div style="display: flex;justify-content: space-between; align-items: center;">
                            <div><span class="point-tip-vd"></span>${this.$t('dashboard.BorrowAPYvariable')}</div>  <div>${params[1].value}%</div>
                        </div>
                        <div style="display: flex;justify-content: space-between; align-items: center;">
                            <div><span class="point-tip-sd"></span>${this.$t('dashboard.BorrowAPYstable')}</div>  <div>${params[0].value}%</div>
                        </div>
                      </div>`;
          }
        },
        series: [{
          z: 2,
          name: 'Borrow APR,stable',
          type: 'line',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0],
          showSymbol: false,
          smooth: true,
          //设置折线图颜色
          itemStyle: {
            color: '#e86016'
          },
          lineStyle: {
            width: 2
          },
          // Current 那条线
          markLine: {
            lineStyle: {
              color: '#1dc757',
              width: 2
            },
            symbol: ['none', 'none'],
            label: {
              show: true,
              position: 'end',
              formatter: params => {
                return `Current: ${params.value / 2}%`;
              },
              distance: 2
            },
            data: [{
              name: 'Y 轴值为 100 的水平线',
              xAxis: 0
            }, [{
              coord: [0, 0],
              lineStyle: {
                color: '#e88f5f',
                width: 2,
                cap: 'round',
                join: 'round'
              },
              label: {
                distance: 5,
                position: 'start',
                offset: [0, -1],
                show: true,
                formatter: params => {
                  return `${params.data.coord[1]}%`;
                }
              }
            }, {
              coord: [0, 0]
            }]]
          }
        }, {
          name: 'Borrow APR,variable',
          type: 'line',
          z: 2,
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0],
          showSymbol: false,
          smooth: false,
          //设置折线图颜色
          itemStyle: {
            color: '#1619d7'
          },
          lineStyle: {
            width: 2
          },
          markLine: {
            z: 0,
            lineStyle: {
              color: '#c9c9c9',
              width: 1,
              opacity: 0.9
            },
            symbol: ['none', 'none'],
            label: {
              show: true,
              position: 'start',
              formatter: () => {
                return ``;
              },
              distance: 7
            },
            data: [[
            // 横线，0到 最佳利用率结束区间
            {
              coord: [0, 0],
              label: {
                show: false,
                position: 'start',
                distance: 3,
                offset: [81, 0],
                formatter: params => {
                  return `${params.data.coord[1]}%   42.5% * APR`;
                }
              }
            }, {
              coord: [0, 0]
            }], [
            // 竖线，最佳利用率结束区间
            {
              coord: [0, 0],
              label: {
                show: false,
                position: 'start',
                distance: 3,
                formatter: params => {
                  return `${params.data.coord[0]}%`;
                }
              }
            }, {
              coord: [0, 0]
            }], {
              // 横线，70% * APR
              yAxis: 0,
              lineStyle: {
                width: 1
              },
              label: {
                show: false,
                position: 'start',
                distance: 3,
                offset: [73, 0],
                formatter: params => {
                  return `${params.data.coord[1]}%   70% * APR`;
                }
              }
            }, [
            // 竖线，最右边那条
            {
              coord: [0, 0]
            }, {
              coord: [0, 0]
            }], [
            // 横线，浮动利率
            {
              coord: [0, 0],
              lineStyle: {
                color: '#494bd5',
                width: 2
              },
              label: {
                show: true,
                offset: [0, 4],
                formatter: params => {
                  return `${params.data.coord[1]}%`;
                }
              }
            }, {
              coord: [0, 0]
            }], [
            // 竖线，最佳利用率开始区间
            {
              coord: [0, 0],
              label: {
                show: false,
                position: 'start',
                distance: 3,
                formatter: params => {
                  return `${params.data.coord[0]}%`;
                }
              }
            }, {
              coord: [0, 0]
            }], [{
              silent: false,
              coord: [0, 0],
              lineStyle: {
                width: 0
              },
              label: {
                show: false,
                position: 'start',
                distance: 3,
                offset: [71, 0],
                formatter: params => {
                  return `${params.data.coord[1]}%   10% * APR`;
                }
              }
            }, {
              silent: false,
              coord: [1, 0],
              lineStyle: {
                width: 0
              }
            }], {
              // 横线，最上面那条最大利率
              yAxis: 0,
              label: {
                show: false,
                position: 'start',
                distance: 3,
                offset: [80, 0],
                formatter: params => {
                  return `${params.data.coord[1]}%   100% * APR`;
                }
              }
            }, [
            // 存款储备
            {
              coord: [0, 0],
              label: {
                show: false,
                position: 'start',
                distance: 3,
                formatter: params => {
                  return `${params.data.coord[0]}%`;
                }
              }
            }, {
              coord: [0, 0]
            }], [
            // 横线，60% * ARP
            {
              coord: [0, 0],
              lineStyle: {
                width: 1
              },
              label: {
                show: false,
                position: 'start',
                distance: 3,
                offset: [73, 0],
                formatter: params => {
                  return `${params.data.coord[1]}%   65% * APR`;
                }
              }
            }, {
              coord: [0, 0]
            }], [
            // 纵线，60% * ARP
            {
              coord: [0, 0],
              lineStyle: {
                width: 1
              },
              label: {
                show: false,
                position: 'start',
                distance: 3,
                formatter: params => {
                  return `${params.data.coord[0]}%`;
                }
              }
            }, {
              coord: [0, 0]
            }]]
          }
        }, {
          type: 'line',
          markArea: {
            emphasis: {
              disabled: true
            },
            silent: false,
            label: {
              show: true,
              formatter: () => {
                return this.$t('common.Model.Optimal Utilization Rate');
              },
              position: 'bottom'
            },
            itemStyle: {
              color: 'rgba(123, 56, 232, 0.05)'
            },
            data: [[{
              xAxis: 0,
              yAxis: 0
            }, {
              xAxis: 0,
              yAxis: 0
            }]]
          }
        }, {
          type: 'line',
          markArea: {
            emphasis: {
              disabled: true
            },
            silent: false,
            itemStyle: {
              color: 'rgba(56, 232, 65, 0.2)'
            },
            data: [[{
              xAxis: 0,
              yAxis: 0
            }, {
              xAxis: 0,
              yAxis: 0
            }]]
          }
        }, {
          type: 'line',
          markArea: {
            emphasis: {
              disabled: true
            },
            silent: false,
            label: {
              show: false,
              formatter: () => {
                return this.$t('dashboard.liquidityReserveRatio');
              },
              position: 'bottom'
            },
            itemStyle: {
              color: 'rgba(232, 56, 71, 0.1)'
            },
            data: [[{
              xAxis: 0,
              yAxis: 0
            }, {
              xAxis: 0,
              yAxis: 0
            }]]
          }
        }]
      }
    };
  },
  watch: {
    optimalUtilizationRateIntervalEnd() {
      this.calcBorrowChartData();
    },
    liquidityReserveRatio() {
      this.calcBorrowChartData();
    },
    currentUtilizationRate() {
      this.utilizationRateFun();
    },
    '$i18n.locale'() {
      this.initTooltipWidth();
      this.initOptionBorrowInfo();
      this.initMenu();
    }
  },
  created() {
    this.initOptionBorrowInfo();
    this.calcBorrowChartData();
    this.utilizationRateFun();
  },
  mounted() {
    this.initTooltipWidth();
  },
  methods: {
    externalFormatFilPrice,
    initMenu() {
      this.menus = [{
        name: this.$t('common.Model.Variable Interest Rate'),
        colorClass: '',
        desc: this.$t('common.Model.Variable Interest Rate Desc')
      }, {
        name: this.$t('common.Model.Stable Interest Rate'),
        colorClass: 'rate-menu-io-orange',
        desc: this.$t('common.Model.Stable Interest Rate Desc')
      }, {
        name: this.$t('common.Model.Optimal Utilization Rate'),
        colorClass: 'rate-menu-io-purple',
        desc: this.$t('common.Model.Optimal Utilization Rate Desc')
      }, {
        name: this.$t('common.Model.Interest Rate Protection'),
        colorClass: 'rate-menu-io-green',
        desc: this.$t('common.Model.Interest Rate Protection Desc')
      }, {
        name: this.$t('dashboard.liquidityReserveRatio'),
        colorClass: 'rate-menu-io-red',
        isNew: true,
        desc: this.$t('common.Model.liquidityReserveRatio Desc')
      }];
    },
    diffBorrow(utilizationRate) {
      if (isEmpty(utilizationRate) || isNaN(utilizationRate)) {
        return {
          false: false,
          val: '0 FIL'
        };
      }
      let borrowValue = PercentageMath.mul(this.supplyBalance, toBigIntDefault(parseFloat(utilizationRate) * 100, 0n));
      let res = toBigIntDefault(this.borrowBalance, 0n) - borrowValue;
      return {
        flag: res < 0n,
        val: this.externalFormatFilPrice((res >= 0n ? res : -res).toString())
      };
    },
    clickMenu(index) {
      let _index = index;
      if (this.selectMenusIndex === index) {
        _index = -1;
      }
      let _currentAPR = (parseFloat((this.currentAPR / E23).toString()) / 100).toFixed(2);
      let _baseVariableBorrowRate = (parseFloat((this.baseVariableBorrowRate / E23).toString()) / 100).toFixed(2);
      let _liquidityReserveRatio = (100.0 - parseFloat((this.liquidityReserveRatio / E23).toString()) / 100).toFixed(1);
      switch (_index) {
        case -1:
          this.optionInterestRateMode.series[3].markArea.data[0][1].xAxis = 0;
          this.optionInterestRateMode.series[1].lineStyle.width = 2;
          this.optionInterestRateMode.series[0].lineStyle.width = 2;
          this.optionInterestRateMode.series[0].itemStyle.color = '#e86016';
          this.optionInterestRateMode.series[1].itemStyle.color = '#1619d7';
          this.optionInterestRateMode.series[1].markLine.data[0][0].label.show = false;
          this.optionInterestRateMode.series[1].markLine.data[2].label.show = false;
          this.optionInterestRateMode.series[1].markLine.data[7].label.show = false;
          this.optionInterestRateMode.series[1].markLine.data[6][0].label.show = false;
          this.optionInterestRateMode.series[1].z = 1;
          this.optionInterestRateMode.series[0].z = 1;
          this.optionInterestRateMode.series[2].markArea.data[0][0].yAxis = (parseFloat((this.optimalBorrowRate / E23).toString()) / 100).toFixed(2);
          this.optionInterestRateMode.series[2].markArea.label.show = true;
          this.optionInterestRateMode.series[1].markLine.data[1][0].label.show = false;
          this.optionInterestRateMode.series[1].markLine.data[5][0].label.show = false;
          this.optionInterestRateMode.series[4].markArea.label.show = false;
          this.optionInterestRateMode.series[4].markArea.data[0][0].yAxis = 0;
          this.optionInterestRateMode.series[1].markLine.data[8][0].label.show = false;
          this.optionInterestRateMode.series[1].markLine.data[8][1].coord = [_liquidityReserveRatio, 0];
          this.optionInterestRateMode.series[1].markLine.data[9][0].label.show = false;
          this.optionInterestRateMode.series[1].markLine.data[9][0].lineStyle.width = 1;
          this.optionInterestRateMode.series[1].markLine.data[10][0].lineStyle.width = 1;
          this.optionInterestRateMode.series[1].markLine.data[2].lineStyle.width = 1;
          this.setBorrowTypeLine();
          break;
        case 0:
          this.optionInterestRateMode.series[0].lineStyle.width = 0;
          this.optionInterestRateMode.series[1].lineStyle.width = 2;
          this.optionInterestRateMode.series[1].markLine.data[0][0].label.show = true;
          this.optionInterestRateMode.series[1].markLine.data[2].label.show = true;
          this.optionInterestRateMode.series[1].markLine.data[6][0].label.show = true;
          this.optionInterestRateMode.series[1].markLine.data[7].label.show = true;
          this.optionInterestRateMode.series[1].markLine.data[9][0].label.show = true;
          this.optionInterestRateMode.series[1].z = 2;
          this.optionInterestRateMode.series[0].z = 1;
          this.clearBorrowTypeLine(_index);
          break;
        case 1:
          this.optionInterestRateMode.series[0].lineStyle.width = 2;
          this.optionInterestRateMode.series[1].lineStyle.width = 0;
          this.optionInterestRateMode.series[1].markLine.data[0][0].label.show = true;
          this.optionInterestRateMode.series[1].markLine.data[7].label.show = true;
          this.optionInterestRateMode.series[1].markLine.data[6][0].label.show = false;
          this.optionInterestRateMode.series[1].z = 1;
          this.optionInterestRateMode.series[0].z = 2;
          this.optionInterestRateMode.series[1].markLine.data[9][0].lineStyle.width = 0;
          this.optionInterestRateMode.series[1].markLine.data[10][0].lineStyle.width = 0;
          this.optionInterestRateMode.series[1].markLine.data[2].lineStyle.width = 0;
          this.clearBorrowTypeLine(_index);
          break;
        case 2:
          this.optionInterestRateMode.series[0].lineStyle.width = 2;
          this.optionInterestRateMode.series[1].lineStyle.width = 2;
          this.optionInterestRateMode.series[0].itemStyle.color = '#e5e5e5';
          this.optionInterestRateMode.series[1].itemStyle.color = '#e5e5e5';
          this.optionInterestRateMode.series[2].markArea.data[0][0].yAxis = this.optionInterestRateMode.yAxis.max;
          this.optionInterestRateMode.series[2].markArea.label.show = false;
          this.optionInterestRateMode.series[1].markLine.data[1][0].label.show = true;
          this.optionInterestRateMode.series[1].markLine.data[5][0].label.show = true;
          this.clearBorrowTypeLine(_index);
          break;
        case 3:
          this.optionInterestRateMode.series[0].itemStyle.color = '#e5e5e5';
          this.optionInterestRateMode.series[1].itemStyle.color = '#e5e5e5';
          this.optionInterestRateMode.series[2].markArea.data[0][0].yAxis = 0;
          this.optionInterestRateMode.series[1].markLine.data[0][0].label.show = false;
          this.optionInterestRateMode.series[1].markLine.data[2].label.show = true;
          this.optionInterestRateMode.series[1].markLine.data[7].label.show = true;
          this.optionInterestRateMode.series[1].markLine.data[6][0].label.show = true;
          this.optionInterestRateMode.series[3].markArea.data[0][0].yAxis = _currentAPR;
          this.optionInterestRateMode.series[3].markArea.data[0][1].yAxis = _baseVariableBorrowRate;
          this.optionInterestRateMode.series[3].markArea.data[0][1].xAxis = 200;
          this.clearBorrowTypeLine(_index);
          break;
        case 4:
          this.optionInterestRateMode.series[0].itemStyle.color = '#e5e5e5';
          this.optionInterestRateMode.series[1].itemStyle.color = '#e5e5e5';
          console.log('_liquidityReserveRatio', _liquidityReserveRatio);
          this.optionInterestRateMode.series[4].markArea.data[0][0].xAxis = _liquidityReserveRatio;
          this.optionInterestRateMode.series[4].markArea.data[0][0].yAxis = this.optionInterestRateMode.yAxis.max;
          this.optionInterestRateMode.series[4].markArea.data[0][1].xAxis = 200;
          this.optionInterestRateMode.series[4].markArea.label.show = false;
          this.optionInterestRateMode.series[2].markArea.data[0][0].yAxis = 0;
          this.optionInterestRateMode.series[2].markArea.label.show = false;
          this.optionInterestRateMode.series[1].markLine.data[1][0].label.show = false;
          this.optionInterestRateMode.series[1].markLine.data[5][0].label.show = false;
          this.optionInterestRateMode.series[1].markLine.data[8][0].coord = [_liquidityReserveRatio, 0];
          this.optionInterestRateMode.series[1].markLine.data[8][1].coord = [_liquidityReserveRatio, this.optionInterestRateMode.yAxis.max];
          this.optionInterestRateMode.series[1].markLine.data[8][0].label.show = true;
          this.clearBorrowTypeLine(_index);
      }
      this.selectMenusIndex = _index;
    },
    clearBorrowTypeLine(index) {
      if (index === 1) {
        this.optionInterestRateMode.series[1].markLine.data[4][0].label.show = false;
        this.optionInterestRateMode.series[1].markLine.data[4][1].coord[0] = 200;
      } else if (index === 0) {
        this.optionInterestRateMode.series[0].markLine.data[1][1].coord[0] = 200;
        this.optionInterestRateMode.series[0].markLine.data[1][0].label.show = false;
      } else if (index > 1) {
        this.optionInterestRateMode.series[0].markLine.data[1][1].coord[0] = 200;
        this.optionInterestRateMode.series[1].markLine.data[4][1].coord[0] = 200;
        this.optionInterestRateMode.series[0].markLine.data[1][0].label.show = false;
        this.optionInterestRateMode.series[1].markLine.data[4][0].label.show = false;
      }
    },
    initTooltipWidth() {
      if (this.$i18n.locale === 'zh') {
        this.tooltipWidth = '180px';
      } else {
        this.tooltipWidth = '220px';
      }
    },
    initOptionBorrowInfo() {
      this.optionInterestRateMode.legend.data = [this.$t('dashboard.BorrowAPRstable'), this.$t('dashboard.BorrowAPRvariable')];
      this.optionInterestRateMode.series[0].name = this.$t('dashboard.BorrowAPRstable');
      this.optionInterestRateMode.series[1].name = this.$t('dashboard.BorrowAPRvariable');
    },
    utilizationRateFun() {
      if (!this.currentUtilizationRate) {
        return;
      }
      let data = (parseInt(this.currentUtilizationRate.toString()) / 50).toFixed(2);
      // current
      let res = parseFloat(data);
      if (res >= 200.0) {
        res = 200.0;
      }
      this.setBorrowTypeLine(res);
      this.optionInterestRateMode.series[0].markLine.data[0].xAxis = res;
    },
    setBorrowTypeLine(res) {
      if (!this.currentUtilizationRate) {
        return;
      }
      let _res = res;
      if (!res) {
        let data = (parseInt(this.currentUtilizationRate.toString()) / 50).toFixed(2);
        // current
        _res = parseFloat(data);
        if (res >= 200.0) {
          _res = 200.0;
        }
      }
      let y = this.calcVariableData(toBigInt(Math.floor(_res * 100 / 2).toString()));
      y = (parseFloat(y.toString()) / 100).toFixed(2);
      this.optionInterestRateMode.series[1].markLine.data[4][0].coord = [200, y];
      this.optionInterestRateMode.series[1].markLine.data[4][1].coord = [_res, y];
      if (200 - _res <= 0.5) {
        this.optionInterestRateMode.series[1].markLine.data[4][0].label.offset = [25, 0];
      }
      this.optionInterestRateMode.series[1].markLine.data[4][0].label.show = true;
      let data = (parseInt(this.currentUtilizationRate.toString()) / 50).toFixed(2);
      // current
      res = parseFloat(data);
      if (res >= 200.0) {
        res = 200.0;
      }
      y = this.stableBorrowRate / E23;
      y = (parseFloat(y.toString()) / 100).toFixed(2);
      if (y > 0) {
        this.optionInterestRateMode.series[0].markLine.data[1][0].coord = [200, y];
        this.optionInterestRateMode.series[0].markLine.data[1][1].coord = [res, y];
        if (200 - res <= 0.5) {
          this.optionInterestRateMode.series[0].markLine.data[1][0].label.offset = [26, 0];
        }
        this.optionInterestRateMode.series[0].markLine.data[1][0].label.show = true;
      } else {
        this.optionInterestRateMode.series[0].markLine.data[1][0].coord = [0, 0];
        this.optionInterestRateMode.series[0].markLine.data[1][1].coord = [0, 0];
        this.optionInterestRateMode.series[0].markLine.data[1][0].label.show = false;
      }
    },
    calcVariableData(t) {
      if (t > 10000n) {
        t = 10000n;
      }
      let optimalUtilizationRateIntervalEnd = this.optimalUtilizationRateIntervalEnd / E23;
      let baseVariableBorrowRate = this.baseVariableBorrowRate / E23;
      let k1 = WadRayMath.rayDiv(this.optimalBorrowRate - this.baseVariableBorrowRate, this.optimalUtilizationRateIntervalEnd);
      let k2 = WadRayMath.rayDiv(this.maxBorrowRate - this.optimalBorrowRate, RAY - this.optimalUtilizationRateIntervalEnd);
      let rMax = this.maxBorrowRate / E23;
      let res = 0;
      if (t <= optimalUtilizationRateIntervalEnd) {
        res = baseVariableBorrowRate + WadRayMath.rayMul(k1, t);
      } else {
        res = rMax - toBigInt(WadRayMath.rayMul(k2, toBigInt(10000) - t));
      }
      return res;
    },
    calcBorrowChartData() {
      if (this.optimalBorrowRate) {
        this.initChartColor();
        this.calcStableChartData();
        this.calcVariableChartData();
        let _currentAPR = (parseFloat((this.currentAPR / E23).toString()) / 100).toFixed(2);
        let _maxBorrowRate = (parseFloat((this.maxBorrowRate / E23).toString()) / 100).toFixed(2);
        let _maxSeadLoadRateInterval = (parseFloat((this.maxSeadLoadRateInterval / E23).toString()) / 100).toFixed(1);
        let _reserveRatioBorrowVariableRate = (parseFloat((this.reserveRatioBorrowVariableRate / E23).toString()) / 100).toFixed(2);
        let _optimalBorrowRate = (parseFloat((this.optimalBorrowRate / E23).toString()) / 100).toFixed(2);
        let _optimalUtilizationRateIntervalEnd = (parseFloat((this.optimalUtilizationRateIntervalEnd / E23).toString()) / 100).toFixed(1);
        let _optimalUtilizationRateIntervalStart = (parseFloat((this.optimalUtilizationRateIntervalStart / E23).toString()) / 100).toFixed(1);
        let _baseVariableBorrowRate = (parseFloat((this.baseVariableBorrowRate / E23).toString()) / 100).toFixed(1);
        this.optionInterestRateMode.yAxis.max = _currentAPR * 1.1;
        this.optionInterestRateMode.series[1].markLine.data[0][0].coord = [0, _optimalBorrowRate];
        this.optionInterestRateMode.series[1].markLine.data[0][1].coord = [_optimalUtilizationRateIntervalEnd, _optimalBorrowRate];
        this.optionInterestRateMode.series[1].markLine.data[1][0].coord = [_optimalUtilizationRateIntervalEnd, 0];
        this.optionInterestRateMode.series[1].markLine.data[1][1].coord = [_optimalUtilizationRateIntervalEnd, _optimalBorrowRate];
        this.optionInterestRateMode.series[1].markLine.data[2].yAxis = _maxBorrowRate;
        this.optionInterestRateMode.series[1].markLine.data[9][0].coord = [0, _reserveRatioBorrowVariableRate];
        this.optionInterestRateMode.series[1].markLine.data[9][1].coord = [_maxSeadLoadRateInterval, _reserveRatioBorrowVariableRate];
        this.optionInterestRateMode.series[1].markLine.data[10][0].coord = [_maxSeadLoadRateInterval, 0];
        this.optionInterestRateMode.series[1].markLine.data[10][1].coord = [_maxSeadLoadRateInterval, _reserveRatioBorrowVariableRate];
        let data = parseFloat(100 .toString()).toFixed(1);
        this.optionInterestRateMode.series[1].markLine.data[3][0].coord = [data, _currentAPR];
        this.optionInterestRateMode.series[1].markLine.data[3][1].coord = [data, 0];
        this.optionInterestRateMode.series[1].markLine.data[7].yAxis = _currentAPR;
        this.optionInterestRateMode.series[1].markLine.data[5][0].coord = [_optimalUtilizationRateIntervalStart, 0];
        this.optionInterestRateMode.series[1].markLine.data[5][1].coord = [_optimalUtilizationRateIntervalStart, _optimalBorrowRate];
        this.optionInterestRateMode.series[1].markLine.data[6][0].coord[1] = _baseVariableBorrowRate;
        this.optionInterestRateMode.series[1].markLine.data[6][1].coord[1] = _baseVariableBorrowRate;
        this.optionInterestRateMode.series[2].markArea.data[0][0].xAxis = _optimalUtilizationRateIntervalStart;
        this.optionInterestRateMode.series[2].markArea.data[0][0].yAxis = _optimalBorrowRate;
        this.optionInterestRateMode.series[2].markArea.data[0][1].xAxis = _optimalUtilizationRateIntervalEnd;
      }
    },
    calcVariableChartData() {
      let variableYData = [];
      let step = 50n;
      let maxUt = 10000n;
      let startUt = 0n;
      let optimalUtilizationRateIntervalEnd = this.optimalUtilizationRateIntervalEnd / E23;
      let maxSeadLoadRateInterval = this.maxSeadLoadRateInterval / E23;
      let baseVariableBorrowRate = this.baseVariableBorrowRate / E23;
      let k1 = WadRayMath.rayDiv(this.optimalBorrowRate - this.baseVariableBorrowRate, this.optimalUtilizationRateIntervalEnd);
      let k2 = WadRayMath.rayDiv(this.reserveRatioBorrowVariableRate - this.optimalBorrowRate, this.maxSeadLoadRateInterval - this.optimalUtilizationRateIntervalEnd);
      let k3 = WadRayMath.rayDiv(this.maxBorrowRate - this.reserveRatioBorrowVariableRate, RAY - this.maxSeadLoadRateInterval);
      let rMax2 = this.reserveRatioBorrowVariableRate / E23;
      let rMax = this.maxBorrowRate / E23;
      for (let ut = startUt; ut <= maxUt; ut = ut + step) {
        let data = 0n;
        if (ut <= optimalUtilizationRateIntervalEnd) {
          data = baseVariableBorrowRate + WadRayMath.rayMul(k1, ut);
        } else if (ut <= maxSeadLoadRateInterval) {
          data = rMax2 - toBigInt(WadRayMath.rayMul(k2, maxSeadLoadRateInterval - ut));
        } else {
          data = rMax - toBigInt(WadRayMath.rayMul(k3, toBigInt(10000) - ut));
        }
        variableYData.push((parseFloat(data.toString()) / 100).toFixed(2));
      }
      this.optionInterestRateMode.series[1].data = variableYData;
    },
    calcStableChartData() {
      let stableYData = [];
      let stableXData = [];
      let step = 50;
      let maxUt = 10000;
      let startUt = 0;
      let optimalUtilizationRateIntervalStart = this.optimalUtilizationRateIntervalStart / E23;
      let optimalBorrowRate = this.optimalBorrowRate / E23;
      for (let ut = startUt; ut <= maxUt; ut = ut + step) {
        let data = 0n;
        if (ut <= optimalUtilizationRateIntervalStart) {
          data = optimalBorrowRate;
        } else {
          data = this.calcStableRateFormula(WadRayMath.rayDiv(toBigInt(ut), 10000n));
        }
        stableYData.push((parseFloat(data.toString()) / 100).toFixed(2));
        stableXData.push((ut / 100).toFixed(1));
      }
      this.optionInterestRateMode.xAxis.data = stableXData;
      this.optionInterestRateMode.series[0].data = stableYData;
    },
    calcStableRateFormula(utilizationRate) {
      let bezierUtilizationRate = WadRayMath.rayMul(utilizationRate - this.optimalUtilizationRateIntervalStart, WadRayMath.rayDiv(WadRayMath.RAY, WadRayMath.RAY - this.optimalUtilizationRateIntervalStart));
      const utilizationMulti = WadRayMath.rayMultiPow(bezierUtilizationRate, 2);
      const vacancyMulti = WadRayMath.rayMultiPow(WadRayMath.RAY - bezierUtilizationRate, 2);
      let res = WadRayMath.rayMul(vacancyMulti[2], this.optimalBorrowRate) + WadRayMath.rayMul(WadRayMath.rayMul(WadRayMath.rayMul(WadRayMath.TOW_RAY, vacancyMulti[1]), utilizationMulti[1]), this.optimalBorrowRate) + WadRayMath.rayMul(utilizationMulti[2], this.currentAPR);
      return res / E23;
    },
    initChartColor() {
      this.setBorrowTypeLine();
    }
  },
  computed: mapState({
    reserveRatioBorrowVariableRate: state => state.publicInfo.reserveRatioBorrowVariableRate,
    maxBorrowRate: state => state.publicInfo.maxBorrowRate,
    currentUtilizationRate() {
      return PercentageMath.div(this.borrowBalance, this.totalSupply);
    },
    liquidityReserveRatio: state => state.publicInfo.liquidityReserveRatio,
    maxSeadLoadRateInterval() {
      return RAY - this.liquidityReserveRatio;
    },
    totalSupply() {
      return this.borrowBalance + this.poolStFilBalanceBig;
    },
    supplyBalance: state => toBigIntDefault(state.stakeInfo.totalStakingTokens, 0n),
    borrowBalance: state => {
      return state.publicInfo.variableDebtTokenTotalSupply + state.publicInfo.stableDebtTokenTotalSupply;
    },
    stableBorrowRate: state => state.publicInfo.stableBorrowRate,
    poolStFilBalanceBig: state => state.publicInfo.poolStFilBalance,
    optimalBorrowRate: state => state.publicInfo.optimalBorrowRate,
    currentAPR: state => state.publicInfo.currentAPR,
    optimalBorrowSimpleRate() {
      return (parseFloat((this.optimalBorrowRate / E23).toString()) / 100).toFixed(2);
    },
    baseVariableBorrowRate: state => state.publicInfo.baseVariableBorrowRate,
    optimalUtilizationRateIntervalStart: state => state.publicInfo.optimalUtilizationRateIntervalStart,
    optimalUtilizationRateIntervalEnd: state => state.publicInfo.optimalUtilizationRateIntervalEnd
  })
};