import { mapState } from "vuex";
import TransferRadioBtn from "./TransferRadioBtn";
import { isEmpty } from "@/utils/model";
import TransferInput from './TransferInput';
import { formatWalletFilPrice } from "@/utils/NumU";
import FilForwarderABI from '@/utils/abi/FilForwarderABI.json';
import { iWriteContract } from "@/utils/contracts/opertion";
import { contracts } from "@/utils/contracts/config";
import { getLocal, gotoAddress, gotoHash, NotificationErr, NotificationSuccess, simpleValue } from "@/utils/common";
import { ethers } from "ethers";
import { decode, delegatedFromEthAddress, ethAddressFromDelegated } from "@glif/filecoin-address";
import { walletProvider } from "@/utils/LoutsRpc";
import { getProvider, prepareSendTransaction, sendTransaction, UserRejectedRequestError } from "@wagmi/core";
import TransferSTFIL from './TransferSTFIL';
import EvmTip from './EvmTip';
import { CoinType } from "@glif/filecoin-address/dist/coinType";
export default {
  name: "TransferComponents",
  components: {
    TransferRadioBtn,
    TransferInput,
    TransferSTFIL,
    EvmTip
  },
  data() {
    return {
      radioOptions: [],
      form: {
        token: 'FIL',
        from: '',
        to: '',
        eth: '',
        amount: ''
      },
      filForwarderContracts: contracts[this.$store.state.evn].FilForwarderAddress.eth,
      amountUsd: '0.00',
      showAmountUsd: false,
      loading: false,
      checkAddressLoading: false,
      toErrTips: '',
      amountErrTips: '',
      formFinish: {
        to: false,
        amount: false
      },
      isEvm: false,
      showFilForwarder: false,
      generateCodeInputTime: 0,
      formToEthAddress: '',
      btnText: '',
      hash: ''
    };
  },
  watch: {
    address() {
      this.initFrom();
    },
    '$i18n.locale'() {
      this.initRadioOptions();
    },
    filPrice() {
      this.initShowAmountUsd();
    },
    'form.token'() {
      this.changeFormToken();
    }
  },
  created() {
    this.initShowAmountUsd();
    this.initRadioOptions();
    this.initFrom();
  },
  methods: {
    changeFormToken() {},
    clearContent() {
      this.btnText = '';
      this.hash = '';
    },
    gotoHash,
    clearInput() {
      this.form.to = '';
      this.form.eth = '';
      this.form.amount = '';
      this.toErrTips = '';
      this.amountErrTips = '';
      this.formFinish.to = false;
      this.formFinish.amount = false;
      this.checkAddressLoading = false;
      this.loading = false;
      this.amountUsd = '0.00';
      this.showFilForwarder = false;
      this.formToEthAddress = '';
      this.isEvm = false;
      this.$nextTick(() => {
        this.$refs['transfer-textarea'].autoResize();
      });
    },
    simpleValue,
    clearInputTo() {
      this.form.to = '';
      this.form.eth = '';
      this.toErrTips = '';
      this.formFinish.to = false;
      this.checkAddressLoading = false;
      this.loading = false;
      this.showFilForwarder = false;
      this.formToEthAddress = '';
      this.isEvm = false;
      this.$nextTick(() => {
        this.$refs['transfer-textarea'].autoResize();
      });
    },
    gotoAdr(addreess) {
      gotoAddress(addreess.trim(), this.$i18n.locale);
    },
    openFilforwader() {
      window.open(`https://docs.filecoin.io/smart-contracts/filecoin-evm-runtime/filforwader/`);
    },
    toAddressChangeWait() {
      this.toErrTips = '';
      this.formFinish.to = false;
      let wait = 1000;
      this.generateCodeInputTime = new Date().getTime() + wait;
      setTimeout(() => {
        let now = new Date().getTime();
        if (now - this.generateCodeInputTime >= 0) {
          // this.inputTime拿的是最新的值，e.timeStamp拿的是零点五秒前函数的e.timeStamp
          this.toAddressChange();
        }
      }, wait);
    },
    async checkIsEvm(address) {
      let code = await getProvider().getCode(address);
      this.isEvm = code !== '0x';
    },
    async toAddressChange() {
      this.checkAddressLoading = true;
      this.showFilForwarder = false;
      this.formToEthAddress = '';
      this.formFinish.to = false;
      this.toErrTips = '';
      this.form.eth = '';
      this.isEvm = false;
      let address = this.form.to.trim();
      if (isEmpty(address)) {
        this.checkAddressLoading = false;
        return;
      }
      let connectType = getLocal('connectType');
      let isF1User = connectType === 'Ledger' || connectType === 'MetaMaskFlask';
      if (address.toLowerCase().startsWith("0x")) {
        let isAddress = ethers.utils.isAddress(address);
        if (isAddress) {
          this.form.eth = address;
          await this.checkIsEvm(this.form.eth);
          if (isF1User) {
            this.form.to = delegatedFromEthAddress(address, this.$store.state.evn === 'test' ? CoinType.TEST : CoinType.MAIN);
            this.formToEthAddress = `ETH: ${this.form.eth}`;
            this.showFilForwarder = true;
          }
          this.formFinish.to = true;
        } else {
          this.toErrTips = this.$t('transfer.noEthAddressTip');
          this.formFinish.to = false;
          this.formToEthAddress = '';
          this.isEvm = false;
        }
      } else {
        try {
          decode(this.form.to).bytes;
          let f4Address = '';
          try {
            let res = await walletProvider.getActor(this.form.to);
            console.log('res', res);
            f4Address = res.Address;
          } catch (e) {
            if (e.code < 0 || e.code > 0 && e.message === 'actor not found' || e.message === 'Internal Server Error') {
              this.formFinish.to = false;
              console.log('res.error.message', e, e.code);
              this.toErrTips = e.message;
              this.checkAddressLoading = false;
              return;
            }
          }
          this.formFinish.to = true;
          if (!isEmpty(f4Address)) {
            let ethAddress = ethAddressFromDelegated(f4Address);
            this.form.eth = ethAddress;
            this.formToEthAddress = `ETH: ${ethAddress}`;
            this.showFilForwarder = false;
            await this.checkIsEvm(this.form.eth);
            if (isF1User) {
              this.showFilForwarder = true;
            }
          } else {
            this.formToEthAddress = '';
            this.showFilForwarder = true;
          }
        } catch (e) {
          this.toErrTips = e.message;
          this.formFinish.to = false;
          this.showFilForwarder = false;
          this.formToEthAddress = '';
        }
      }
      this.checkAddressLoading = false;
    },
    initShowAmountUsd() {
      let _filePrice = parseFloat(this.filPrice);
      if (isNaN(_filePrice) || _filePrice === 0) {
        this.showAmountUsd = false;
      } else {
        this.showAmountUsd = true;
      }
    },
    isEmpty,
    amountChange() {
      this.amountErrTips = '';
      let _filePrice = parseFloat(this.filPrice);
      if (isEmpty(this.form.amount)) {
        this.formFinish.amount = false;
        this.amountUsd = '0.00';
        return;
      }
      let amount = parseFloat(this.form.amount);
      if (this.form.token === 'FIL') {
        let filBalance = parseFloat(this.formatWalletFilPrice(this.filBalance, 2, false, true, false).replaceAll(",", ""));
        if (amount > filBalance) {
          this.amountErrTips = this.$t('transfer.transferBalanceTip');
          this.formFinish.amount = false;
        } else {
          this.amountErrTips = '';
          this.formFinish.amount = true;
        }
      } else {
        let stFilBalance = parseFloat(this.formatWalletFilPrice(this.stFilBalance, 2, false, true, false).replaceAll(",", ""));
        if (amount > stFilBalance) {
          this.formFinish.amount = false;
          this.amountErrTips = this.$t('transfer.transferBalanceTip');
        } else {
          this.amountErrTips = '';
          this.formFinish.amount = true;
        }
      }
      this.amountUsd = (amount * _filePrice).toFixed(2);
    },
    setAmountIsBalance() {
      if (this.loading) {
        return;
      }
      if (this.form.token === 'FIL') {
        this.form.amount = this.formatWalletFilPrice(this.filBalance, 2, false, true, false).replaceAll(",", "");
      } else {
        this.form.amount = this.formatWalletFilPrice(this.stFilBalance, 2, false, true, false).replaceAll(",", "");
      }
      this.amountChange();
    },
    send() {
      this.loading = true;
      this.btnText = this.$t('common.Wait for transaction confirmation');
      let connectType = getLocal('connectType');
      let isF1User = connectType === 'Ledger' || connectType === 'MetaMaskFlask';
      if (isEmpty(this.form.eth) || isF1User) {
        iWriteContract({
          address: contracts[this.$store.state.evn].FilForwarderAddress.eth,
          f0address: contracts[this.$store.state.evn].FilForwarderAddress.id,
          abi: FilForwarderABI.abi,
          functionName: "forward",
          args: [decode(this.form.to).bytes],
          overrides: {
            value: ethers.utils.parseEther(this.form.amount.toString())
          }
        }, res => {
          let {
            hash
          } = res;
          this.btnText = this.$t('common.Waiting for winding');
          this.hash = hash;
        }).then(() => {
          NotificationSuccess(this.$t('transfer.Transfer successful'));
          setTimeout(() => {
            this.$store.dispatch('initAccount');
          }, 5000);
          this.form.amount = '';
          this.amountChange();
          this.loading = false;
          this.clearContent();
        }).catch(err => {
          NotificationErr(err.message, "transfer send" + ` ${getLocal('connectType')}`);
          this.loading = false;
          this.clearContent();
        });
      } else {
        prepareSendTransaction({
          request: {
            to: this.form.eth,
            value: ethers.utils.parseEther(this.form.amount.toString())
          }
        }).then(config => {
          sendTransaction(config).then(res => {
            let {
              hash,
              wait
            } = res;
            this.btnText = this.$t('common.Waiting for winding');
            this.hash = hash;
            wait().then(() => {
              NotificationSuccess(this.$t('transfer.Transfer successful'));
              setTimeout(() => {
                this.$store.dispatch('initAccount');
              }, 5000);
              this.form.amount = '';
              this.loading = false;
              this.clearContent();
            }).catch(err => {
              NotificationErr(err.message);
              this.loading = false;
              this.clearContent();
            });
          }).catch(err => {
            if (!(err instanceof UserRejectedRequestError)) {
              NotificationErr(err.message);
            }
            this.loading = false;
            this.clearContent();
          });
        }).catch(err => {
          NotificationErr(err.message);
          this.loading = false;
          this.clearContent();
        });
      }
    },
    initFrom() {
      if (!isEmpty(this.address)) {
        this.form.from = this.address;
      }
    },
    initRadioOptions() {
      this.radioOptions = [{
        label: 'FIL',
        value: 'FIL',
        img: require('@/assets/images/filecoin-logo.svg')
      }, {
        label: 'stFIL',
        value: 'stFIL',
        img: require('@/assets/images/stfil-logo.svg')
      }];
    },
    formatWalletFilPrice
  },
  computed: mapState({
    address() {
      return this.$store.state.address;
    },
    isConnected() {
      return this.$store.state.isConnected;
    },
    isConnecting() {
      return this.$store.state.isConnecting;
    },
    stFilBalance() {
      return this.$store.state.stFilBalance;
    },
    filBalance() {
      return this.$store.state.balance;
    },
    filPrice() {
      return this.$store.state.filPrice;
    },
    ifAllFinishSend() {
      return this.formFinish.amount && this.formFinish.to;
    },
    f1Address() {
      return this.$store.state.f1Address;
    },
    simpleContractAddress() {
      if (isEmpty(this.filForwarderContracts)) {
        return '';
      }
      if (this.filForwarderContracts.length > 20) {
        return this.filForwarderContracts.toString().substring(0, 14) + '...' + this.filForwarderContracts.toString().substring(this.filForwarderContracts.length - 14);
      } else {
        return this.filForwarderContracts.toString();
      }
    }
  })
};