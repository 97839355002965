import { gotoAddress, gotoHash, NotificationErr, NotificationSuccess } from "@/utils/common";
import { formatWalletFilPrice } from "@/utils/NumU";
import { mapState } from "vuex";
import { isEmpty } from "@/utils/model";
import { ethers } from "ethers";
import { decode, ethAddressFromDelegated, ethAddressFromID } from "@glif/filecoin-address";
import { walletProvider } from "@/utils/LoutsRpc";
import { iWriteContract } from "@/utils/contracts/opertion";
import { contracts } from "@/utils/contracts/config";
import STFILTokenABI from "@/utils/abi/STFILToken.json";
import { getProvider } from "@wagmi/core";
import EvmTip from './EvmTip';
export default {
  name: "TransferSTFIL",
  components: {
    EvmTip
  },
  data() {
    return {
      stFilForm: {
        to: '',
        eth: '',
        amount: ''
      },
      loading: false,
      formFinish: {
        to: false,
        amount: false
      },
      formToEthAddress: '',
      toErrTips: '',
      amountUsd: '0.00',
      showAmountUsd: false,
      amountErrTips: '',
      checkAddressLoading: false,
      hash: '',
      btnText: '',
      generateCodeInputTime: 0,
      isEvm: false
    };
  },
  created() {
    this.initShowAmountUsd();
  },
  methods: {
    clearInput() {
      this.stFilForm.to = '';
      this.stFilForm.eth = '';
      this.stFilForm.amount = '';
      this.toErrTips = '';
      this.amountErrTips = '';
      this.formFinish.to = false;
      this.formFinish.amount = false;
      this.checkAddressLoading = false;
      this.loading = false;
      this.amountUsd = '0.00';
      this.showFilForwarder = false;
      this.formToEthAddress = '';
      this.isEvm = false;
      this.$nextTick(() => {
        this.$refs['transfer-textarea'].autoResize();
      });
    },
    clearContent() {
      this.btnText = '';
      this.hash = '';
    },
    send() {
      this.loading = true;
      this.btnText = this.$t('common.Wait for transaction confirmation');
      iWriteContract({
        address: contracts[this.$store.state.evn].STFILToken.eth,
        f0address: contracts[this.$store.state.evn].STFILToken.id,
        abi: STFILTokenABI.abi,
        functionName: "transfer",
        args: [this.stFilForm.eth, ethers.utils.parseEther(this.stFilForm.amount.toString())]
      }, res => {
        let {
          hash
        } = res;
        this.btnText = this.$t('common.Waiting for winding');
        this.hash = hash;
      }).then(() => {
        NotificationSuccess(this.$t('transfer.Transfer successful'));
        setTimeout(() => {
          this.$store.dispatch('initAccount');
        }, 5000);
        this.stFilForm.amount = '';
        this.amountChange();
        this.loading = false;
        this.clearContent();
      }).catch(err => {
        NotificationErr(err.message);
        this.loading = false;
        this.clearContent();
      });
    },
    gotoHash,
    amountChange() {
      this.amountErrTips = '';
      let _filePrice = parseFloat(this.filPrice);
      if (isEmpty(this.stFilForm.amount)) {
        this.formFinish.amount = false;
        this.amountUsd = '0.00';
        return;
      }
      let amount = parseFloat(this.stFilForm.amount);
      let stFilBalance = parseFloat(this.formatWalletFilPrice(this.stFilBalance, 2, false, true, false).replaceAll(",", ""));
      if (amount > stFilBalance) {
        this.formFinish.amount = false;
        this.amountErrTips = this.$t('transfer.transferBalanceTip');
      } else {
        this.amountErrTips = '';
        this.formFinish.amount = true;
      }
      this.amountUsd = (amount * _filePrice).toFixed(2);
    },
    initShowAmountUsd() {
      let _filePrice = parseFloat(this.filPrice);
      if (isNaN(_filePrice) || _filePrice === 0) {
        this.showAmountUsd = false;
      } else {
        this.showAmountUsd = true;
      }
    },
    formatWalletFilPrice,
    setAmountIsBalance() {
      if (this.loading) {
        return;
      }
      this.stFilForm.amount = this.formatWalletFilPrice(this.stFilBalance, 2, false, true, false).replaceAll(",", "");
      this.amountChange();
    },
    toAddressChangeWait() {
      this.toErrTips = '';
      this.formFinish.to = false;
      let wait = 1000;
      this.generateCodeInputTime = new Date().getTime() + wait;
      setTimeout(() => {
        let now = new Date().getTime();
        if (now - this.generateCodeInputTime >= 0) {
          // this.inputTime拿的是最新的值，e.timeStamp拿的是零点五秒前函数的e.timeStamp
          this.toAddressChange();
        }
      }, wait);
    },
    clearInputTo() {
      this.stFilForm.to = '';
      this.stFilForm.eth = '';
      this.toErrTips = '';
      this.formFinish.to = false;
      this.checkAddressLoading = false;
      this.loading = false;
      this.showFilForwarder = false;
      this.formToEthAddress = '';
      this.isEvm = false;
      this.$nextTick(() => {
        this.$refs['transfer-textarea'].autoResize();
      });
    },
    async checkIsEvm(address) {
      let code = await getProvider().getCode(address);
      this.isEvm = code !== '0x';
    },
    async toAddressChange() {
      this.checkAddressLoading = true;
      this.formToEthAddress = '';
      this.formFinish.to = false;
      this.toErrTips = '';
      this.stFilForm.eth = '';
      this.isEvm = false;
      let address = this.stFilForm.to.trim();
      if (isEmpty(address)) {
        this.checkAddressLoading = false;
        return;
      }
      if (address.toLowerCase().startsWith("0x")) {
        let isAddress = ethers.utils.isAddress(address);
        if (isAddress) {
          this.formFinish.to = true;
          this.stFilForm.eth = address;
          await this.checkIsEvm(this.stFilForm.eth);
          console.log(isAddress);
        } else {
          this.toErrTips = this.$t('transfer.noEthAddressTip');
          this.formFinish.to = false;
          this.formToEthAddress = '';
          this.isEvm = false;
        }
      } else {
        try {
          decode(this.stFilForm.to).bytes;
          let f4Address = '';
          try {
            let res = await walletProvider.getActor(this.stFilForm.to);
            console.log('res', res);
            if (isEmpty(res.Address)) {
              await this.getF0Address();
              return;
            }
            f4Address = res.Address;
          } catch (e) {
            this.formFinish.to = false;
            console.log('res.error.message', e);
            this.toErrTips = e.message;
            this.checkAddressLoading = false;
            return;
          }
          this.formFinish.to = true;
          if (!isEmpty(f4Address)) {
            let ethAddress = ethAddressFromDelegated(f4Address);
            this.stFilForm.eth = ethAddress;
            this.formToEthAddress = `ETH: ${ethAddress}`;
            await this.checkIsEvm(this.stFilForm.eth);
          } else {
            this.formToEthAddress = '';
          }
        } catch (e) {
          this.toErrTips = e.message;
          this.formFinish.to = false;
          this.formToEthAddress = '';
        }
      }
      this.checkAddressLoading = false;
    },
    gotoAdr(addreess) {
      gotoAddress(addreess.trim(), this.$i18n.locale);
    },
    async getF0Address() {
      try {
        let f0Address = await walletProvider.lookupId(this.stFilForm.to);
        if (!isEmpty(f0Address)) {
          let ethAddress = ethAddressFromID(f0Address);
          this.stFilForm.eth = ethAddress;
          this.formToEthAddress = `ETH: ${ethAddress}`;
          this.formFinish.to = true;
          this.checkAddressLoading = false;
        }
      } catch (e2) {
        console.log(e2);
        this.toErrTips = this.$t('transfer.transfeStfilTip');
        this.checkAddressLoading = false;
      }
    }
  },
  computed: mapState({
    address() {
      return this.$store.state.address;
    },
    isConnected() {
      return this.$store.state.isConnected;
    },
    isConnecting() {
      return this.$store.state.isConnecting;
    },
    stFilBalance() {
      return this.$store.state.stFilBalance;
    },
    filBalance() {
      return this.$store.state.balance;
    },
    filPrice() {
      return this.$store.state.filPrice;
    },
    ifAllFinishSend() {
      return this.formFinish.amount && this.formFinish.to;
    }
  })
};